<script setup>

import Accordion from "../MemberCompliance/Verifications/Accordion.vue";
import {onMounted, ref} from "vue";
import CameraCapture from "../../../../userpicture/CameraCapture.vue";
import axios from "axios";
import Swal from "sweetalert2";

const props = defineProps([
    'subscription',
    'leader',
    'market',
    'subscriptions_url',
    'token',
    'verifications'
]);

const first_name = ref(props.leader.first_name ?? '');
const last_name = ref(props.leader.last_name ?? '');
const phone_number = ref(props.leader.phone ?? '');
const photoFile = ref('');

const setPhotoURL = (e) => {
    photoFile.value = e;
}
onMounted(() => {
    bvn.value = props.leader.bvn;
    nin.value = props.leader.national_id;
    marketaddress.value = props.market.address;
    market_city.value = props.market.city;

    verifications.value = props.verifications?.verifications;
    bvnVerification.value = verifications.value?.confirm_user_bvn;
    ninVerification.value = verifications.value?.confirm_user_nin;
    confirmLocationVerification.value = verifications.value?.confirm_shop_address;
    livePhotoVerification.value = verifications.value?.confirm_user_photo;
    if (props.verifications?.status) {
        startVerification.value = true;
    }
});
/*Form inputs*/
const errors = ref([]);
const bvn = ref('');
const nin = ref('');
const marketaddress = ref('');
const market_city = ref('');
const proof_of_ownership = ref('');
/*Form inputs*/

const startVerification = ref(false);
const submitting = ref(false);

const verifications = ref([]);
const bvnVerification = ref('');
const ninVerification = ref('');
const confirmLocationVerification = ref('');
const livePhotoVerification = ref('');
const submit = () => {
    let formData = new FormData();

    formData.append('bvn', bvn.value);
    formData.append('national_id', nin.value);
    formData.append('kra_pin', '');
    formData.append('market_address', marketaddress.value);
    formData.append('market_city', market_city.value);
    formData.append('utility_upload', rentalAgreementFile.value);
    submitting.value = true;
    axios.post("/apis/v4/verifications/market/savedetails", formData).then((resp) => {
        verifications.value = resp.data.verifications.verifications;
        // broadCastListener(resp.data.verifications.id)
        errors.value = [];
        submitting.value = false;
        getVerifications();
        qoreIdVerifications();
    }).catch((err) => {
        console.log(err)
        Swal.fire('There has been an error', 'error');
        // errors.value = err.response.data.errors;
        submitting.value = false;
    }).finally(() => {
        submitting.value = false;
    });
}
onMounted(() => {
    getVerifications();
})

const getVerifications = () => {
    axios.get('/apis/v4/saas/verifications').then((resp) => {
        bvnVerification.value = resp.data.data.recent_results.bvn;
        ninVerification.value = resp.data.data.recent_results.nin;
        confirmLocationVerification.value = resp.data.data.recent_results.address;
        livePhotoVerification.value = resp.data.data.recent_results.face_match;
    });
}


const qoreIdVerifications = () => {
    submitting.value = true;

    const form = new FormData();

    form.append("verification_type", "market");
    form.append("market_id", props.market?.id);
    form.append("user_id", props.leader?.id);

    // bvn-verification-form-append
    // if (bvnVerification.value === 'InCorrect' || bvnVerification.value === 'failed') {
        form.append("types[]", "bvn");
        form.append("bvn", bvn.value);
    // }

    // nin-verification-form-append
    // if (ninVerification.value === 'InCorrect' || ninVerification.value === 'failed') {
        form.append("types[]", "nin");
        form.append("nin", nin.value);
    // }

    // face-match-verification-form-append
    // if (livePhotoVerification.value === 'InCorrect' || livePhotoVerification.value === 'failed') {
        form.append("types[]", "face_match");
        form.append(
            "face_match[photo]",
            photoFile.value ? photoFile.value : null
        );

        form.append("face_match[id_number]", bvn.value);
        form.append("face_match[id_type]", "bvn");
        form.append("face_match[id_number]", nin.value);
        form.append("face_match[id_type]", "nin");
    // }

    // address-verification-form-append
    // if (confirmLocationVerification.value === 'InCorrect' || confirmLocationVerification.value === 'failed') {
        form.append("types[]", "address");
        form.append("address[lga]", props.market?.l_g_a?.name);
        form.append("address[state]", props.market?.geo_state?.name);
        form.append("address[city]", market_city.value);
        form.append("address[street]", marketaddress.value);
    // }

    axios
        .post("/apis/v4/saas/verifications", form)
        .then(() => {
            submitting.value = false;
            var myModalEl = document.querySelector(
                "#thankyou"
            );
            var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

            modal.toggle();
            getVerifications();
        })
        .catch((err) => {
            Swal.fire(`${err.response?.data?.message ?? 'There were issues with your core id verification'}`, '', 'error')
        })
        .finally(() => {
        });

}

const broadCastListener = (id) => {
    Echo.channel('Verifications' + id)
        .listen('.Verification', (e) => {
            verifications.value = e.verification.verifications;
            getVerifications();
        });
};

function updateVerifications() {
    bvnVerification.value = verifications.value.confirm_user_bvn;
    ninVerification.value = verifications.value.confirm_user_nin;
    confirmLocationVerification.value = verifications.value.confirm_shop_address;
    livePhotoVerification.value = verifications.value.confirm_user_photo;
}

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

const rentalAgreementFile = ref();
const rentalAgreement = (e) => {
    rentalAgreementFile.value = e.target.files[0];
}

function handleDragOver(e) {
    e.dataTransfer.dropEffect = 'copy';
    e.preventDefault();
}

const saving = ref(false);
const updateProfile = () => {
    saving.value = true;
    axios.post("/apis/v4/editingProfile", {
        first_name: first_name.value,
        last_name: last_name.value,
    }).then(() => {
        saving.value = false;
    })

}
const showAfterVerifications = ref(false);
const showForm = ref(false);
</script>


<template>
    <div>
        <template v-if="subscription == 'Free'">
            <div
                class="card mb-3"
                style="border-radius: 0.25rem; box-shadow: none"
            >
                <div
                    class="card-body"
                >
                    <h4 class="text-success">Upgrade to get verified</h4>
                    <p style="color: #283342">
                        You are currently on the free plan. To get
                        verified, you need to be on the
                        <span style="color: #4b9d30">
                                        value builder monthly
                                    </span>
                        or
                        <span style="color: #4b9d30">
                                        value builder yearly plan
                                    </span>
                        . Upgrade today to get trusted by vendors
                    </p>
                    <a
                        class="btn btn-success"
                        rel="noreferrer noopener"
                        target="_blank"
                        :href="`${subscriptions_url}/upgrade?token=${token}&market_id=${market.id}`"
                    >
                        <button class="btn btn-success">
                            Upgrade today
                        </button>
                    </a>
                </div>
            </div>

        </template>
        <template v-else>
            <div
                class="card mb-3"
                style="border-radius: 0.25rem; box-shadow: none"
                v-if="!startVerification"
            >
                <div
                    class="card-body text-white"
                    style="background-color: #1D242E;border-radius: 8px"
                >
                    <h4 class="text-white">Get Verified</h4>
                    <p>
                        Verify Your market on Amana market so vendors can trust you and your members can access
                        financing and insurance options for your market depending on your region
                    </p>

                    <button
                        class="btn btn-success"
                        style="background-color: white !important;color:#1D242E;border-color: transparent;box-shadow: none"
                        @click="showForm = !showForm"
                    >
                        Start Verification
                    </button>
                </div>
            </div>
            <div v-if="market?.verification_status === 'partial_verified'">
                <div class="card mb-3 card-pending">
                    <div class="card-body">
                        <h4 class="card-title">
                            Market Verification is in Progress

                        </h4>
                        <div class="card" style="border-radius: 2em">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <div class="circle-container">
                                            <div class="circle-text">
                                                <p class="small">
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="d-flex ">
                                            <div style="width: 100%;" class="tw-ml-3 tw-mr-3">
                                                <div class="text-xs-center text-black" id="progressId">
                                                    Trust Level 0
                                                </div>
                                                <div class="progress progress-success" value="40"
                                                     max="100" aria-describedby="progressId"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="circle-container">
                                            <div class="circle-text">
                                                <p class="small">
                                                    8
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>
                            Your market verification is currently in progress. Once verification is complete you will be
                            notified

                        </p>
                        <div class="card-text">
                            <button class="btn btn-success btn-view" @click="showForm = !showForm">
                                View Status
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="market?.verification_status === 'verified'">
                <div class="card mb-3 card-pending">
                    <div class="card-body">
                        <h4 class="card-title">
                            Market Verification is in Progress

                        </h4>
                        <div class="card" style="border-radius: 2em">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <div class="circle-container">
                                            <div class="circle-text">
                                                <p class="small">
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="d-flex ">
                                            <div style="width: 100%;" class="tw-ml-3 tw-mr-3">
                                                <div class="text-xs-center text-black" id="progressId">
                                                    Trust Level 0
                                                </div>
                                                <div class="progress progress-success" value="40"
                                                     max="100" aria-describedby="progressId"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="circle-container">
                                            <div class="circle-text">
                                                <p class="small">
                                                    8
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>
                            Your market verification is currently in progress. Once verification is complete you will be
                            notified

                        </p>
                        <div class="card-text">
                            <button class="btn btn-success btn-view" @click="showForm = !showForm">
                                View Status
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="market?.verification_status === 'Unverified'">
                <div class="card mb-3 card-pending">
                    <div class="card-body">
                        <h4 class="card-title">
                            Market Verification is in Progress

                        </h4>
                        <div class="card" style="border-radius: 2em">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <div class="circle-container">
                                            <div class="circle-text">
                                                <p class="small">
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="d-flex ">
                                            <div style="width: 100%;" class="tw-ml-3 tw-mr-3">
                                                <div class="text-xs-center text-black" id="progressId">
                                                    Trust Level 0
                                                </div>
                                                <div class="progress progress-success" value="40"
                                                     max="100" aria-describedby="progressId"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="circle-container">
                                            <div class="circle-text">
                                                <p class="small">
                                                    8
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>
                            Your market verification is currently in progress. Once verification is complete you will be
                            notified

                        </p>
                        <div class="card-text">
                            <button class="btn btn-success btn-view" @click="showForm = !showForm">
                                View Status
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="card mb-3 card-pending">
                    <div class="card-body">
                        <h4 class="card-title">
                            Market Verification is in Progress

                        </h4>
                        <div class="card" style="border-radius: 2em">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <div class="circle-container">
                                            <div class="circle-text">
                                                <p class="small">
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="d-flex ">
                                            <div style="width: 100%;" class="tw-ml-3 tw-mr-3">
                                                <div class="text-xs-center text-black" id="progressId">
                                                    Trust Level 0
                                                </div>
                                                <div class="progress progress-success" value="40"
                                                     max="100" aria-describedby="progressId"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="circle-container">
                                            <div class="circle-text">
                                                <p class="small">
                                                    8
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>
                            Your market verification is currently in progress. Once verification is complete you will be
                            notified

                        </p>
                        <div class="card-text">
                            <button class="btn btn-success btn-view" @click="showForm = !showForm">
                                View Status
                            </button>
                        </div>
                    </div>
                </div>
<!--                <div-->
<!--                    class="card mb-3"-->
<!--                    style="border-radius: 0.25rem; box-shadow: none"-->

<!--                >-->
<!--                    <div-->
<!--                        class="card-body text-white"-->
<!--                        style="background-color: #1D242E;border-radius: 8px"-->
<!--                    >-->
<!--                        <h4 class="text-white">Get Verified</h4>-->
<!--                        <p>-->
<!--                            Verify Your market on Amana market so vendors can trust you and your members can access-->
<!--                            financing and insurance options for your market depending on your region-->
<!--                        </p>-->

<!--                        <button-->
<!--                            class="btn btn-success"-->
<!--                            style="background-color: white !important;color:#1D242E;border-color: transparent;box-shadow: none"-->
<!--                            @click="showForm = !showForm"-->
<!--                        >-->
<!--                            Start Verification-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </div>-->
            </div>

            <div v-if="showForm">
                <Accordion key="callcenter">
                    <template #title>
                        <span class="accordion-title poppins-semibold">
                        Call Center Verification
                        </span>
                    </template>
                    <template #status>
                        <div></div>
                    </template>
                    <template #errors>
                        <!--                <div class="alert alert-danger" role="alert">-->
                        <!--                    <strong>Oh snap!</strong>-->
                        <!--                </div>-->
                    </template>
                    <div>
                        <p>
                            Call ‎+234 1 330 6114 with your registered number to initiate your verification or wait for
                            our team to call you on your registered number
                        </p>
                        <p>
                            <strong>
                                Confirm your information below as provided in your NIN or BVN
                            </strong>
                        </p>
                    </div>
                    <div>
                        <!--Label: First Name, Attributes:first_name -->
                        <div class="form-group">
                            <label for="first_name">First Name</label>
                            <input type="text" class="form-control" id="first_name" v-model="first_name"
                                   aria-describedby="first_name-help"
                                   :class="[errors.first_name ? 'is-invalid': '',!errors.first_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                   placeholder="First Name" required>
                            <div class="invalid-feedback" v-if="errors.first_name">
                                {{ errors.first_name.toString() }}
                            </div>
                        </div>

                        <!--Label: Last Name, Attributes:last_name -->
                        <div class="form-group">
                            <label for="last_name">Last Name</label>
                            <input type="text" class="form-control" id="last_name" v-model="last_name"
                                   aria-describedby="last_name-help"
                                   :class="[errors.last_name ? 'is-invalid': '',!errors.last_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                   placeholder="Last Name" required>
                            <div class="invalid-feedback" v-if="errors.last_name">
                                {{ errors.last_name.toString() }}
                            </div>
                        </div>

                        <!--Label: Phone Number, Attributes:phone_number -->
                        <div class="form-group">
                            <label for="phone_number">Phone Number</label>
                            <input type="text"
                                   disabled
                                   class="form-control" id="phone_number" v-model="phone_number"
                                   aria-describedby="phone_number-help"
                                   :class="[errors.phone_number ? 'is-invalid': '',!errors.phone_number && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                   placeholder="Phone Number" required>
                            <div class="invalid-feedback" v-if="errors.phone_number">
                                {{ errors.phone_number.toString() }}
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <a style="color:#4B9D30" class="poppins-bold mt-3 underline"
                               @click="updateProfile"
                            >
                                Save
                                <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                            </a>
                        </div>
                    </div>
                </Accordion>

                <!--    BVN Verification    -->
                <Accordion key="bvn">
                    <template #title>
                        <span class="accordion-title poppins-semibold">
                        Market Leader BVN
                        </span>
                    </template>
                    <template #status>
                        <div v-if="errors && errors.bvn">
                            <div
                                class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i> Error
                            </div>
                        </div>
                        <template v-else>
                            <div v-if="bvnVerification === 'queuing'">
                                <div
                                    class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-yellow-100 tw-text-yellow-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                    <i class="fa fa-circle-notch fa-spin fa-fw tw-text-yellow-600"></i> Queueing...
                                </div>
                            </div>
                            <div v-if="bvnVerification === 'running'">
                                <div
                                    class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-blue-100 tw-text-blue-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                    <i class="fa fa-cog fa-spin fa-fw tw-text-blue-600"></i> Running...
                                </div>
                            </div>
                            <div v-if="bvnVerification === null || bvnVerification === 'pending'">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        fill="#FFF8EB"
                                    />
                                    <path
                                        d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                        stroke="#D78100"
                                        stroke-width="1.5"
                                    />
                                    <path
                                        d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                        stroke="#D78100"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                    />
                                </svg>
                            </div>
                            <div v-if="bvnVerification === 'Correct' || bvnVerification === 'success'">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                >
                                    <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        fill="#41AE49"
                                    />
                                    <path
                                        d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                        stroke="#F8FBF8"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div v-if="bvnVerification === 'InCorrect' || bvnVerification === 'failed'">
                        <span class="badge badge-pill badge-danger">
                             <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                          <rect width="34" height="34" rx="17" fill="#FFF2F0"/><path
                                 d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                 stroke="#E2341D" stroke-width="1.5" stroke-linecap="round"
                                 stroke-linejoin="round"/>
                      </svg>
                         </span>
                            </div>
                            <div v-else>

                            </div>
                        </template>

                    </template>
                    <template #errors>
                        <!--                <div class="alert alert-danger" role="alert">-->
                        <!--                    <strong>Oh snap!</strong>-->
                        <!--                </div>-->
                    </template>
                    <div>
                        <div class="mb-2" style="color:#1D242E">
                            Confirm your BVN below
                        </div>
                        <label for="bvn" class="form-label">BVN</label>
                        <input type="text" class="form-control form-control-primary" id="bvn" v-model="bvn"
                               aria-describedby="bvn-help"
                               placeholder="Enter your BVN"
                               :class="[errors.bvn ? 'is-invalid': '',!errors.bvn && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               :disabled="(
                                   (bvnVerification === null || bvnVerification === 'pending') ||
                                   (bvnVerification === 'Correct' || bvnVerification === 'success')
                               )"
                        >
                        <div class="invalid-feedback" v-if="errors.bvn">
                            {{ errors.bvn.toString() }}
                        </div>
                    </div>
                </Accordion>

                <!--    NIN Verification    -->
                <Accordion key="nin">
                    <template #title>
                        <span class="accordion-title poppins-semibold">
                        Market Leader NIN Verification
                        </span>
                    </template>
                    <template #status>
                        <div v-if="errors.national_id">
                            <div
                                class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i> Error
                            </div>
                        </div>
                        <template v-else>
                            <div v-if="ninVerification === 'queuing'">
                                <div
                                    class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-yellow-100 tw-text-yellow-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                    <i class="fa fa-circle-notch fa-spin fa-fw tw-text-yellow-600"></i> Queueing...
                                </div>
                            </div>
                            <div v-if="ninVerification === 'running'">
                                <div
                                    class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-blue-100 tw-text-blue-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                    <i class="fa fa-cog fa-spin fa-fw tw-text-blue-600"></i> Running...
                                </div>
                            </div>
                            <div v-if="ninVerification === null || ninVerification === 'pending'">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        fill="#FFF8EB"
                                    />
                                    <path
                                        d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                        stroke="#D78100"
                                        stroke-width="1.5"
                                    />
                                    <path
                                        d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                        stroke="#D78100"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                    />
                                </svg>
                            </div>
                            <div v-if="ninVerification === 'Correct' || ninVerification === 'success'">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                >
                                    <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        fill="#41AE49"
                                    />
                                    <path
                                        d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                        stroke="#F8FBF8"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div v-if="ninVerification === 'InCorrect' || ninVerification === 'failed'">
                        <span class="badge badge-pill badge-danger">
                             <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                          <rect width="34" height="34" rx="17" fill="#FFF2F0"/><path
                                 d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                 stroke="#E2341D" stroke-width="1.5" stroke-linecap="round"
                                 stroke-linejoin="round"/>
                      </svg>
                         </span>
                            </div>
                            <div v-else>

                            </div>
                        </template>
                    </template>
                    <template #errors>
                        <!--                <div class="alert alert-danger" role="alert">-->
                        <!--                    <strong>Oh snap!</strong>-->
                        <!--                </div>-->
                    </template>
                    <!--Label: National Identification, Attributes:nin -->
                    <div class="">
                        <div class="mb-2" style="color:#1D242E">
                            Confirm your NIN below
                        </div>
                        <label for="nin" class="form-label">National Identification</label>
                        <input type="text" class="form-control" id="nin" v-model="nin"
                               aria-describedby="nin-help"
                               :class="[errors.national_id ? 'is-invalid': '',!errors.national_id && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               placeholder="Enter your National Identification number"
                               required
                               :disabled="(
                                   (ninVerification === null || ninVerification === 'pending') ||
                                   (ninVerification === 'Correct' || ninVerification === 'success')
                               )"
                        >
                        <div class="invalid-feedback" v-if="errors.national_id">
                            {{ errors.national_id.toString() }}
                        </div>
                    </div>
                </Accordion>

                <!--    Live Photo Verification    -->
                <Accordion key="liveVerification">
                    <template #title>
                        <span class="accordion-title poppins-semibold">
                        Market Leader Live Photo
                        </span>
                    </template>
                    <template #status>
                        <div v-if="livePhotoVerification === 'queuing'">
                            <div
                                class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-yellow-100 tw-text-yellow-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-circle-notch fa-spin fa-fw tw-text-yellow-600"></i> Queueing...
                            </div>
                        </div>
                        <div v-if="livePhotoVerification === 'running'">
                            <div
                                class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-blue-100 tw-text-blue-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-cog fa-spin fa-fw tw-text-blue-600"></i> Running...
                            </div>
                        </div>
                        <div v-if="livePhotoVerification === null || livePhotoVerification === 'pending'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="34"
                                    height="34"
                                    rx="17"
                                    fill="#FFF8EB"
                                />
                                <path
                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                />
                                <path
                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </div>
                        <div v-if="livePhotoVerification === 'Correct' || livePhotoVerification === 'success'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                            >
                                <rect
                                    width="34"
                                    height="34"
                                    rx="17"
                                    fill="#41AE49"
                                />
                                <path
                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                    stroke="#F8FBF8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div v-if="livePhotoVerification === 'InCorrect' || livePhotoVerification === 'failed'">
                        <span class="badge badge-pill badge-danger">
                             <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                          <rect width="34" height="34" rx="17" fill="#FFF2F0"/><path
                                 d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                 stroke="#E2341D" stroke-width="1.5" stroke-linecap="round"
                                 stroke-linejoin="round"/>
                      </svg>
                         </span>
                        </div>
                        <div v-else>

                        </div>
                    </template>
                    <template #errors>
                        <!--                <div class="alert alert-danger" role="alert">-->
                        <!--                    <strong>Oh snap!</strong>-->
                        <!--                </div>-->
                    </template>

                    <CameraCapture
                        @upload-file="setPhotoURL"
                        :uploaded_photo="null"
                        :user_id="leader.id"
                        :disabled_field="(
                           (livePhotoVerification === null || livePhotoVerification === 'pending') ||
                           (livePhotoVerification === 'Correct' || livePhotoVerification === 'success')
                        )"
                    ></CameraCapture>
                </Accordion>

                <!--    Location Verification    -->
                <Accordion key="location">
                    <template #title>
                        <span class="accordion-title poppins-semibold">
                        Market Address Verification
                        </span>
                    </template>
                    <template #status>
                        <div v-if="errors.market_address || errors.market_city">
                            <div
                                class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i> Error
                            </div>
                        </div>
                        <template v-else>
                            <div v-if="confirmLocationVerification === 'queuing'">
                                <div
                                    class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-yellow-100 tw-text-yellow-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                    <i class="fa fa-circle-notch fa-spin fa-fw tw-text-yellow-600"></i> Queueing...
                                </div>
                            </div>
                            <div v-if="confirmLocationVerification === 'running'">
                                <div
                                    class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-blue-100 tw-text-blue-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                    <i class="fa fa-cog fa-spin fa-fw tw-text-blue-600"></i> Running...
                                </div>
                            </div>
                            <div
                                v-if="confirmLocationVerification === null || confirmLocationVerification === 'pending'">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        fill="#FFF8EB"
                                    />
                                    <path
                                        d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                        stroke="#D78100"
                                        stroke-width="1.5"
                                    />
                                    <path
                                        d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                        stroke="#D78100"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                    />
                                </svg>
                            </div>
                            <div
                                v-if="confirmLocationVerification === 'Correct' || confirmLocationVerification === 'success'">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                >
                                    <rect
                                        width="34"
                                        height="34"
                                        rx="17"
                                        fill="#41AE49"
                                    />
                                    <path
                                        d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                        stroke="#F8FBF8"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div
                                v-if="confirmLocationVerification === 'InCorrect' || confirmLocationVerification === 'failed'">
                        <span class="badge badge-pill badge-danger">
                             <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                          <rect width="34" height="34" rx="17" fill="#FFF2F0"/><path
                                 d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                 stroke="#E2341D" stroke-width="1.5" stroke-linecap="round"
                                 stroke-linejoin="round"/>
                      </svg>
                         </span>
                            </div>
                            <div v-else>

                            </div>
                        </template>
                    </template>
                    <template #errors>
                        <!--                <div class="alert alert-danger" role="alert">-->
                        <!--                    <strong>Oh snap!</strong>-->
                        <!--                </div>-->
                    </template>
                    <p class="mb-4">
                        Please input the address of your market and expect a phone call for physical verification of
                        your market.
                    </p>
                    <!--Label: Market Address, Attributes:marketaddress -->
                    <div class="form-group">
                        <label for="marketaddress" class="form-label">Address</label>
                        <input type="text" class="form-control" id="marketaddress" v-model="marketaddress"
                               aria-describedby="marketaddress-help"
                               :class="[errors.market_address ? 'is-invalid': '',!errors.market_address && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               placeholder="Address"
                               required
                               :disabled="(
                                   (confirmLocationVerification === null || confirmLocationVerification === 'pending') ||
                                   (confirmLocationVerification === 'Correct' || confirmLocationVerification === 'success')
                               )"
                        >
                        <div class="invalid-feedback" v-if="errors.market_address">
                            {{ errors.market_address.toString() }}
                        </div>
                    </div>

                    <!--Label: Market City, Attributes:market_city -->
                    <div class="form-group mt-3">
                        <label for="market_city" class="form-label">City</label>
                        <input type="text" class="form-control" id="market_city" v-model="market_city"
                               aria-describedby="market_city-help"
                               :class="[errors.market_city ? 'is-invalid': '',!errors.market_city && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               placeholder="City"
                               required
                               :disabled="(
                                   (confirmLocationVerification === null || confirmLocationVerification === 'pending') ||
                                   (confirmLocationVerification === 'Correct' || confirmLocationVerification === 'success')
                               )"
                        >
                        <div class="invalid-feedback" v-if="errors.market_city">
                            {{ errors.market_city.toString() }}
                        </div>
                    </div>

                    <div class="form-group mt-3 drag-target" @dragover.prevent="handleDragOver">
                        <div class="form-group mt-3">
                            <label for="proof_of_ownership" class="form-label">
                                Market utility bill
                            </label>
                            <input type="file"
                                   @change="rentalAgreement"
                                   class="form-control" id="proof_of_ownership"
                                   aria-describedby="proof_of_ownership-help"
                                   :class="[errors.proof_of_ownership ? 'is-invalid': '',!errors.proof_of_ownership && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                   placeholder="Rent Agreement or Proof of Ownership"
                                   required
                                   :disabled="(
                                       (confirmLocationVerification === null || confirmLocationVerification === 'pending') ||
                                       (confirmLocationVerification === 'Correct' || confirmLocationVerification === 'success')
                                   )"
                            >
                            <div class="invalid-feedback" v-if="errors.proof_of_ownership">
                                {{ errors.proof_of_ownership.toString() }}
                            </div>
                        </div>
                    </div>
                </Accordion>

                <!--    Submit Button    -->
                <!--    only show button if 1 or more of the verifications failed   -->
                <div
                    class="row"

                >
                    <div class="d-flex justify-content-end">
                        <!--    disable button if all verifications success/pending   -->
                        <button
                            class="btn btn-success mr-3 mb-3"
                            @click.prevent="submit"
                        >
                            Submit
                            <span
                                class="fa fa-spin fa-spinner"
                                v-if="submitting"
                            ></span>
                        </button>
                    </div>
                </div>


            </div>
        </template>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="thankyou" tabindex="-1" role="dialog" aria-labelledby="thankyouId" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="thankyouId poppins-bold" style="color: #4B9D30;font-size: 24">Thank
                        you!!!</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <p>
                        Your verification has been submitted and you will be notified once it is complete. Please note
                        that
                        verification can take up to 3 days
                        If your verification is not complete in 3 days, please call ‎+234 1 330 6114 to confirm your
                        status
                    </p>
                    <button type="button" class="btn btn-success" data-bs-dismiss="modal">Continue</button>

                </div>
                <!--                <div class="modal-footer">-->
                <!--                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
                <!--                    <button type="button" class="btn btn-primary">Save</button>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<style scoped>
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
}

.accordion-title {
    color: #1D242E;
}

.card-title {
    color: white
}

.card-pending {
    background-color: #EE8509;
    color: white
}

.circle-container {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px solid #EE8509;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.circle-text {
    position: relative;
    top: 5px;
    color: #4B9D30
}
.btn-view{
    color: #EE8509;
    background-color: white !important;
    border-color: transparent;
}
</style>
