<script setup>
import {onMounted, ref} from "vue";
import Accordion from "./Accordion.vue";
import CameraCapture from "../../../../../userpicture/CameraCapture.vue";
import axios from "axios";

const props = defineProps(['verifications', 'user']);

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

const verifications = ref(props.verifications);
const verificationStarted = ref(false);
const callCenterverified = ref(false);

/* Identity Verification Data*/
const errors = ref([]);
const bvn = ref('');
const nin = ref('');
const marketaddress = ref('');
const market_city = ref('');
const proof_of_ownership = ref('');
/* Identity Verification Data*/
onMounted(() => {
    bvn.value = props.user.bvn;
    nin.value = props.user.national_id;
    marketaddress.value = props.user.address;
    market_city.value = props.user.city;
    proof_of_ownership.value = props.user.city;

    verifications.value = props.verifications?.verifications;
    bvnVerication.value = verifications.value?.confirm_user_bvn;
    ninVerication.value = verifications.value?.confirm_user_nin;
    confirmLocationVerification.value = verifications.value?.confirm_shop_address;
    callCenterverified.value = verifications.value?.call_center_verified;
    livePhotoVerification.value = verifications.value?.confirm_user_photo;
    if (props.verifications?.status) {
        verificationStarted.value = true;
    }
});

const bvnVerication = ref(false);
const ninVerication = ref(false);
const confirmLocationVerification = ref(false);
const livePhotoVerification = ref(false);
const submitting = ref(false);
const submit = () => {
    let formData = new FormData();

    formData.append('bvn',bvn.value);
    formData.append('national_id',nin.value);
    formData.append('kra_pin','');
    formData.append('rentalagreement',rentalAgreementFile.value);
    formData.append('address',marketaddress.value);
    formData.append('city',market_city.value);

    submitting.value = true;
    axios.post("/apis/v4/verifications/market-member/savedetails", formData).then((resp) => {
        broadCastListener(resp.data.verifications.id)
        errors.value = [];
        submitting.value = false;
        verifications.value = resp.data.verifications.verifications;
        updateVerifications();
    }).catch((err) => {
        errors.value = err.response.data.errors;
        submitting.value = false;
    }).finally(() => {
        submitting.value = false;
    });
}

function updateVerifications() {
    bvnVerication.value = verifications.value.confirm_user_bvn;
    ninVerication.value = verifications.value.confirm_user_nin;
    confirmLocationVerification.value = verifications.value.confirm_shop_address;
    livePhotoVerification.value = verifications.value.confirm_user_photo;
}

const broadCastListener = (id) => {
    Echo.channel('Verifications' + id)
        .listen('.Verification', (e) => {
            verifications.value = e.verification.verifications;
            updateVerifications();
            console.log(e.verification);
        });
};

const rentalAgreementFile = ref();
const rentalAgreement = (e) => {
    rentalAgreementFile.value = e.target.files[0];
}

function handleDragOver(e) {
    e.dataTransfer.dropEffect = 'copy';
    e.preventDefault();
}
</script>

<template>
    <template v-if="!verificationStarted">
        <div>
            <div class="card-body">
                <h4 class="text-success">Get Verified</h4>
                <p style="color: rgba(29, 36, 46, 0.5)">
                    Verify Your account so vendors can trust you and
                    you can access financing and insurance options
                    depending on your region.
                </p>
                <button
                    @click="verificationStarted =!verificationStarted"
                    class="btn btn-success"
                >
                    Start Verification
                </button>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="row">
            <Accordion key="callcenter">
                <template #title>
                    Call Center Verification
                </template>
                <template #status>
                    <div v-if="callCenterverified === null || callCenterverified === 'pending'">
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                width="34"
                                height="34"
                                rx="17"
                                fill="#FFF8EB"
                            />
                            <path
                                d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                stroke="#D78100"
                                stroke-width="1.5"
                            />
                            <path
                                d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                stroke="#D78100"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                        </svg>
                    </div>
                    <div v-if="callCenterverified === 'Correct'">
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                        >
                            <rect
                                width="34"
                                height="34"
                                rx="17"
                                fill="#41AE49"
                            />
                            <path
                                d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                stroke="#F8FBF8"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div v-if="callCenterverified === 'InCorrect'">
                        <span class="badge badge-pill badge-danger">
                             <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                          <rect width="34" height="34" rx="17" fill="#FFF2F0"/><path
                                 d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                 stroke="#E2341D" stroke-width="1.5" stroke-linecap="round"
                                 stroke-linejoin="round"/>
                      </svg>
                         </span>
                    </div>
                    <div v-else>

                    </div>
                </template>
                <template #errors>
                    <!--                <div class="alert alert-danger" role="alert">-->
                    <!--                    <strong>Oh snap!</strong>-->
                    <!--                </div>-->
                </template>
                <div>
                    <p>
                        Call 0791489171 number with your registered number to initiate your verification or wait for
                        our
                        team to call you on your registered number
                    </p>
                </div>
            </Accordion>
            <!--    BVN Verification    -->
            <Accordion key="bvn">
                <template #title>
                    BVN Verification
                </template>
                <template #status>
                    <div v-if="errors && errors.bvn">
                        <div
                            class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                            <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i> Error
                        </div>
                    </div>
                    <template v-else>
                        <div v-if="bvnVerication === 'queuing'">
                            <div
                                class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-yellow-100 tw-text-yellow-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-circle-notch fa-spin fa-fw tw-text-yellow-600"></i> Queueing...
                            </div>
                        </div>
                        <div v-if="bvnVerication === 'running'">
                            <div
                                class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-blue-100 tw-text-blue-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-cog fa-spin fa-fw tw-text-blue-600"></i> Running...
                            </div>
                        </div>
                        <div v-if="bvnVerication === null || bvnVerication === 'pending'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="34"
                                    height="34"
                                    rx="17"
                                    fill="#FFF8EB"
                                />
                                <path
                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                />
                                <path
                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </div>
                        <div v-if="bvnVerication === 'Correct'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                            >
                                <rect
                                    width="34"
                                    height="34"
                                    rx="17"
                                    fill="#41AE49"
                                />
                                <path
                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                    stroke="#F8FBF8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div v-if="bvnVerication === 'InCorrect'">
                        <span class="badge badge-pill badge-danger">
                             <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                          <rect width="34" height="34" rx="17" fill="#FFF2F0"/><path
                                 d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                 stroke="#E2341D" stroke-width="1.5" stroke-linecap="round"
                                 stroke-linejoin="round"/>
                      </svg>
                         </span>
                        </div>
                        <div v-else>

                        </div>
                    </template>

                </template>
                <template #errors>
                    <!--                <div class="alert alert-danger" role="alert">-->
                    <!--                    <strong>Oh snap!</strong>-->
                    <!--                </div>-->
                </template>
                <div>
                    <label for="bvn" class="form-label">Bank Verification Number (BVN)</label>
                    <input type="text" class="form-control form-control-primary" id="bvn" v-model="bvn"
                           aria-describedby="bvn-help"
                           placeholder="Enter your BVN"
                           :class="[errors.bvn ? 'is-invalid': '',!errors.bvn && Object.keys(errors).length > 1 ? 'is-valid': '']"
                    >
                    <div class="invalid-feedback" v-if="errors.bvn">
                        {{ errors.bvn.toString() }}
                    </div>
                </div>
            </Accordion>

            <!--    NIN Verification    -->
            <Accordion key="nin">
                <template #title>
                    NIN Verification
                </template>
                <template #status>
                    <div v-if="errors.national_id">
                        <div
                            class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                            <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i> Error
                        </div>
                    </div>
                    <template v-else>
                        <div v-if="ninVerication === 'queuing'">
                            <div
                                class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-yellow-100 tw-text-yellow-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-circle-notch fa-spin fa-fw tw-text-yellow-600"></i> Queueing...
                            </div>
                        </div>
                        <div v-if="ninVerication === 'running'">
                            <div
                                class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-blue-100 tw-text-blue-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-cog fa-spin fa-fw tw-text-blue-600"></i> Running...
                            </div>
                        </div>
                        <div v-if="ninVerication === null || ninVerication === 'pending'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="34"
                                    height="34"
                                    rx="17"
                                    fill="#FFF8EB"
                                />
                                <path
                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                />
                                <path
                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </div>
                        <div v-if="ninVerication === 'Correct'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                            >
                                <rect
                                    width="34"
                                    height="34"
                                    rx="17"
                                    fill="#41AE49"
                                />
                                <path
                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                    stroke="#F8FBF8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div v-if="ninVerication === 'InCorrect'">
                                            <span class="badge badge-pill badge-danger">
                                                 <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                              <rect width="34" height="34" rx="17" fill="#FFF2F0"/><path
                                                     d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                                     stroke="#E2341D" stroke-width="1.5" stroke-linecap="round"
                                                     stroke-linejoin="round"/>
                                          </svg>
                                             </span>
                        </div>
                        <div v-else>

                        </div>
                    </template>

                </template>
                <template #errors>
                    <!--                <div class="alert alert-danger" role="alert">-->
                    <!--                    <strong>Oh snap!</strong>-->
                    <!--                </div>-->
                </template>
                <!--Label: National Identification, Attributes:nin -->
                <div class="">
                    <label for="nin" class="form-label">National Identification</label>
                    <input type="text" class="form-control" id="nin" v-model="nin"
                           aria-describedby="nin-help"
                           :class="[errors.national_id ? 'is-invalid': '',!errors.national_id && Object.keys(errors).length > 1 ? 'is-valid': '']"
                           placeholder="Enter your National Identification number" required>
                    <div class="invalid-feedback" v-if="errors.national_id">
                        {{ errors.national_id.toString() }}
                    </div>
                </div>
            </Accordion>

            <!--    Location Verification    -->
            <Accordion key="location">
                <template #title>
                    Location Verification
                </template>
                <template #status>
                    <div v-if="errors.address || errors.city">
                        <div
                            class="tw-inline-block tw-w-22 tw-px-3 tw-py-1 tw-rounded-full tw-bg-red-100 tw-text-red-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                            <i class="fa fa-hexagon-exclamation fa-beat-fade fa-fw tw-text-red-600"></i> Error
                        </div>
                    </div>
                    <template v-else>
                        <div v-if="confirmLocationVerification === 'queuing'">
                            <div
                                class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-yellow-100 tw-text-yellow-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-circle-notch fa-spin fa-fw tw-text-yellow-600"></i> Queueing...
                            </div>
                        </div>
                        <div v-if="confirmLocationVerification === 'running'">
                            <div
                                class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-blue-100 tw-text-blue-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                                <i class="fa fa-cog fa-spin fa-fw tw-text-blue-600"></i> Running...
                            </div>
                        </div>
                        <div v-if="confirmLocationVerification === null || confirmLocationVerification === 'pending'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="34"
                                    height="34"
                                    rx="17"
                                    fill="#FFF8EB"
                                />
                                <path
                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                />
                                <path
                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                    stroke="#D78100"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </div>
                        <div v-if="confirmLocationVerification === 'Correct'">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                            >
                                <rect
                                    width="34"
                                    height="34"
                                    rx="17"
                                    fill="#41AE49"
                                />
                                <path
                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                    stroke="#F8FBF8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div v-if="confirmLocationVerification === 'InCorrect'">
                        <span class="badge badge-pill badge-danger">
                             <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                          <rect width="34" height="34" rx="17" fill="#FFF2F0"/><path
                                 d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                 stroke="#E2341D" stroke-width="1.5" stroke-linecap="round"
                                 stroke-linejoin="round"/>
                      </svg>
                         </span>
                        </div>
                        <div v-else>

                        </div>
                    </template>

                </template>
                <template #errors>
                    <!--                <div class="alert alert-danger" role="alert">-->
                    <!--                    <strong>Oh snap!</strong>-->
                    <!--                </div>-->
                </template>
                <!--Label: Market Address, Attributes:marketaddress -->
                <div class="form-group">
                    <label for="marketaddress" class="form-label">Address</label>
                    <input type="text" class="form-control" id="marketaddress" v-model="marketaddress"
                           aria-describedby="marketaddress-help"
                           :class="[errors.address ? 'is-invalid': '',!errors.address && Object.keys(errors).length > 1 ? 'is-valid': '']"
                           placeholder="Address" required>
                    <div class="invalid-feedback" v-if="errors.address">
                        {{ errors.address.toString() }}
                    </div>
                </div>

                <!--Label: Market City, Attributes:market_city -->
                <div class="form-group mt-3">
                    <label for="market_city" class="form-label">City</label>
                    <input type="text" class="form-control" id="market_city" v-model="market_city"
                           aria-describedby="market_city-help"
                           :class="[errors.city ? 'is-invalid': '',!errors.city && Object.keys(errors).length > 1 ? 'is-valid': '']"
                           placeholder="City" required>
                    <div class="invalid-feedback" v-if="errors.city">
                        {{ errors.city.toString() }}
                    </div>
                </div>

                <!--Label: Rent Agreement or Proof of Ownership, Attributes:proof_of_ownership -->
                <div class="form-group mt-3 drag-target" @dragover.prevent="handleDragOver">
                    <label for="proof_of_ownership" class="form-label">Rent Agreement or Proof of Ownership</label>
                    <input type="file" class="form-control" id="proof_of_ownership"
                           aria-describedby="proof_of_ownership-help"
                           @change="rentalAgreement"
                           :class="[errors.proof_of_ownership ? 'is-invalid': '',!errors.proof_of_ownership && Object.keys(errors).length > 1 ? 'is-valid': '']"
                           placeholder="Rent Agreement or Proof of Ownership" required>
                    <div class="invalid-feedback" v-if="errors.proof_of_ownership">
                        {{ errors.proof_of_ownership.toString() }}
                    </div>
                </div>
            </Accordion>


            <Accordion key="liveVerification">
                <template #title>
                    Live Verification
                </template>
                <template #status>
                    <div v-if="livePhotoVerification === 'queuing'">
                        <div
                            class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-yellow-100 tw-text-yellow-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                            <i class="fa fa-circle-notch fa-spin fa-fw tw-text-yellow-600"></i> Queueing...
                        </div>
                    </div>
                    <div v-if="livePhotoVerification === 'running'">
                        <div
                            class="tw-inline-block tw-w-32 tw-px-3 tw-py-1 tw-rounded-full tw-bg-blue-100 tw-text-blue-800 tw-font-bold tw-text-sm tw-whitespace-nowrap tw-cursor-wait">
                            <i class="fa fa-cog fa-spin fa-fw tw-text-blue-600"></i> Running...
                        </div>
                    </div>
                    <div v-if="livePhotoVerification === null || livePhotoVerification === 'pending'">
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                width="34"
                                height="34"
                                rx="17"
                                fill="#FFF8EB"
                            />
                            <path
                                d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                stroke="#D78100"
                                stroke-width="1.5"
                            />
                            <path
                                d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                stroke="#D78100"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                        </svg>
                    </div>
                    <div v-if="livePhotoVerification === 'Correct'">
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                        >
                            <rect
                                width="34"
                                height="34"
                                rx="17"
                                fill="#41AE49"
                            />
                            <path
                                d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                stroke="#F8FBF8"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div v-if="livePhotoVerification === 'InCorrect'">
                        <span class="badge badge-pill badge-danger">
                             <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                          <rect width="34" height="34" rx="17" fill="#FFF2F0"/><path
                                 d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                 stroke="#E2341D" stroke-width="1.5" stroke-linecap="round"
                                 stroke-linejoin="round"/>
                      </svg>
                         </span>
                    </div>
                    <div v-else>

                    </div>
                </template>
                <template #errors>
                    <!--                <div class="alert alert-danger" role="alert">-->
                    <!--                    <strong>Oh snap!</strong>-->
                    <!--                </div>-->
                </template>
                <CameraCapture
                    :uploaded_photo="null"
                    :user_id="props.user.id"
                ></CameraCapture>
            </Accordion>
        </div>
        <!--        <div class="row tw-ml-3 tw-mr-3">-->
        <!--            <div class="alert alert-warning" role="alert">-->

        <!--                <p>-->
        <!--                    <strong>-->
        <!--                        {{ currency }}150 will be deducted from your wallet balance.-->
        <!--                        This deduction will occur automatically once you submit your details.-->
        <!--                    </strong>-->
        <!--                </p>-->

        <!--                <p>-->
        <!--                    If you have any questions or concerns about this deduction, please don't hesitate to contact our-->
        <!--                    support team for assistance.-->
        <!--                </p>-->
        <!--            </div>-->
        <!--        </div>-->

        <div class="row tw-ml-3 tw-mr-3">
            <div class="d-flex justify-content-end" role="alert">
                <button class="btn btn-secondary mr-3 mb-3"
                        :disabled="submitting"
                        @click.prevent="submit">
                    Submit
                    <i class="fa fa-spin fa-spinner" v-if="submitting"></i>
                    <i class="fa fa-thin fa-save" v-else>
                    </i>
                </button>
            </div>
        </div>
    </template>
</template>

<style>
.drag-target {
    //border: 1px dashed #ccc;
    //border-radius: 5px;
    //padding: 10px;
    //text-align: center;
}

.drag-target.dragging {
    background-color: #aaa;
}
</style>

