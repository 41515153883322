<script setup>
import {onMounted, ref} from "vue";
import Swal from "sweetalert2";
import moment from "moment";
import WebsiteInformation from "../../v4/profile/WebsiteInformation.vue";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
    market: {
        type: Object,
        required: true,
    },
    subscription_plan: {
        type: Object || String,
        required: true,
    },
    onboarding: {
        type: Object,
        required: true,
    },
    is_marketleader: {
        type: Boolean,
        required: true,
    },
});

const errors = ref("");
const phone = ref(props.user.phone);

const user_avatar = ref(props.user.user_avatar);
const first_name = ref(props.user.first_name);
const last_name = ref(props.user.last_name);
const old_password = ref("");
const new_password = ref("");
const confirm_password = ref("");
const fileupload = ref("");
const email = ref(props.user.email);
const avatar = ref("");

function hideReadMore() {
    var myModalEl = document.querySelector("#referralReadMore");
    var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    modal.hide();
}

function readMore() {
    var myModalEl = document.querySelector("#referralReadMore");
    var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    modal.show();
}

const savingProfile = ref(false);

function updateAccount(confirm = '') {
    savingProfile.value = true;
    const formData = new FormData();
    formData.append("avatar", avatar.value);
    formData.append("phone", phone.value);
    formData.append("first_name", first_name.value);
    formData.append("last_name", last_name.value);
    formData.append("email", email.value);
    if (confirm === 'removeAvatar') {
        formData.append("removeAvatar", true);
    }

    axios
        .post("/apis/v4/editingProfile", formData)
        .then((resp) => {
            Swal.fire({
                position: "top-end",
                toast: true,
                icon: "success",
                title: "Your account details have been updated.",
                showConfirmButton: false,
                timer: 2500,
            });
            email.value = resp.data.user.email;
            first_name.value = resp.data.user.first_name;
            last_name.value = resp.data.user.last_name;
            phone.value = resp.data.user.phone;
            user_avatar.value = resp.data.user.user_avatar;
            savingProfile.value = false;
        })
        .catch((err) => {
            Swal.fire({
                position: "top-end",
                toast: true,
                icon: "error",
                title: "We had issues updating your account.",
                showConfirmButton: false,
                timer: 2000,
            });
            savingProfile.value = false;
        });
}

function upload_photo() {
    const fileInput = fileupload.value;

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(fileInput.files[0].type)) {
        Swal.fire({
            position: "top-end",
            toast: true,
            icon: "error",
            title: "Upload only images.",
            showConfirmButton: false,
            timer: 2000,
        });
        return;
    }

    // Check file size (in bytes)
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (fileInput.files[0].size > maxSizeInBytes) {
        Swal.fire({
            position: "top-end",
            toast: true,
            icon: "error",
            title: "Limited to a maximum of 5MB.",
            showConfirmButton: false,
            timer: 2000,
        });
        return;
    }

    avatar.value = fileInput.files[0];
}

function updatePassword() {
    axios
        .post("/apis/v4/update-password", {
            current_password: old_password.value,
            password: new_password.value,
            password_confirmation: confirm_password.value,
        })
        .then((resp) => {
            Swal.fire({
                position: "top-end",
                toast: true,
                icon: "success",
                title: "Password updated successfully.",
                showConfirmButton: false,
                timer: 2000,
            });
        })
        .catch((err) => {
            Swal.fire({
                position: "top-end",
                toast: true,
                icon: "error",
                title: "Check your input and try again. Your current password must be correct.",
                showConfirmButton: false,
                timer: 2000,
            });
        });
}

const svgImage = ref("");

function enableTwoFactorAuth() {
    axios.post("/four/enable-two-factor-auth").then((resp) => {
        axios.get("/four/enable-two-factor-auth").then((resp) => {
            svgImage.value = resp.data.svg;
        });
    });
}

const confirm_otp = ref("");
const twoFactorEnabled = ref(props.user.two_factor_confirmed_at);

function confirmTwoFactorAuthentication() {
    axios
        .post("/four/user/confirmed-two-factor-authentication", {
            code: confirm_otp.value,
        })
        .then((resp) => {
            if (resp.data.confirmed) {
                twoFactorEnabled.value = true;
            }
        });
}

onMounted(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get("referral_program")) {
        var triggerFirstTabEl = document.querySelector(
            "#myTab li:nth-child(4) a"
        );
        triggerFirstTabEl.click();
    }

    if (urlParams.get("website")) {
        document.querySelector("#website-tab").click()
    }

    getReferralObject();

    customizeSubscription()
});
const showUpgrade = ref(false);
const customizeSubscription = () => {
    if (props.subscription_plan === 'Free') {
        showUpgrade.value = true;
    }

    if (Object.keys(props.subscription_plan).length < 1) {
        showUpgrade.value = true;
    }
};
const referral_code = ref("");
const total_referrals = ref("");
const number_of_market_members = ref("");
const number_of_markets = ref("");
const total_earnings = ref("");
const current_balance = ref("");
const total_cash_out = ref("");
const Referrals = ref("");
const getReferralObject = () => {
    axios
        .post("/apis/v4/referral_program")
        .then((resp) => {
            referral_code.value = resp.data.referral_code;
            total_referrals.value = resp.data.total_referrals;
            number_of_market_members.value = resp.data.number_of_market_members;
            number_of_markets.value = resp.data.number_of_markets;
            total_earnings.value = resp.data.total_earnings;
            current_balance.value = resp.data.current_balance;
            total_cash_out.value = resp.data.total_cash_out;
            Referrals.value = resp.data.referrals;
        })
        .catch((err) => {
        });
};

function formatDate(date) {
    return moment(date).format("LL");
    return "Dec 232";
}
</script>

<template>
    <div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link active hero-example"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                >
                    Account Details
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                >
                    Security
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                >
                    Profile Preferences
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <a
                    class="nav-link"
                    id="referral-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#referral"
                    type="button"
                    role="tab"
                    aria-controls="referral"
                    aria-selected="false"
                >
                    Referral Program
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="things-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#things"
                    type="button"
                    role="tab"
                    aria-controls="things"
                    aria-selected="false"
                >
                    Things to do
                </button>
            </li>
            <li class="nav-item" role="presentation" >
                <button
                    class="nav-link"
                    id="website-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#website"
                    type="button"
                    role="tab"
                    aria-controls="website"
                    aria-selected="false"
                >
                    Website
                </button>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
            >
                <div class="card">
                    <div class="card-body mt-5">
                        <div class="row">
                            <div class="col-md-8 offset-md-2">
                                <h6 class="card-title text-muted">
                  <span
                      class="fa-stack"
                      style="vertical-align: center; color: #bfdbfe"
                  >
                    <i class="fa-solid fa-circle fa-stack-2x"></i>
                    <i
                        class="fa-solid fa-file fa-stack-1x"
                        style="color: #075985"
                    ></i>
                  </span>
                                    Account Details
                                </h6>
                                <div>
                                    <form action="">
                                        <div class="row gap-2 mt-4">
                                            <div class="col-md-12">
                                                <div class="mb-2">
                                                    <label for="upload_photo">Avatar</label>
                                                    <p class="help-block text-muted">
                                                        Recommended size 200 x 200
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div v-if="user_avatar">
                                                        <div class="d-flex">
                                                            <img
                                                                :src="user_avatar"
                                                                class="user-img"
                                                                alt="user avatar"
                                                            />
                                                            <div class="d-flex" style="margin-left: 1rem">
                                                                <!--Label: Upload Photo, Attributes:upload_photo -->
                                                                <div class="form-group">
                                                                    <input
                                                                        type="file"
                                                                        class="form-control"
                                                                        ref="fileupload"
                                                                        id="upload_photo"
                                                                        accept="image/*"
                                                                        @change="upload_photo"
                                                                        aria-describedby="upload_photo-help"
                                                                        :class="[
                                                                    errors.upload_photo
                                                                        ? 'is-invalid'
                                                                        : '',
                                                                    !errors.upload_photo &&
                                                                    Object.keys(
                                                                        errors
                                                                    ).length > 1
                                                                        ? 'is-valid'
                                                                        : '',
                                                                ]"
                                                                    />

                                                                    <div
                                                                        class="invalid-feedback"
                                                                        v-if="
                                                                    errors.upload_photo
                                                                "
                                                                    >
                                                                        {{
                                                                            errors.upload_photo.toString()
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="mr-5" style="width: 10px"></div>
                                                            <div class="ml-5 pl-5">
                                                                <button
                                                                    class="btn btn-outline-secondary"
                                                                    @click.prevent="updateAccount('removeAvatar')"
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="d-flex">
                                                            <img
                                                                src="/v4/avatar.png"
                                                                class="user-img"
                                                                alt="user avatar"
                                                            />
                                                            <div class="d-flex" style="margin-left: 1rem">
                                                                <!--Label: Upload Photo, Attributes:upload_photo -->
                                                                <div class="form-group">
                                                                    <input
                                                                        type="file"
                                                                        class="form-control"
                                                                        ref="fileupload"
                                                                        id="upload_photo"
                                                                        accept="image/*"
                                                                        @change="
                                                                    upload_photo
                                                                "
                                                                        aria-describedby="upload_photo-help"
                                                                        :class="[
                                                                    errors.upload_photo
                                                                        ? 'is-invalid'
                                                                        : '',
                                                                    !errors.upload_photo &&
                                                                    Object.keys(
                                                                        errors
                                                                    ).length > 1
                                                                        ? 'is-valid'
                                                                        : '',
                                                                ]"
                                                                    />

                                                                    <div
                                                                        class="invalid-feedback"
                                                                        v-if="
                                                                    errors.upload_photo
                                                                "
                                                                    >
                                                                        {{
                                                                            errors.upload_photo.toString()
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-3">
                                                <!--Label: Phone, Attributes:phone -->
                                                <div class="form-floating">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="phone"
                                                        v-model="phone"
                                                        disabled
                                                        aria-describedby="phone-help"
                                                        :class="[
                                                            errors.phone
                                                                ? 'is-invalid'
                                                                : '',
                                                            !errors.phone &&
                                                            Object.keys(errors)
                                                                .length > 1
                                                                ? 'is-valid'
                                                                : '',
                                                        ]"
                                                    />
                                                    <label for="phone">Phone</label>

                                                    <div class="invalid-feedback" v-if="errors.phone">
                                                        {{
                                                            errors.phone.toString()
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <!--Label: First Name, Attributes:first_name -->
                                                <div class="form-floating">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="first_name"
                                                        v-model="first_name"
                                                        aria-describedby="first_name-help"
                                                        :class="[
                                                            errors.first_name
                                                                ? 'is-invalid'
                                                                : '',
                                                            !errors.first_name &&
                                                            Object.keys(errors)
                                                                .length > 1
                                                                ? 'is-valid'
                                                                : '',
                                                        ]"
                                                    />
                                                    <label for="first_name">First Name</label>
                                                    <div
                                                        class="invalid-feedback"
                                                        v-if="errors.first_name"
                                                    >
                                                        {{
                                                            errors.first_name.toString()
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <!--Label: Last Name, Attributes:last_name -->
                                                <div class="form-floating">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="last_name"
                                                        v-model="last_name"
                                                        aria-describedby="last_name-help"
                                                        :class="[
                                                            errors.last_name
                                                                ? 'is-invalid'
                                                                : '',
                                                            !errors.last_name &&
                                                            Object.keys(errors)
                                                                .length > 1
                                                                ? 'is-valid'
                                                                : '',
                                                        ]"
                                                    />
                                                    <label for="last_name">Last Name</label>
                                                    <div class="invalid-feedback" v-if="errors.last_name">
                                                        {{
                                                            errors.last_name.toString()
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <!--Label: Email, Attributes:email -->
                                                <div class="form-floating">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        id="email"
                                                        v-model="email"
                                                        aria-describedby="email-help"
                                                        :class="[
                                                            errors.email
                                                                ? 'is-invalid'
                                                                : '',
                                                            !errors.email &&
                                                            Object.keys(errors)
                                                                .length > 1
                                                                ? 'is-valid'
                                                                : '',
                                                        ]"
                                                    />
                                                    <label for="email">Email</label>
                                                    <div class="invalid-feedback" v-if="errors.email">
                                                        {{
                                                            errors.email.toString()
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="d-flex justify-content-end">
                                                    <button
                                                        class="btn btn-sm btn-outline-secondary"
                                                        @click.prevent="updateAccount"
                                                    >
                                                        Save Changes
                                                        <i
                                                            class="fa fa-spin fa-spinner"
                                                            v-if="savingProfile"
                                                        ></i>
                                                        <i v-else class="fa fa-save fa-sm"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-8 offset-md-2 mt-4">
                                <h6 class="card-title text-muted">
                  <span
                      class="fa-stack"
                      style="vertical-align: center; color: #ffcccc"
                  >
                    <i class="fa-solid fa-circle fa-stack-2x"></i>
                    <i
                        class="fa-solid fa-lock fa-stack-1x"
                        style="color: #b35a5a"
                    ></i>
                  </span>
                                    Password
                                </h6>
                                <div>
                                    <form action="">
                                        <div class="row gap-3 mt-4">
                                            <div class="col-md-12">
                                                <!--Label: Old Password, Attributes:old_password -->
                                                <div class="form-floating">
                                                    <input
                                                        type="password"
                                                        class="form-control"
                                                        id="old_password"
                                                        v-model="old_password"
                                                        aria-describedby="old_password-help"
                                                        :class="[
                                                            errors.old_password
                                                                ? 'is-invalid'
                                                                : '',
                                                            !errors.old_password &&
                                                            Object.keys(errors)
                                                                .length > 1
                                                                ? 'is-valid'
                                                                : '',
                                                        ]"
                                                    />
                                                    <label for="old_password">Current Password</label>

                                                    <div
                                                        class="invalid-feedback"
                                                        v-if="
                                                            errors.old_password
                                                        "
                                                    >
                                                        {{
                                                            errors.old_password.toString()
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <!--Label: New Password, Attributes:new_password -->
                                                <div class="form-floating">
                                                    <input
                                                        type="password"
                                                        class="form-control"
                                                        id="new_password"
                                                        v-model="new_password"
                                                        aria-describedby="new_password-help"
                                                        :class="[
                                                            errors.new_password
                                                                ? 'is-invalid'
                                                                : '',
                                                            !errors.new_password &&
                                                            Object.keys(errors)
                                                                .length > 1
                                                                ? 'is-valid'
                                                                : '',
                                                        ]"
                                                    />
                                                    <label for="new_password">New Password</label>
                                                    <div
                                                        class="invalid-feedback"
                                                        v-if="
                                                            errors.new_password
                                                        "
                                                    >
                                                        {{
                                                            errors.new_password.toString()
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <!--Label: Confirm Password, Attributes:confirm_password -->
                                                <div class="form-floating">
                                                    <input
                                                        type="password"
                                                        class="form-control"
                                                        id="confirm_password"
                                                        v-model="
                                                            confirm_password
                                                        "
                                                        aria-describedby="confirm_password-help"
                                                        :class="[
                                                            errors.confirm_password
                                                                ? 'is-invalid'
                                                                : '',
                                                            !errors.confirm_password &&
                                                            Object.keys(errors)
                                                                .length > 1
                                                                ? 'is-valid'
                                                                : '',
                                                        ]"
                                                    />
                                                    <label for="confirm_password">Confirm Password</label>
                                                    <div
                                                        class="invalid-feedback"
                                                        v-if="
                                                            errors.confirm_password
                                                        "
                                                    >
                                                        {{
                                                            errors.confirm_password.toString()
                                                        }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="d-flex justify-content-end">
                                                    <button
                                                        class="btn btn-sm btn-outline-secondary"
                                                        @click.prevent="
                                                            updatePassword
                                                        "
                                                    >
                                                        Update Password
                                                        <i class="fa fa-save fa-sm"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
            >
                <div class="card">
                    <div class="card-body" v-if="!twoFactorEnabled">
                        <h5 class="card-title text-muted mt-4">
                            Two Factor Authentication
                        </h5>
                        <p class="card-text">
                            Use an authenticator app to enhance the security of your account.
                            When setup, you will need a verification code when you login to
                            Amana Market.
                        </p>
                        <button
                            href="#"
                            class="btn btn-outline-primary btn-sm"
                            style="border-radius: 1rem"
                            @click.prevent="enableTwoFactorAuth"
                        >
                            Setup two factor authentication
                            <i class="fa-light fa-angle-right"></i>
                        </button>

                        <div class="mt-5">
                            <div v-if="svgImage">
                                <span v-html="svgImage"></span>

                                <div class="mt-3">
                                    <!--Label: Confirm OTP, Attributes:confirm_otp -->
                                    <div class="form-floating">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="confirm_otp"
                                            v-model="confirm_otp"
                                            aria-describedby="confirm_otp-help"
                                            :class="[
                                                errors.confirm_otp
                                                    ? 'is-invalid'
                                                    : '',
                                                !errors.confirm_otp &&
                                                Object.keys(errors).length > 1
                                                    ? 'is-valid'
                                                    : '',
                                            ]"
                                        />
                                        <label for="confirm_otp">Confirm OTP</label>

                                        <div class="invalid-feedback" v-if="errors.confirm_otp">
                                            {{ errors.confirm_otp.toString() }}
                                        </div>
                                    </div>

                                    <div class="mt-3">
                                        <button
                                            class="btn btn-secondary btn-sm"
                                            @click.prevent="
                                                confirmTwoFactorAuthentication
                                            "
                                        >
                                            Confirm
                                            <i class="fa fa-save fa-xs"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" v-else>Two Factor is enabled</div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
            >
                <div class="card">
                    <div class="card-body mt-5">
                        <div class="row">
                            <div class="col-8">
                                <h6 class="card-title text-muted">
                  <span
                      class="fa-stack"
                      style="vertical-align: center; color: #fae7f7"
                  >
                    <i class="fa-solid fa-circle fa-stack-2x"></i>
                    <i
                        class="fa-solid fa-bell fa-stack-1x"
                        style="color: #d95ab4"
                    ></i>
                  </span>
                                    Notifications
                                </h6>
                                <div class="row mt-4" style="margin-left: 2rem">
                                    <div class="d-flex justify-content-between">
                                        <div>Turn on Market notifications</div>
                                        <div class="text-muted">Receive updates via SMS</div>
                                        <div>
                                            <div class="form-check form-switch">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckDefault1"
                                                    checked=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-left: 2rem">
                                <hr/>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-8">
                                <h6 class="card-title text-muted">
                  <span
                      class="fa-stack"
                      style="vertical-align: center; color: #cce8ff"
                  >
                    <i class="fa-solid fa-circle fa-stack-2x"></i>
                    <i
                        class="fa-solid fa-wallet fa-stack-1x"
                        style="color: #001c33"
                    ></i>
                  </span>
                                    Wallet Balance
                                </h6>
                                <div class="row mt-4" style="margin-left: 2rem">
                                    <div class="d-flex justify-content-between">
                                        <div>Low wallet balance notification</div>
                                        <div class="text-muted">
                                            Get reminded when my wallet balance is low
                                        </div>
                                        <div>
                                            <div class="form-check form-switch">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckDefault1"
                                                    checked=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-left: 2rem">
                                <hr/>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="d-flex justify-content-end">
                                <button
                                    class="btn btn-secondary btn-sm"
                                    @click.prevent="updateAccount"
                                >
                                    Save Changes
                                    <i class="fa fa-spin fa-spinner" v-if="savingProfile"></i>
                                    <i v-else class="fa fa-save fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="referral"
                role="tabpanel"
                aria-labelledby="referral-tab"
            >
                <div class="card">
                    <div
                        class="card-body"
                        style="
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
            "
                    >
                        <div
                            style="
                width: 100%;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                flex: 1;
              "
                        >
                            <div
                                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;
                  width: 100%;
                  gap: 0.5rem;
                "
                            >
                                <h4 style="font-size: 18px; padding: 0; margin: 0">
                                    Refer a friend or market and get paid
                                </h4>
                            </div>
                            <p style="padding: 0; margin: 0.5rem 0">
                                Earn rewards on Amana Market by referring new users and markets
                                <span
                                    style="
                    text-decoration: underline;
                    color: #437ef7;
                    cursor: pointer;
                  "
                                    @click="readMore"
                                >read more</span
                                >
                            </p>

                            <div
                                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  gap: 0.3rem;
                  width: 100%;
                  background: #f5f5f5;
                  border: 1px solid #f5f5f5;
                  padding: 11px 24px;
                  flex-wrap: wrap;
                  border-radius: 6px;
                  overflow-x: hidden;
                "
                            >
                                <p
                                    style="
                    margin: 0;
                    font-weight: 600;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                                >
                                    {{ referral_code }}
                                </p>
                                <div
                                    style="
                    color: #00ab4d;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    gap: 0.2rem;
                    align-items: center;
                    cursor: pointer;
                  "
                                    @click="copyToClipboard(referral_code)"
                                >
                                    <i
                                        class="fa-light fa-copy"
                                        style="cursor: pointer; color: #00ab4d"
                                    ></i>
                                    <p style="margin: 0; padding: 0">Copy code</p>
                                </div>
                            </div>
                            <p style="padding: 0; font-size: 0.75rem; margin: 0.5rem 0">
                                By using the code you're agreeing to
                                <span
                                ><a
                                    target="_blank"
                                    rel="noreferrer noopenner"
                                    href="#"
                                    style="cursor: pointer; color: #00ab4d"
                                >AmanaMarket's Terms and Conditions</a
                                ></span
                                >
                                and to our processing of your information in accordance with the
                                privacy notice.
                            </p>
                        </div>

                        <hr style="color: grey; width: 100%; margin: 1.5rem 0"/>

                        <div
                            style="
                width: 100%;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                gap: 1.25rem;
                min-height: 8rem;
                margin-bottom: 1.5rem;
              "
                        >
                            <div
                                style="
                  background: #fafafa;
                  border-radius: 0.3125rem;
                  border: 1px solid #eaebf0;
                  padding: 1rem;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-start;
                  gap: 1rem;
                "
                            >
                                <svg
                                    width="44"
                                    height="44"
                                    viewBox="0 0 44 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect width="44" height="44" rx="22" fill="#F0FAF0"/>
                                    <path
                                        d="M14 15V29H30V13"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M24 29L24 23.5372C24 21.3938 20 21.5836 20 23.5372L20 29"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M24 32L20 32"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M13 15L20.7351 12.2601C21.9889 11.9133 22.0111 11.9133 23.2649 12.2601L31 15"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M22.0119 18H22"
                                        stroke="#41AE49"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <div
                                    style="
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: column;
                    gap: 1rem;
                  "
                                >
                                    <div
                                        style="
                      width: 100%;
                      display: flex;
                      align-items: flex-start;
                      justify-content: center;
                      gap: 0.25rem;
                      flex-direction: column;
                    "
                                    >
                                        <p
                                            style="
                        color: #6b7b8f;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        margin: 0;
                        padding: 0;
                      "
                                        >
                                            Total Markets Referred
                                        </p>
                                        <p
                                            style="
                        color: #2d3142;
                        margin: 0;
                        padding: 0;
                        font-size: 1.25rem;
                        font-style: normal;
                        font-weight: 600;
                      "
                                        >
                                            {{ total_referrals || 0 }}
                                        </p>
                                    </div>
                                    <div
                                        style="width: 100%; height: 1px; background: #eaebf0"
                                    ></div>
                                    <div
                                        style="
                      width: 100%;
                      display: flex;
                      align-items: flex-start;
                      justify-content: center;
                      gap: 0.25rem;
                      flex-direction: column;
                    "
                                    >
                                        <p
                                            style="
                        color: #6b7b8f;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        margin: 0;
                        padding: 0;
                      "
                                        >
                                            Total earning from markets
                                        </p>
                                        <p
                                            style="
                        color: #2d3142;
                        font-size: 1.25rem;
                        font-style: normal;
                        font-weight: 600;
                        margin: 0;
                        padding: 0;
                      "
                                        >
                                            {{ currency }}{{ total_earnings || 0 }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                style="
                  border-radius: 0.3125rem;
                  border: 1px solid #eaebf0;
                  background: #fafafa;
                  padding: 1rem;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-start;
                  gap: 1rem;
                "
                            >
                                <svg
                                    width="44"
                                    height="44"
                                    viewBox="0 0 44 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect width="44" height="44" rx="22" fill="#F0FAF0"/>
                                    <path
                                        d="M30.774 28C31.5233 28 32.1193 27.5285 32.6545 26.8691C33.7499 25.5194 31.9513 24.4408 31.2654 23.9126C30.568 23.3756 29.7894 23.0714 29 23M28 21C29.3807 21 30.5 19.8807 30.5 18.5C30.5 17.1193 29.3807 16 28 16"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M13.226 28C12.4767 28 11.8807 27.5285 11.3455 26.8691C10.2501 25.5194 12.0487 24.4408 12.7346 23.9126C13.432 23.3756 14.2106 23.0714 15 23M15.5 21C14.1193 21 13 19.8807 13 18.5C13 17.1193 14.1193 16 15.5 16"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M18.0838 25.1112C17.062 25.743 14.383 27.0331 16.0147 28.6474C16.8118 29.436 17.6995 30 18.8156 30H25.1844C26.3005 30 27.1882 29.436 27.9853 28.6474C29.617 27.0331 26.938 25.743 25.9162 25.1112C23.5201 23.6296 20.4799 23.6296 18.0838 25.1112Z"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M25.5 17.5C25.5 19.433 23.933 21 22 21C20.067 21 18.5 19.433 18.5 17.5C18.5 15.567 20.067 14 22 14C23.933 14 25.5 15.567 25.5 17.5Z"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                    />
                                </svg>
                                <div
                                    style="
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-direction: column;
                    gap: 1rem;
                  "
                                >
                                    <div
                                        style="
                      width: 100%;
                      display: flex;
                      align-items: flex-start;
                      justify-content: center;
                      gap: 0.25rem;
                      flex-direction: column;
                    "
                                    >
                                        <p
                                            style="
                        color: #6b7b8f;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        margin: 0;
                        padding: 0;
                      "
                                        >
                                            Total Members Referred
                                        </p>
                                        <p
                                            style="
                        color: #2d3142;
                        margin: 0;
                        padding: 0;
                        font-size: 1.25rem;
                        font-style: normal;
                        font-weight: 600;
                      "
                                        >
                                            <!-- {{ total_earnings ?? 0 }} -->
                                            0
                                        </p>
                                    </div>
                                    <div
                                        style="width: 100%; height: 1px; background: #eaebf0"
                                    ></div>
                                    <div
                                        style="
                      width: 100%;
                      display: flex;
                      align-items: flex-start;
                      justify-content: center;
                      gap: 0.25rem;
                      flex-direction: column;
                    "
                                    >
                                        <p
                                            style="
                        color: #6b7b8f;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        margin: 0;
                        padding: 0;
                      "
                                        >
                                            Total earning from members
                                        </p>
                                        <p
                                            style="
                        color: #2d3142;
                        font-size: 1.25rem;
                        font-style: normal;
                        font-weight: 600;
                        margin: 0;
                        padding: 0;
                      "
                                        >
                                            {{ currency }}{{ total_earnings || 0 }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                style="
                  border-radius: 0.3125rem;
                  border: 1px solid #eaebf0;
                  background: #fafafa;
                  padding: 1rem;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-start;
                  gap: 1rem;
                "
                            >
                                <svg
                                    width="44"
                                    height="44"
                                    viewBox="0 0 44 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect width="44" height="44" rx="22" fill="#F0FAF0"/>
                                    <path
                                        d="M13 25.0614V16C15.1047 16.6213 19.5762 17.4267 25.0038 17.8028C27.9252 18.0052 29.3859 18.1065 30.1929 18.9769C31 19.8473 31 21.2497 31 24.0546V26.0683C31 28.9566 31 30.4008 30.0163 31.2998C29.0325 32.1987 27.6919 32.0677 25.0106 31.8058C23.7295 31.6806 22.3748 31.509 21 31.2775"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M27.6258 18C28.0035 16.5767 28.3453 13.9882 27.327 12.7029C26.6816 11.8883 25.7223 11.9665 24.7818 12.0493C19.8379 12.4841 16.3454 13.3673 14.393 13.9674C13.5535 14.2254 13 15.0452 13 15.9604"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M25 25C25 25.8284 25.6716 26.5 26.5 26.5C27.3284 26.5 28 25.8284 28 25C28 24.1716 27.3284 23.5 26.5 23.5C25.6716 23.5 25 24.1716 25 25Z"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                    />
                                    <path
                                        d="M13 29C13 29 14 29 15 31C15 31 18.1765 26 21 25"
                                        stroke="#41AE49"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>

                                <div
                                    style="
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 1rem;
                  "
                                >
                                    <div
                                        style="
                      width: 100%;
                      display: flex;
                      align-items: flex-start;
                      justify-content: center;
                      gap: 0.25rem;
                      flex-direction: column;
                    "
                                    >
                                        <p
                                            style="
                        color: #6b7b8f;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        margin: 0;
                        padding: 0;
                      "
                                        >
                                            Total Cash out
                                        </p>
                                        <p
                                            style="
                        color: #2d3142;
                        font-size: 1.25rem;
                        font-style: normal;
                        font-weight: 600;
                        margin: 0;
                        padding: 0;
                      "
                                        >
                                            {{ currency }}{{ total_cash_out || 0 }}
                                        </p>
                                    </div>
                                    <button
                                        style="
                      display: flex;
                      padding: 0.5rem 0.75rem;
                      justify-content: center;
                      align-items: center;
                      gap: 0.375rem;
                      border-radius: 0.3125rem;
                      border: 1px solid #41ae49;
                      background: #41ae49;
                      color: #fff;
                      white-space: nowrap;
                      font-size: 0.75rem;
                    "
                                    >
                                        Withdraw to wallet
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M6 12L9.29289 8.70711C9.62623 8.37377 9.79289 8.20711 9.79289 8C9.79289 7.79289 9.62623 7.62623 9.29289 7.29289L6 4"
                                                stroke="white"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul
                        class="nav nav-tabs"
                        id="referralTabList"
                        role="tablist"
                        style="padding: 0rem 1rem 0 1rem"
                    >
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                id="earning-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#earning"
                                type="button"
                                role="tab"
                                aria-controls="earning"
                                aria-selected="true"
                            >
                                Earning History
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="cashout-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#cashout"
                                type="button"
                                role="tab"
                                aria-controls="cashout"
                                aria-selected="false"
                            >
                                Cash-out History
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content" id="referralTabListContent">
                        <div
                            class="tab-pane fade show active"
                            id="earning"
                            role="tabpanel"
                            aria-labelledby="earning-tab"
                        >
                            <div id="new-table" style="padding: 1.5rem 1rem; width: 100%">
                                <div
                                    v-if="Referrals.length < 1"
                                    style="
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 1rem 0;
                    margin: 0 auto;
                    width: 100%;
                    max-width: 21rem;
                    gap: 0.8rem;
                  "
                                >
                                    <svg
                                        width="194"
                                        height="141"
                                        viewBox="0 0 194 141"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.529297 79.1374C0.529297 45.0798 28.1384 17.4707 62.196 17.4707H132.063C166.12 17.4707 193.729 45.0798 193.729 79.1374C193.729 113.195 166.12 140.804 132.063 140.804H62.1959C28.1383 140.804 0.529297 113.195 0.529297 79.1374Z"
                                            fill="#EEEEEF"
                                        />
                                        <mask
                                            id="mask0_1157_884"
                                            style="mask-type: alpha"
                                            maskUnits="userSpaceOnUse"
                                            x="0"
                                            y="0"
                                            width="194"
                                            height="141"
                                        >
                                            <rect
                                                x="0.529297"
                                                y="0.804199"
                                                width="193.2"
                                                height="140"
                                                rx="70"
                                                fill="#FFB21D"
                                            />
                                        </mask>
                                        <g mask="url(#mask0_1157_884)">
                                            <path
                                                d="M43.8008 164.729L44.6326 149.469C36.7501 138.951 34.3431 108.644 34.1047 94.2544L42.969 75.1098L60.9913 67.6185C70.6032 65.8612 92.4887 62.5132 103.136 63.1791C113.783 63.845 122.175 70.8555 125.04 74.2775C130.493 80.289 144.005 93.2001 154.43 96.7515C167.462 101.191 160.253 120.89 159.421 123.387C158.755 125.385 142.692 138.463 134.744 144.752L82.3408 160.289L78.4591 168.336L80.9545 169.445C89.6052 172.775 96.5738 171.943 98.9768 171.11L100.918 181.099C92.1375 179.156 72.4147 176.049 63.7639 179.156C55.1132 182.264 49.8082 191.365 48.237 195.527L46.2962 195.804C43.4311 192.382 37.3127 184.151 35.76 178.602C34.2073 173.052 40.4736 167.041 43.8008 164.729Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M103.413 70.9481C104.744 68.7285 109.144 66.1389 111.177 65.1215C111.177 65.9539 113.288 67.1067 115.059 61.792C120.049 46.8093 119.772 32.9364 117.277 26.2774L110.622 21.2832L89.2728 29.052L56.2781 68.1736C59.4204 72.6129 66.2042 82.3794 68.2005 85.9309C70.1969 89.4823 69.7717 99.6188 69.3096 104.243L98.4226 103.411L100.363 87.5956L100.086 75.3875C100.641 74.8326 102.082 73.1678 103.413 70.9481Z"
                                                fill="#D4D4D4"
                                            />
                                            <path
                                                d="M100.095 75.7727C98.1471 76.7848 95.0557 77.2646 91.4908 74.8323C92.8771 75.8497 94.6516 78.3838 90.659 80.3815C85.6682 82.8786 73.4684 82.3237 80.9546 64.0115C82.7107 66.6011 86.6663 72.0022 88.4408 72.8901C85.853 70.9479 80.4001 64.0669 79.2911 52.0808C78.182 40.0946 76.9805 44.127 76.5184 47.6414L57.9353 70.5287C61.103 75.0539 65.7102 81.7582 67.7393 85.1401C75.5323 83.256 85.903 81.3038 90.659 82.3237C96.6442 83.6071 97.8503 96.4342 94.7856 103.514L98.4224 103.41L100.363 87.5954L100.095 75.7727Z"
                                                fill="#999999"
                                            />
                                            <path
                                                d="M96.4816 29.0519C108.681 25.0565 116.537 33.4912 118.94 38.208C120.049 29.5513 117.554 25.3524 116.168 24.3351L106.463 19.3408C98.0528 24.2426 84.2819 33.0472 96.4816 29.0519Z"
                                                fill="#BE693B"
                                            />
                                            <ellipse
                                                cx="2.9517"
                                                cy="0.793492"
                                                rx="2.9517"
                                                ry="0.793492"
                                                transform="matrix(0.932234 0.361856 -0.361422 0.932402 91.6047 56.3408)"
                                                fill="#B2B2B2"
                                            />
                                            <ellipse
                                                cx="2.95148"
                                                cy="0.793551"
                                                rx="2.95148"
                                                ry="0.793551"
                                                transform="matrix(0.988734 0.149684 -0.149482 0.988764 108.97 60.4644)"
                                                fill="#B2B2B2"
                                            />
                                            <path
                                                d="M105.632 51.5259C105.447 52.9132 105.354 56.1871 106.463 58.1849C107.85 60.682 108.959 64.289 101.75 62.6243"
                                                stroke="#232323"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M93.9861 65.1216L94.5149 65.1485C97.6157 65.3063 100.656 66.0698 103.463 67.3958L104.522 67.8962"
                                                stroke="#232323"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M96.2832 44.341C94.1419 44.7901 92.6048 45.5629 91.591 46.2902C91.0056 46.7102 91.4131 47.3685 92.1181 47.2206C95.8561 46.436 100.974 45.8987 104.055 45.6518C104.591 45.6088 104.939 45.0454 104.598 44.6296C103.182 42.9063 100.355 43.4869 96.2832 44.341Z"
                                                fill="#232323"
                                            />
                                            <path
                                                d="M84.8246 38.6181C86.5991 35.5106 97.4864 31.6817 104.788 30.2944C129.464 35.2886 108.747 70.2896 104.865 69.4572L111.165 71.0807C112.366 69.6934 114.991 66.1419 115.878 63.0344C116.988 59.15 118.374 52.2135 119.206 40.8378C120.037 29.462 119.76 28.0747 114.215 17.2539C109.779 8.59718 102.755 7.35788 99.797 7.82031C92.8653 4.21336 80.1111 8.37523 71.7931 26.9649C63.4751 45.5546 41.8483 71.0807 32.6985 90.5027C28.2147 100.02 23.3043 126.535 36.8505 130.102C36.1111 128.067 34.2269 115.428 34.6705 106.106C35.2251 94.4525 41.0165 81.0691 46.5618 75.52C52.1071 69.9708 64.8614 74.6876 70.684 70.2483C76.5066 65.809 82.6064 42.5025 84.8246 38.6181Z"
                                                fill="#232323"
                                                stroke="#232323"
                                                stroke-width="1.99448"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M114.625 46.139C116.694 46.8012 117.634 47.3795 117.992 47.7454C118.157 47.9136 118.075 48.146 117.896 48.2989L116.38 49.5944C116.077 49.8538 115.629 49.8462 115.329 49.5819C110.101 44.964 110.116 47.1385 109.722 46.0027C109.394 45.0576 111.082 45.0051 114.625 46.139Z"
                                                fill="#232323"
                                            />
                                            <ellipse
                                                cx="97.3133"
                                                cy="51.5258"
                                                rx="3.3272"
                                                ry="3.32949"
                                                fill="white"
                                            />
                                            <ellipse
                                                cx="97.5906"
                                                cy="52.3584"
                                                rx="2.4954"
                                                ry="2.49712"
                                                fill="#232323"
                                            />
                                            <ellipse
                                                cx="111.731"
                                                cy="53.1908"
                                                rx="3.3272"
                                                ry="3.32949"
                                                fill="white"
                                            />
                                            <ellipse
                                                cx="112.008"
                                                cy="54.023"
                                                rx="2.4954"
                                                ry="2.49712"
                                                fill="#232323"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M157.816 79.4243C158.457 76.3383 156.109 73.4394 152.958 73.4244L103.117 73.188C100.647 73.1763 98.5404 74.975 98.1651 77.4165L95.8451 92.507C95.3845 95.5025 92.3802 97.3978 89.4784 96.5235L71.9192 91.2332C70.5353 90.8163 69.0386 91.0203 67.8169 91.7925L60.6555 96.3186C57.1783 98.5163 57.7077 103.742 61.5551 105.197L73.2368 109.616C74.9589 110.268 76.1818 111.815 76.4176 113.642L81.8505 155.717C82.1865 158.318 84.4807 160.215 87.099 160.055L137.222 157C137.717 156.97 138.206 156.865 138.67 156.691L157.207 149.735C159.362 148.926 160.684 146.747 160.404 144.462L154.54 96.4952C154.474 95.9555 154.497 95.4087 154.608 94.8763L157.816 79.4243Z"
                                                fill="#D8D8D8"
                                            />
                                            <path
                                                d="M139.225 108.639C138.826 105.469 136.097 103.114 132.904 103.186L122.815 103.41C118.888 103.498 116.019 107.152 116.866 110.988L120.663 128.181C121.452 131.756 126.407 132.115 127.704 128.691C128.792 125.822 132.704 125.46 134.297 128.081L134.501 128.416C136.595 131.862 141.909 130.007 141.406 126.005L139.225 108.639Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M127.656 109.871C128.02 108.37 130.093 108.211 130.681 109.64L130.994 110.401C131.257 111.04 131.901 111.437 132.59 111.384L133.412 111.321C134.952 111.204 135.743 113.125 134.567 114.126L133.937 114.662C133.411 115.109 133.232 115.844 133.495 116.483L133.809 117.247C134.397 118.676 132.813 120.022 131.498 119.211L130.798 118.78C130.21 118.417 129.455 118.475 128.928 118.923L128.302 119.455C127.125 120.456 125.355 119.367 125.719 117.866L125.914 117.063C126.076 116.392 125.789 115.692 125.201 115.33L124.497 114.896C123.182 114.085 123.672 112.066 125.212 111.948L126.034 111.886C126.723 111.833 127.3 111.343 127.463 110.671L127.656 109.871Z"
                                                fill="#D8D8D8"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M71.9192 91.2335C70.5353 90.8165 69.0386 91.0206 67.8169 91.7928L60.6555 96.3189C57.1783 98.5165 57.7077 103.742 61.5551 105.197L73.2368 109.617C74.9589 110.268 76.1818 111.816 76.4176 113.642L82.2761 159.013C82.3725 159.759 83.0307 160.303 83.782 160.257C83.9139 160.249 84.044 160.223 84.1687 160.179L98.3043 155.218C100.486 154.452 101.855 152.288 101.611 149.988L96.5143 101.832C96.3031 99.8366 94.9158 98.162 92.9942 97.583L71.9192 91.2335Z"
                                                fill="#B2B2B2"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M71.9192 91.2335C70.5353 90.8165 69.0386 91.0206 67.8169 91.7928L60.6555 96.3189C57.1783 98.5165 57.7077 103.742 61.5551 105.197L75.7711 110.576C75.9333 110.637 76.0484 110.783 76.0706 110.955C76.1137 111.288 76.483 111.469 76.7732 111.3L95.7395 100.211C96.0896 100.006 96.2862 99.6141 96.241 99.211C96.1953 98.8031 95.9108 98.4617 95.5177 98.3433L71.9192 91.2335Z"
                                                fill="#D8D8D8"
                                            />
                                            <path
                                                d="M124.711 91.0941C133.576 84.986 115.884 97.7578 124.61 85.3875C145.538 55.7196 146.41 -21.5041 77.0871 10.3453"
                                                stroke="#D9D9D9"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-dasharray="9.97 9.97"
                                            />
                                            <path
                                                d="M157.816 79.4246C158.457 76.3386 156.109 73.4397 152.957 73.4247L103.117 73.1884C100.647 73.1766 98.5402 74.9753 98.1648 77.4168L95.0364 97.7656C95.0028 97.9841 94.7837 98.1223 94.5721 98.0585C94.2856 97.9722 94.0233 98.2494 94.1252 98.5306L94.5503 99.7035C94.6989 100.114 95.0982 100.378 95.5337 100.355L96.2844 100.315C96.2877 100.315 96.2911 100.315 96.2942 100.316L96.3186 100.323C96.3225 100.324 96.3266 100.322 96.3272 100.318C96.3277 100.315 96.3303 100.312 96.3335 100.312L153.298 97.2751C154.017 97.2367 154.553 96.5979 154.465 95.8836C154.449 95.7491 154.454 95.6128 154.482 95.4802L157.816 79.4246Z"
                                                fill="#B2B2B2"
                                            />
                                            <path
                                                d="M154.358 96.7656L161.334 147.812"
                                                stroke="#232323"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M89.5499 65.3989C90.5851 65.0536 92.4297 64.1263 91.5263 63.1793"
                                                stroke="#232323"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M79.5682 51.2485C79.1061 56.6127 80.9546 68.9503 92.0452 75.3874"
                                                stroke="#232323"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M49.72 81.0591L51.524 98.2089C52.6426 108.843 55.0242 119.307 58.6185 129.377L64.1077 144.758"
                                                stroke="#232323"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                            />
                                        </g>
                                        <path
                                            d="M72.4959 53.9812L64.1855 53.8993"
                                            stroke="white"
                                            stroke-width="1.90476"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M73.8648 50.1084L66.0681 47.1069"
                                            stroke="white"
                                            stroke-width="1.90476"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M75.6606 46.2186L69.8392 40.1638"
                                            stroke="white"
                                            stroke-width="1.90476"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M68.8154 91.5953C69.6438 90.8969 72.2117 89.8354 75.8564 91.1762C79.5012 92.5171 90.9048 97.0425 96.1511 99.1375C96.2891 91.1762 97.4764 75.0021 101.121 73.9965C104.766 72.9909 133.289 73.2981 147.095 73.5775"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <ellipse
                                            cx="3.74962"
                                            cy="1.66569"
                                            rx="3.74962"
                                            ry="1.66569"
                                            transform="matrix(-0.384834 -0.922986 0.922797 -0.385286 75.3018 20.4556)"
                                            fill="#D9D9D9"
                                        />
                                        <ellipse
                                            cx="3.74962"
                                            cy="1.66569"
                                            rx="3.74962"
                                            ry="1.66569"
                                            transform="matrix(-0.384834 -0.922986 0.922797 -0.385286 71.1331 10.4575)"
                                            fill="#D9D9D9"
                                        />
                                        <ellipse
                                            cx="2.49975"
                                            cy="3.7478"
                                            rx="2.49975"
                                            ry="3.7478"
                                            transform="matrix(-0.384834 -0.922986 0.922797 -0.385286 70.4309 15.2651)"
                                            fill="#232323"
                                        />
                                    </svg>

                                    <p
                                        style="
                      color: #2d3142;
                      font-size: 1.125rem;
                      font-style: normal;
                      font-weight: 600;
                      margin: 0;
                      padding: 0;
                    "
                                    >
                                        No data found
                                    </p>
                                    <p
                                        style="
                      color: #4f5d75;
                      font-size: 0.875rem;
                      font-style: normal;
                      font-weight: 400;
                      margin: 0;
                      padding: 0;
                      text-align: center;
                    "
                                    >
                                        There are currently no earnings recorded on the system at
                                        the moment
                                    </p>
                                </div>
                                <div
                                    :key="referral?.refferredUser?.id"
                                    v-else
                                    v-for="referral in Referrals"
                                >
                                    <div
                                        style="
                      border-radius: 0.3125rem;
                      border: 1px solid #eaebf0;
                      background: #fff;
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      align-items: flex-start;
                    "
                                    >
                                        <div
                                            style="
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        padding: 1rem;
                      "
                                        >
                                            <p
                                                style="
                          color: #2d3142;
                          font-size: 0.875rem;
                          font-style: normal;
                          font-weight: 500;
                          margin: 0;
                          padding: 0;
                        "
                                            >
                                                {{
                                                    referral.refferredUser?.name
                                                }}
                                            </p>
                                            <p
                                                style="
                          margin: 0;
                          padding: 0;
                          color: #4f5d75;
                          font-size: 0.75rem;
                          font-style: normal;
                          font-weight: 400;
                        "
                                            >
                                                {{
                                                    referral.refferredUser?.formattedphone
                                                }}
                                            </p>
                                        </div>
                                        <div
                                            style="
                        width: 100%;
                        align-items: flex-start;
                        justify-content: center;
                        gap: 1rem;
                        flex-wrap: wrap;
                        padding: 0 1rem;
                        display: grid;
                        grid-template-columns: repeat(
                          auto-fit,
                          minmax(200px, 1fr)
                        );
                      "
                                        >
                                            <div
                                                style="
                          display: flex;
                          align-items: flex-start;
                          justify-content: center;
                          flex-direction: column;
                          flex: 1;
                          min-width: 200px;
                        "
                                            >
                                                <p
                                                    style="
                            color: #2d3142;
                            font-size: 0.875rem;
                            font-style: normal;
                            margin: 0;
                            padding: 0;
                          "
                                                >
                                                    {{
                                                        referral?.market?.name
                                                    }}
                                                </p>
                                                <p
                                                    style="
                            margin: 0;
                            padding: 0;
                            color: #7c8b9d;
                            font-size: 0.75rem;
                            font-style: normal;
                            font-weight: 400;
                          "
                                                >
                                                    Market
                                                </p>
                                            </div>

                                            <div
                                                style="
                          display: flex;
                          flex: 1;
                          align-items: flex-start;
                          justify-content: center;
                          flex-direction: column;
                        "
                                            >
                                                <p
                                                    style="
                            color: #2d3142;
                            font-size: 0.875rem;
                            font-style: normal;
                            margin: 0;
                            padding: 0;
                          "
                                                >
                                                    {{
                                                        formatDate(
                                                            referral.pivot
                                                                .created_at
                                                        )
                                                    }}
                                                </p>
                                                <p
                                                    style="
                            margin: 0;
                            padding: 0;
                            font-size: 0.75rem;
                            color: #7c8b9d;
                            font-style: normal;
                          "
                                                >
                                                    Referral Date
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style="
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        padding: 1rem;
                      "
                                        >
                                            <p
                                                style="
                          color: #2d3142;
                          font-size: 0.875rem;
                          font-style: normal;
                          margin: 0;
                          padding: 0;
                          display: flex;
                          align-items: center;
                          justify-content: flex-start;
                          gap: 0.2rem;
                        "
                                            >
                                                <svg
                                                    width="13"
                                                    height="13"
                                                    viewBox="0 0 13 13"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M5.83152 0.274606C6.20327 -0.0915352 6.79673 -0.0915353 7.16848 0.274606L7.71985 0.817652C7.94704 1.04142 8.26948 1.13692 8.58053 1.07258L9.33542 0.916443C9.84439 0.811168 10.3436 1.13483 10.4601 1.64559L10.6329 2.40312C10.7041 2.71527 10.9242 2.97147 11.2204 3.08698L11.9391 3.36732C12.4237 3.55633 12.6702 4.10089 12.4945 4.59411L12.2338 5.32562C12.1264 5.62705 12.1743 5.96259 12.3615 6.22129L12.8159 6.8491C13.1223 7.27239 13.0378 7.86495 12.6256 8.18403L12.0143 8.65728C11.7624 8.85228 11.6228 9.16064 11.6417 9.48038L11.6874 10.2563C11.7183 10.7795 11.3297 11.232 10.8119 11.2756L10.044 11.3403C9.72758 11.367 9.44487 11.5503 9.28938 11.8295L8.91201 12.5073C8.65758 12.9642 8.08816 13.1329 7.62921 12.8872L6.94851 12.5229C6.66803 12.3727 6.33198 12.3727 6.05149 12.5229L5.37079 12.8872C4.91184 13.1329 4.34242 12.9642 4.08799 12.5073L3.71062 11.8295C3.55513 11.5503 3.27242 11.367 2.956 11.3403L2.18808 11.2756C1.67033 11.232 1.28169 10.7795 1.31256 10.2563L1.35834 9.48038C1.3772 9.16064 1.2376 8.85228 0.985704 8.65728L0.37438 8.18403C-0.0377956 7.86495 -0.122254 7.27239 0.184111 6.8491L0.638499 6.22129C0.825734 5.96259 0.873559 5.62705 0.766159 5.32562L0.505518 4.59411C0.329784 4.10089 0.576317 3.55633 1.06091 3.36732L1.77964 3.08698C2.0758 2.97146 2.29587 2.71527 2.36707 2.40312L2.53986 1.64558C2.65636 1.13483 3.15562 0.811168 3.66458 0.916443L4.41947 1.07258C4.73052 1.13692 5.05296 1.04142 5.28016 0.817652L5.83152 0.274606Z"
                                                        fill="#41AE49"
                                                    />
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M9.28147 4.90035C9.51224 5.13111 9.51224 5.50525 9.28147 5.73602L6.24039 8.7771C5.89424 9.12325 5.33303 9.12325 4.98688 8.7771L3.71853 7.50875C3.48776 7.27798 3.48776 6.90384 3.71853 6.67307C3.94929 6.44231 4.32344 6.44231 4.5542 6.67307L5.61364 7.73251L8.4458 4.90035C8.67657 4.66958 9.05071 4.66958 9.28147 4.90035Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                                {{ referral.pivot.status }}
                                            </p>
                                            <p
                                                style="
                          margin: 0;
                          padding: 0;
                          font-size: 0.75rem;
                          color: #7c8b9d;
                          font-style: normal;
                        "
                                            >
                                                Status
                                            </p>
                                        </div>

                                        <div
                                            style="
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        padding: 1rem;
                        background: #f7f7f8;
                      "
                                        >
                                            <p
                                                style="
                          color: #2d3142;
                          font-size: 0.875rem;
                          font-style: normal;
                          font-weight: 600;
                          margin: 0;
                          padding: 0;
                        "
                                            >
                                                {{ currency }}{{
                                                    referral.pivot.amount || 0
                                                }}
                                            </p>
                                            <p
                                                style="
                          color: #7c8b9d;
                          font-size: 0.75rem;
                          font-style: normal;
                          font-weight: 400;
                          margin: 0;
                          padding: 0;
                        "
                                            >
                                                Amount Earned
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="cashout"
                            role="tabpanel"
                            aria-labelledby="cashout-tab"
                        >
                            <div
                                style=" display: flex; flex-direction: column; justify-content: flex-start; align-items: center; padding: 1rem 0; margin: 0 auto; width: 100%; max-width: 21rem; gap: 0.8rem;"
                            >
                                <svg
                                    width="194"
                                    height="141"
                                    viewBox="0 0 194 141"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M193.413 78.3337C193.413 44.2761 165.804 16.667 131.746 16.667H61.7464C27.6888 16.667 0.0797729 44.2761 0.0797729 78.3337C0.0797729 112.391 27.6889 140 61.7464 140H131.746C165.804 140 193.413 112.391 193.413 78.3337Z"
                                        fill="#EEEEEF"
                                    />
                                    <mask
                                        id="mask0_974_132851"
                                        style="mask-type: alpha"
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="194"
                                        height="140"
                                    >
                                        <path
                                            d="M193.413 69.12C193.413 30.9461 162.467 0 124.293 0H69.1997C31.0258 0 0.0797729 30.9461 0.0797729 69.12V70.88C0.0797729 109.054 31.0258 140 69.1998 140H124.293C162.467 140 193.413 109.054 193.413 70.88V69.12Z"
                                            fill="#23BA3B"
                                        />
                                    </mask>
                                    <g mask="url(#mask0_974_132851)">
                                        <path
                                            d="M50.2481 104.224L74.1686 95.2985C75.421 94.8312 76.72 94.5002 78.0433 94.3111L106.84 90.1974C110.188 89.719 113.603 90.1621 116.719 91.4789L154.57 107.478C159.14 109.41 162.75 113.086 164.598 117.69L183.121 163.847C187.787 175.473 179.01 188.072 166.488 187.724L157.738 187.481C156.71 187.452 155.781 186.86 155.322 185.941L154.961 185.221C153.809 182.916 150.358 183.567 150.125 186.133C150.005 187.451 148.9 188.46 147.577 188.46H42.1599C29.8227 188.46 20.8992 176.676 24.2443 164.801L27.838 152.043L38.9785 116.146C40.6867 110.641 44.8484 106.239 50.2481 104.224Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M132.146 97.9997L111.957 89.4663L76.0523 94.5955L65.8286 98.4103C79.1996 113.399 103.48 125.659 132.146 97.9997Z"
                                            fill="#AEAEAE"
                                        />
                                        <path
                                            d="M80.1557 91.0051C80.9763 89.3638 79.8137 82.7984 79.1298 79.7209C73.3851 73.9761 69.2133 59.8879 67.8456 53.5619L63.7422 35.6096L74.0006 22.2737L94.5175 21.7607C100.843 27.9158 114.419 40.6362 118.112 42.2776C122.728 44.3293 122.728 49.9714 121.189 55.6135C119.958 60.1273 116.573 61.9396 115.034 62.2815L112.983 70.4883C113.496 76.3014 114.727 88.2353 115.547 89.4664C116.368 90.6974 121.702 93.7407 124.267 95.1085C100.878 117.267 80.3266 104.683 72.9748 95.6214C75.0265 94.7665 79.335 92.6465 80.1557 91.0051Z"
                                            fill="#D4D4D4"
                                        />
                                        <path
                                            d="M112.988 70.5459C113.504 76.3799 114.729 88.2392 115.547 89.4661C116.368 90.6971 121.702 93.7405 124.267 95.1083C121.578 97.6555 118.927 99.7437 116.329 101.431C103.181 96.4536 87.6844 89.6262 79.8206 83.2782C79.6963 82.5422 79.5645 81.8315 79.4365 81.1851C84.6079 83.6885 92.4089 85.1992 99.6467 83.3111C105.992 81.6559 110.065 75.967 112.988 70.5459Z"
                                            fill="#999999"
                                        />
                                        <path
                                            d="M116.06 48.9453C117.428 49.8002 119.651 52.1254 117.599 54.5874"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M67.333 49.9712C69.7266 61.2555 75.0268 83.6188 89.3886 84.8498"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M68.8717 50.4842V56.6392C60.665 42.2774 60.665 34.9256 61.6909 33.0449L60.665 31.5061C68.4614 6.88587 90.5854 9.2795 100.673 13.5538C115.035 13.9642 118.967 33.2158 119.138 42.7904C115.445 41.149 113.496 45.8679 112.983 48.4325C112.573 43.5084 104.947 34.0707 101.186 29.9673L90.9274 26.3769C84.362 35.8146 73.83 37.4902 69.3847 37.1482C65.6916 39.6102 67.5039 47.0647 68.8717 50.4842Z"
                                            fill="#232323"
                                        />
                                        <path
                                            d="M83.7466 49.458C84.0886 53.0485 84.5673 60.6397 83.7466 62.281C82.7208 64.3327 86.3112 65.3586 89.3888 64.3327"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M72.0889 43.5746C76.7813 40.4389 78.8443 42.012 79.5843 43.1384C79.8521 43.5459 79.6037 44.0191 79.1487 44.1944L77.5627 44.8055C74.5982 45.9478 71.8764 47.6401 69.5404 49.7932C69.2372 50.0727 68.7541 50.0651 68.5532 49.705C67.8653 48.4723 67.9678 46.3286 72.0889 43.5746Z"
                                            fill="#232323"
                                        />
                                        <path
                                            d="M92.9883 38.0806C96.2234 38.0404 98.7105 39.034 100.538 40.2062C101.664 40.9282 100.797 42.4582 99.4753 42.2553C92.3746 41.1657 87.259 42.6606 86.499 42.0846C85.585 41.3917 85.1427 38.178 92.9883 38.0806Z"
                                            fill="#232323"
                                        />
                                        <mask
                                            id="mask1_974_132851"
                                            style="mask-type: alpha"
                                            maskUnits="userSpaceOnUse"
                                            x="69"
                                            y="49"
                                            width="16"
                                            height="10"
                                        >
                                            <path
                                                d="M81.1817 49.458C74.2168 49.458 70.554 52.4599 69.2424 54.441C68.9828 54.8332 69.0377 55.3495 69.3351 55.7138C76.668 64.696 90.722 49.458 81.1817 49.458Z"
                                                fill="black"
                                            />
                                        </mask>
                                        <g mask="url(#mask1_974_132851)">
                                            <circle
                                                cx="76.3097"
                                                cy="52.7936"
                                                r="4.61629"
                                                transform="rotate(-19.6555 76.3097 52.7936)"
                                                fill="white"
                                            />
                                            <circle
                                                cx="75.539"
                                                cy="54.0741"
                                                r="3.07753"
                                                fill="#232323"
                                            />
                                        </g>
                                        <mask
                                            id="mask2_974_132851"
                                            style="mask-type: alpha"
                                            maskUnits="userSpaceOnUse"
                                            x="88"
                                            y="44"
                                            width="16"
                                            height="10"
                                        >
                                            <path
                                                d="M100.16 44.8906C93.1948 44.8906 89.532 47.8925 88.2204 49.8737C87.9608 50.2658 88.0158 50.7821 88.3132 51.1464C95.646 60.1286 109.7 44.8906 100.16 44.8906Z"
                                                fill="black"
                                            />
                                        </mask>
                                        <g mask="url(#mask2_974_132851)">
                                            <circle
                                                cx="95.2882"
                                                cy="48.2262"
                                                r="4.61629"
                                                transform="rotate(-19.6555 95.2882 48.2262)"
                                                fill="white"
                                            />
                                            <circle
                                                cx="94.5175"
                                                cy="49.5067"
                                                r="3.07753"
                                                fill="#232323"
                                            />
                                        </g>
                                        <path
                                            d="M125.137 32.9641L131.242 23.856"
                                            stroke="white"
                                            stroke-width="2.25969"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M128.364 37.3096L137.278 30.9059"
                                            stroke="white"
                                            stroke-width="2.25969"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M131.302 42.1389L142.112 40.15"
                                            stroke="white"
                                            stroke-width="2.25969"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M70.4102 52.5358C71.9489 51.3389 76.2575 49.0479 81.1815 49.4582"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M89.3887 47.9684C90.9274 46.7716 95.236 44.4805 100.16 44.8908"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <ellipse
                                            cx="97.9275"
                                            cy="58.9124"
                                            rx="4.66429"
                                            ry="1.87308"
                                            transform="rotate(-11.7826 97.9275 58.9124)"
                                            fill="#B2B2B2"
                                        />
                                        <ellipse
                                            cx="77.4104"
                                            cy="63.0159"
                                            rx="4.66429"
                                            ry="1.87308"
                                            transform="rotate(-11.7826 77.4104 63.0159)"
                                            fill="#B2B2B2"
                                        />
                                        <ellipse
                                            cx="94.6311"
                                            cy="72.0214"
                                            rx="5.55641"
                                            ry="1.72721"
                                            transform="rotate(-8.82443 94.6311 72.0214)"
                                            fill="#232323"
                                        />
                                        <path
                                            d="M59.509 138.827L45.8316 110.295C45.4544 109.508 44.6985 108.971 43.8313 108.873L31.7933 107.516C30.2788 107.345 28.9576 108.541 28.977 110.065L29.4747 149.199C29.4969 150.946 31.239 152.146 32.8792 151.545L58.0969 142.3C59.4999 141.785 60.155 140.174 59.509 138.827Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M30.7152 68.0347C30.5195 69.7717 36.974 77.5356 40.2257 81.2004L41.9275 82.8041C42.7915 83.6183 44.105 83.7231 45.0872 83.0564L66.3002 68.6559C67.462 67.8671 67.7604 66.2837 66.9654 65.1262L57.5332 51.394C56.753 50.2582 55.2127 49.9509 54.0642 50.7123C45.2592 56.5505 30.896 66.4307 30.7152 68.0347Z"
                                            fill="#B2B2B2"
                                        />
                                        <path
                                            d="M30.7911 81.0168C20.1852 86.1542 20.3573 92.3011 21.7691 94.7324C19.3714 97.2768 22.034 100.186 23.665 101.323C25.3468 105.91 32.0133 116.996 37.3957 116.064C41.7017 115.318 45.2757 112.481 46.5245 111.156L44.965 105.483C48.0846 98.0942 45.674 89.4374 44.0788 86.0326C45.3276 84.7074 47.7732 81.3476 47.5653 78.5096C47.3055 74.9621 44.0485 74.595 30.7911 81.0168Z"
                                            fill="#D4D4D4"
                                        />
                                        <path
                                            d="M42.1833 79.4421C41.6451 77.9742 44.0693 77.016 45.3486 76.7204C46.1845 77.1078 47.7064 78.0417 47.107 78.6777C46.3577 79.4728 42.856 81.277 42.1833 79.4421Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M41.3575 82.8674L39.7214 79.8244"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M46.6943 80.3906C42.3313 82.6841 32.7121 87.3903 26.1431 91.0477"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M33.8946 102.256C36.3258 100.844 41.6074 98.5955 43.2832 100.895C45.378 103.77 43.8794 105.36 39.919 107.333"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M31.9987 95.6651C33.956 93.9067 39.8828 90.4848 42.4272 92.8825C44.9715 95.2802 44.4759 99.4168 43.9101 101.185"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M37.1064 85.4667C39.4866 85.8082 44.3847 88.0021 42.4273 92.8829"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <rect
                                            width="25.4067"
                                            height="3.32875"
                                            rx="1.66437"
                                            transform="matrix(-0.823235 0.5677 0.5677 0.823235 54.0967 53.7378)"
                                            fill="white"
                                        />
                                        <rect
                                            width="25.4067"
                                            height="2.27149"
                                            rx="1.13575"
                                            transform="matrix(-0.823235 0.5677 0.5677 0.823235 56.4961 57.0215)"
                                            fill="white"
                                        />
                                        <path
                                            d="M45.9136 110.833C50.0802 123.333 60.9969 149.417 71.3302 153.75"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M153.958 140.007L159.45 108.847C159.601 107.987 160.185 107.266 160.994 106.939L172.222 102.392C173.635 101.82 175.229 102.616 175.621 104.089L185.675 141.912C186.124 143.6 184.769 145.225 183.028 145.088L156.252 142.972C154.763 142.854 153.698 141.479 153.958 140.007Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M159.16 111.65C157.464 124.671 151.505 152.986 141.241 162.085"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M162.633 64.0775C163.289 65.6977 159.163 74.9125 157.017 79.3173L155.81 81.3199C155.197 82.3366 153.96 82.7911 152.835 82.4134L128.529 74.2545C127.197 73.8076 126.484 72.363 126.938 71.0342L132.325 55.2699C132.771 53.966 134.172 53.2554 135.483 53.6796C145.534 56.9321 162.027 62.5814 162.633 64.0775Z"
                                            fill="#B2B2B2"
                                        />
                                        <path
                                            d="M166.054 76.6009C177.652 78.6937 179.14 84.6601 178.435 87.3817C181.429 89.1867 179.648 92.7054 178.383 94.2392C177.998 99.1099 174.562 111.581 169.127 112.132C164.779 112.573 160.574 110.802 159.014 109.862L158.989 103.979C153.996 97.703 153.987 88.7169 154.607 85.0083C153.048 84.0682 149.788 81.4907 149.224 78.7015C148.52 75.215 151.558 73.9848 166.054 76.6009Z"
                                            fill="#D4D4D4"
                                        />
                                        <path
                                            d="M154.659 78.1507C154.782 76.5921 152.19 76.3218 150.878 76.3815C150.177 76.9796 148.963 78.2886 149.711 78.7399C150.647 79.3039 154.505 80.099 154.659 78.1507Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M156.377 81.2269L157.133 77.8559"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M150.569 80.2783C155.389 81.3127 165.92 83.256 173.231 85.0101"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M168.783 97.8914C166.061 97.1861 160.369 96.4422 159.374 99.1082C158.131 102.441 160.002 103.569 164.347 104.402"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M168.835 91.0341C166.476 89.8674 159.847 88.1672 158.042 91.1613C156.237 94.1554 157.828 98.006 158.849 99.557"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <path
                                            d="M161.17 82.586C158.97 83.5557 154.843 86.9871 158.042 91.1608"
                                            stroke="#232323"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                        <rect
                                            x="136.266"
                                            y="56.6021"
                                            width="25.4067"
                                            height="3.32875"
                                            rx="1.66437"
                                            transform="rotate(18.9745 136.266 56.6021)"
                                            fill="white"
                                        />
                                        <rect
                                            x="134.84"
                                            y="60.4106"
                                            width="25.4067"
                                            height="2.27149"
                                            rx="1.13575"
                                            transform="rotate(18.9745 134.84 60.4106)"
                                            fill="white"
                                        />
                                    </g>
                                </svg>
                                <p
                                    style="
                    color: #2d3142;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 600;
                    margin: 0;
                    padding: 0;
                  "
                                >
                                    No data found
                                </p>
                                <p
                                    style="
                    color: #4f5d75;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                  "
                                >
                                    There are currently no cash-out recorded on the system at the
                                    moment.
                                </p>
                            </div>

                            <!--                            <div-->
                            <!--                                style="padding: 1.5rem 1rem; width: 100%; overflow-x: auto"-->
                            <!--                                id="new-table"-->
                            <!--                            >-->
                            <!--                                <table style="width: 100%; font-size: 0.75rem">-->
                            <!--                                    <tr>-->
                            <!--                                        <th>Withdrawal Amount</th>-->
                            <!--                                        <th>Withdrawal Date</th>-->
                            <!--                                    </tr>-->
                            <!--                                    <tr>-->
                            <!--                                        <td></td>-->
                            <!--                                        <td></td>-->
                            <!--                                    </tr>-->
                            <!--                                </table>-->
                            <!--                                <nav id="new-table" aria-label="Page navigation example">-->
                            <!--                                    <ul class="pagination justify-content-end">-->
                            <!--                                        <li class="page-item disabled">-->
                            <!--                                            <a-->
                            <!--                                                class="page-link"-->
                            <!--                                                href="#"-->
                            <!--                                                tabindex="-1"-->
                            <!--                                                style="font-size: 0.75rem"-->
                            <!--                                            >Prev</a-->
                            <!--                                            >-->
                            <!--                                        </li>-->
                            <!--                                        <li class="page-item active">-->
                            <!--                                            <a style="font-size: 0.75rem" class="page-link" href="#"-->
                            <!--                                            >1</a-->
                            <!--                                            >-->
                            <!--                                        </li>-->
                            <!--                                        <li class="page-item">-->
                            <!--                                            <a style="font-size: 0.75rem" class="page-link" href="#"-->
                            <!--                                            >Next</a-->
                            <!--                                            >-->
                            <!--                                        </li>-->
                            <!--                                    </ul>-->
                            <!--                                </nav>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="things"
                role="tabpanel"
                aria-labelledby="things-tab"
            >
                <div class="row">
                    <div class="card" style="border-radius: 1rem">
                        <div class="card-header mt-4">
                            <h6><strong>Let's get you started!</strong></h6>
                        </div>
                        <div class="card-body">
                            <div class="row gap-2">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex">
                                        <div>
                      <span
                          class="fa-stack fa-2x"
                          style="vertical-align: center; color: #d1d5db"
                      >
                        <i class="fa-solid fa-square fa-stack-2x"></i>
                        <i
                            class="fa-solid fa-certificate fa-stack-1x"
                            style="color: #64748b"
                        ></i>
                      </span>
                                        </div>
                                        <div style="margin-left: 1rem">
                                            <h6 class="mb-0">Add A product</h6>
                                            <small class="text-muted"
                                            >Add a product so that other buyers can buy from you.</small
                                            >
                                        </div>
                                    </div>
                                    <div style="margin-left: 1rem">
                                        <i
                                            v-if="onboarding.add_product"
                                            class="fa fa-solid fa-shield-check fa-3x"
                                            style="color: green"
                                        ></i>
                                        <i
                                            v-else
                                            class="fa fa-solid fa-shield-xmark fa-3x"
                                            style="color: red"
                                        ></i>
                                    </div>
                                </div>
                                <hr style="color: slategrey"/>
                            </div>
                            <div class="row gap-2">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex">
                                        <div>
                      <span
                          class="fa-stack fa-2x"
                          style="vertical-align: center; color: #d1d5db"
                      >
                        <i class="fa-solid fa-square fa-stack-2x"></i>
                        <i
                            class="fa-solid fa-certificate fa-stack-1x"
                            style="color: #64748b"
                        ></i>
                      </span>
                                        </div>
                                        <div style="margin-left: 1rem">
                                            <h6 class="mb-0">Make an Order</h6>
                                            <small class="text-muted"
                                            >Make an order from any of the buyers in our many markets.</small
                                            >
                                        </div>
                                    </div>
                                    <div style="margin-left: 1rem">
                                        <i
                                            v-if="onboarding.make_an_order"
                                            class="fa fa-solid fa-shield-check fa-3x"
                                            style="color: green"
                                        ></i>
                                        <i
                                            v-else
                                            class="fa fa-solid fa-shield-xmark fa-3x"
                                            style="color: red"
                                        ></i>
                                    </div>
                                </div>
                                <hr style="color: slategrey"/>
                            </div>
                            <div class="row gap-2">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex">
                                        <div>
                      <span
                          class="fa-stack fa-2x"
                          style="vertical-align: center; color: #d1d5db"
                      >
                        <i class="fa-solid fa-square fa-stack-2x"></i>
                        <i
                            class="fa-solid fa-certificate fa-stack-1x"
                            style="color: #64748b"
                        ></i>
                      </span>
                                        </div>
                                        <div style="margin-left: 1rem">
                                            <h6 class="mb-0">Top up your wallet</h6>
                                            <small class="text-muted"
                                            >Top up your wallet so that you are able to settle fees and charges.</small
                                            >
                                        </div>
                                    </div>
                                    <div style="margin-left: 1rem">
                                        <i
                                            v-if="onboarding.top_up_wallet"
                                            class="fa fa-solid fa-shield-check fa-3x"
                                            style="color: green"
                                        ></i>
                                        <i
                                            v-else
                                            class="fa fa-solid fa-shield-xmark fa-3x"
                                            style="color: red"
                                        ></i>
                                    </div>
                                </div>
                                <hr style="color: slategrey"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="website"
                role="tabpanel"
                aria-labelledby="website-tab"
            >
                <div class="row">
                    <WebsiteInformation
                        :market="props.market"
                        :showUpgrade="showUpgrade"
                    ></WebsiteInformation>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="referralReadMore"
        tabindex="-1"
        role="dialog"
        aria-labelledby="referralReadMore"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div
                    style="
            width: 100%;
            padding: 1.25rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1;
            color: #151b28;
          "
                >
                    <div
                        style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              width: 100%;
            "
                    >
                        <h4 style="font-size: 1.125rem; padding: 0; margin: 0">
                            What is the “Referral Program”?
                        </h4>
                    </div>
                    <p style="padding: 0; margin: 0.5rem 0; font-size: 0.875rem">
                        The referral program is a program that enables you to earn money by
                        making referrals (inviting) friends and other markets to the
                        platform
                    </p>
                </div>

                <div
                    style="
            width: 100%;
            padding: 0 1.25rem 1.25rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1;
          "
                >
                    <div
                        style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              width: 100%;
            "
                    >
                        <h4 style="font-size: 1.125rem; padding: 0; margin: 0">
                            Benefits of the “Referral Program”
                        </h4>
                    </div>
                    <p style="padding: 0; margin: 0.5rem 0">
                        When you refer people to use market services with your referral
                        code, you get:
                    </p>
                    <ol style="margin: 0">
                        <li style="color: #4f5d75; font-size: 14px">
                            {{ currency }}100 for each user who signs up and gets verified on Amana Market
                        </li>
                        <li style="color: #4f5d75; font-size: 14px">
                            {{ currency }}4,000 per each subscription a market leader pays for their market
                            on Amana Market
                        </li>
                    </ol>
                </div>

                <div
                    style="
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 1.25rem;
          "
                >
                    <button
                        style="
              border-radius: 0.375rem;
              border: 1px solid #eaebf0;
              padding: 0.75rem 1.12rem;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
              color: #2d3142;
              font-size: 0.9375rem;
              font-style: normal;
              font-weight: 500;
            "
                        @click.prevent="hideReadMore"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
