<script setup>
import Stepper from 'bs-stepper'
import {onMounted, ref} from "vue";
import SendOTPForPayment from "../payments/sendOTPForPayment.vue";
import InlineTopUp from "../payments/inlinetopup/InlineTopUp.vue";

let stepper;
const errors = ref([]);
const otp = ref('');
const saving = ref(false);
const currentIndex = ref(0);
const keyinlinetopup = ref(0);
const topup = ref(false);

const props = defineProps([
  'user_id',
  'phone',
  'balance',
  'order_amount',
  'order_id',
  'order_type'
]);

onMounted(function () {
  document.addEventListener('DOMContentLoaded', function () {
    stepper = new Stepper(document.querySelector('.bs-stepper'))
  });

});

function updateTopUpAMount() {
  // let myModalEl = document.querySelector('#payfororder')
  // let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
  // modal.hide();

  topup.value = true

}

defineExpose({
  walletToppedUp,
  sendOTP
});

function walletToppedUp() {
  completePayments();
}

function sendOTP() {
  axios.post('/apis/v4/send_otp', {
    'phone': props.phone,
    'user_id': props.user_id,
    message:
        'Your one-time password to complete payment on Amana Market is {otp} and is valid for the next 2 minutes.\n',
    'key': 'pay_for_order',
  })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
}

function nextPage() {
  axios.post('/apis/v4/validate_send_otp', {
    otp: otp.value,
    code: otp.value,
    phone: props.phone,
    user_id: props.user_id,
    message:
        'Your one-time password to complete payment on Amana Market is {otp} and is valid for the next 2 minutes.\n',
    key: `pay_for_order:${props.user_id}`,
  }).then(() => {
    currentIndex.value++;
    stepper.next();
  }).catch((error) => {
    errors.value = error.response.data.errors;
  });
}

function completePayments() {
  saving.value = true;
  axios.post('/apis/v4/completePayment', {
    'user_id': props.user_id,
    'order_id': props.order_id,
    'type': props.order_type,
    'amount': props.order_amount,
  }).then(() => {
    saving.value = false;
    let myModalEl = document.querySelector('#payfororder')
    let modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
    modal.hide();
    location.reload();
  }).catch(() => {
    saving.value = false;

  });
}
</script>

<template>
  <button class="btn btn-secondary btn-sm radius-30 px-4" data-bs-toggle="modal"
          @click="sendOTP"
          data-bs-target="#payfororder">
    Pay
  </button>
  <div class="modal fade" id="payfororder" tabindex="-1" role="dialog"
       aria-labelledby="PayforOrder"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="PayforOrder">Pay for Order </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <div class="bs-stepper">
            <div class="bs-stepper-header" role="tablist">
              <!-- your steps here -->
              <div class="step" data-target="#logins-part">
                <button type="button" class="step-trigger" role="tab" aria-controls="logins-part"
                        id="logins-part-trigger">
                  <span class="bs-stepper-circle">1</span>
                  <span class="bs-stepper-label">OTP</span>
                </button>
              </div>
              <div class="line"></div>
              <div class="step" data-target="#information-part">
                <button type="button" class="step-trigger" role="tab" aria-controls="information-part"
                        id="information-part-trigger">
                  <span class="bs-stepper-circle">2</span>
                  <span class="bs-stepper-label">Make Payment</span>
                </button>
              </div>
            </div>
            <div class="bs-stepper-content">
              <div id="logins-part" class="content" role="tabpanel" aria-labelledby="logins-part-trigger">
                <send-o-t-p-for-payment
                    :errors="errors"
                    @updatedotp="args => otp = args"
                ></send-o-t-p-for-payment>
              </div>
              <div id="information-part" class="content" role="tabpanel" aria-labelledby="information-part-trigger">
                <div class="alert alert-success" role="alert" v-if="parseFloat(order_amount) < parseFloat(balance)">
                  <strong>
                    You have <strong>{{ parseFloat(balance).toLocaleString() }}</strong> in your wallet. It is
                    sufficient to pay for this
                    order
                  </strong>
                  <div>
                    <!--                   <a href="#" class="btn btn-secondary mt-3">Confirm Payment</a>-->
                  </div>
                </div>
                <div class="alert alert-warning" role="alert" v-else>
                  <strong>
                    You do not have enough funds in your wallet. Click on next to top your wallet.
                  </strong>
                  <div>
                    <!--                   <a href="#" class="btn btn-secondary mt-3">Confirm Payment</a>-->
                  </div>
                </div>

                <div class="d-flex justify-content-center" v-if="topup">
                  <div class="col-6 border border-3 p-4">
                    <InlineTopUp
                        ref="inlineTopUp"
                        :key="keyinlinetopup"
                        @walletToppedUp="walletToppedUp"
                        :amountToPay="parseInt(order_amount) - parseInt(balance)"
                    ></InlineTopUp>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!--          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close-->
          <!--          </button>-->
          <template v-if="currentIndex === 0">
            <button type="button" class="btn btn-outline-secondary" @click="nextPage">Next</button>
          </template>
          <template v-else>
            <div v-if="parseFloat(order_amount) < parseFloat(balance)">
              <button type="button" class="btn btn-outline-secondary"
                      :disabled="saving"
                      @click="completePayments">
                Complete
                <i class="fa fa-save" v-if="!saving"></i>
                <i class="fa fa-spin fa-spinner" v-else></i>
              </button>

            </div>
            <div v-else>
              <button type="button" class="btn btn-secondary px-4"
                      @click="updateTopUpAMount"
                      id="topupaccount"
              >
                Next
              </button>

            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
