<script setup>
import { onMounted, ref } from "vue";

const props = defineProps([
    "user_id",
    "type",
    "id",
    "from",
    "due",
    "amount",
    "switchto",
    "upcomingpayment",
    "buyer_id",
    "market",
    "currency",
    "credit_score",
]);

const from = ref("");
const type = ref("");
const status = ref("");
const market = ref("");
const due = ref("");
const userCreditScore = ref("");
const upcomingPayments = ref([]);

onMounted(() => {
    axios.get("/apis/v4/orderandpay").then((res) => {
        from.value = res?.data?.from;
        type.value = res?.data?.upcomingPayments?.type;
        status.value = res?.data?.upcomingPayments?.status;
        market.value = res?.data?.market;
        due.value = res?.data?.due;
        userCreditScore.value = res?.data?.credit_score;
        upcomingPayments.value =
            res?.data?.upcomingPayments?.data?.filter((i) => !i?.deleted_at) ??
            [];
    });
});
</script>

<template>
    <div v-if="upcomingPayments.length > 0">
        <div
            v-for="payment in upcomingPayments"
            :key="payment?.id"
            style="
                border-radius: 0.25rem;
                border: 1px solid rgba(29, 36, 46, 0.3);
                background: #fff;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                position: relative;
                margin-bottom: 1rem;
            "
        >
            <div
                style="
                    width: 4px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(29, 36, 46, 0.3);
                "
            ></div>
            <div
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    border-bottom: 1px solid rgba(29, 36, 46, 0.3);
                    padding: 0.8rem 1.3rem;
                    flex-wrap: wrap;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 0.3rem;
                    "
                >
                    <div
                        class="dot-success"
                        style="width: 0.75rem; height: 0.75rem"
                    ></div>
                    <!-- <div -->
                    <!--     v-if="payment?.status === 'Paid'" -->
                    <!--     class="dot-success" -->
                    <!--     style="width: 0.75rem; height: 0.75rem" -->
                    <!-- ></div> -->
                    <!-- <div -->
                    <!--     v-else-if=" -->
                    <!--         payment?.status === 'Overdue' || -->
                    <!--         payment?.status === 'OverDue' || -->
                    <!--         payment?.status === 'Rejected' -->
                    <!--     " -->
                    <!--     class="dot-danger" -->
                    <!--     style="width: 0.75rem; height: 0.75rem" -->
                    <!-- ></div> -->
                    <!-- <div -->
                    <!--     v-else-if="payment?.status === 'Pending'" -->
                    <!--     class="dot-pending" -->
                    <!--     style="width: 0.75rem; height: 0.75rem" -->
                    <!-- ></div> -->
                    <!-- <div -->
                    <!--     v-else-if="payment?.status === 'Accepted'" -->
                    <!--     class="dot-success" -->
                    <!--     style="width: 0.75rem; height: 0.75rem" -->
                    <!-- ></div> -->
                    <!-- <div -->
                    <!--     v-else -->
                    <!--     class="dot-warn" -->
                    <!--     style="width: 0.75rem; height: 0.75rem" -->
                    <!-- ></div> -->
                    <div
                        style="
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font-weight: 600;
                            flex-wrap: wrap;
                            gap: 0.2rem;
                        "
                    >
                        <!-- {{ props.type }} -->
                        <span
                            v-if="payment.type === 'Purchase'"
                            style="font-weight: 400"
                            ><span style="font-weight: 600">{{
                                payment.type
                            }}</span>
                            from
                        </span>
                        <span v-else style="font-weight: 400"
                            ><span style="font-weight: 600">{{
                                payment.type
                            }}</span>
                            to
                        </span>
                        {{ payment?.market?.name }}
                    </div>
                </div>
                <p style="margin: 0; font-weight: 600">
                    {{ currency }}{{ payment?.amount?.toLocaleString() ?? "" }}
                </p>
            </div>
            <div
                style="
                    padding: 0.8rem 1.3rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    flex-wrap: wrap;
                    gap: 1rem;
                "
            >
                <div
                    style="
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                    "
                >
                    <div
                        style="
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font-weight: 600;
                            gap: 0.3rem;
                        "
                    >
                        <template v-if="payment?.market?.name === 'Amana'">
                            Amana Market (CoAmana)
                        </template>
                        <template v-else>
                            Market:
                            <span style="font-weight: 400"
                                >{{ payment?.market?.name }}
                            </span>
                        </template>
                    </div>
                    <div
                        style="
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font-weight: 600;
                            gap: 0.3rem;
                        "
                    >
                        Date:
                        <span style="font-weight: 400">{{
                            payment?.upcoming_at
                        }}</span>
                    </div>

                    <div
                        style="
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font-weight: 600;
                            gap: 0.3rem;
                        "
                    >
                        Status:

                        <span
                            v-if="payment?.status === 'Paid'"
                            class="text-success"
                            style="font-weight: 400"
                            >{{ payment?.status }}</span
                        >
                        <span
                            v-else-if="
                                payment?.status === 'Overdue' ||
                                payment?.status === 'OverDue' ||
                                payment?.status === 'Rejected'
                            "
                            class="text-danger"
                            style="font-weight: 400"
                            >{{ payment?.status }}</span
                        >
                        <span
                            v-else-if="payment?.status === 'Pending'"
                            class="text-pending"
                            style="font-weight: 400"
                            >{{ payment?.status }}</span
                        >
                        <span
                            v-else-if="payment?.status === 'Accepted'"
                            class="text-success"
                            style="font-weight: 400"
                            >{{ payment?.status }}</span
                        >
                        <span
                            v-else
                            class="text-warn"
                            style="font-weight: 400"
                            >{{ payment?.status }}</span
                        >
                    </div>
                </div>

                <a
                    v-if="
                        payment?.payable_type === 'Order' &&
                        payment?.payable?.meta?.order_accepted_by_seller &&
                        (payment?.status !== 'Rejected' ||
                            payment?.status !== 'Automated Rejection')
                    "
                    :href="'/four/admin/upcoming-payments/' + payment?.id"
                    type="button"
                    style="
                        color: #4b9d30;
                        text-align: right;
                        font-size: 0.75rem;
                        text-decoration: none;
                    "
                >
                    <div
                        style="
                            color: #4b9d30;
                            text-align: right;
                            font-size: 0.75rem;
                        "
                    >
                        View
                        <svg
                            width="9"
                            height="8"
                            viewBox="0 0 9 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                                fill="#4B9D30"
                            />
                            <path
                                d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                                fill="#4B9D30"
                            />
                        </svg>
                    </div>
                </a>

                <a
                    v-if="
                        payment?.payable_type === 'Order' &&
                        !payment?.payable?.meta?.order_accepted_by_seller &&
                        (payment?.status !== 'Rejected' ||
                            payment?.status !== 'Automated Rejection')
                    "
                    :href="'/four/admin/upcoming-payments/' + payment?.id"
                    type="button"
                    style="
                        color: #4b9d30;
                        text-align: right;
                        font-size: 0.75rem;
                        text-decoration: none;
                    "
                >
                    <div
                        style="
                            color: #4b9d30;
                            text-align: right;
                            font-size: 0.75rem;
                        "
                    >
                        View
                        <svg
                            width="9"
                            height="8"
                            viewBox="0 0 9 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                                fill="#4B9D30"
                            />
                            <path
                                d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                                fill="#4B9D30"
                            />
                        </svg>
                    </div>
                </a>

                <a
                    v-else-if="
                        payment?.payable_type === 'Order' &&
                        payment?.status === 'Rejected'
                    "
                    :href="'/four/admin/upcoming-payments/' + payment?.id"
                    type="button"
                    disabled
                    style="
                        color: #4b9d30;
                        text-align: right;
                        font-size: 0.75rem;
                        text-decoration: none;
                    "
                >
                    <div
                        style="
                            color: #4b9d30;
                            text-align: right;
                            font-size: 0.75rem;
                        "
                    >
                        View
                        <svg
                            width="9"
                            height="8"
                            viewBox="0 0 9 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                                fill="#4B9D30"
                            />
                            <path
                                d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                                fill="#4B9D30"
                            />
                        </svg>
                    </div>
                </a>

                <a
                    v-else-if="
                        payment?.payable_type !== 'Order' &&
                        payment?.status === 'Paid'
                    "
                    :href="'/four/admin/upcoming-payments/' + payment?.id"
                    type="button"
                    style="
                        color: #4b9d30;
                        text-align: right;
                        font-size: 0.75rem;
                        text-decoration: none;
                    "
                >
                    <div
                        style="
                            color: #4b9d30;
                            text-align: right;
                            font-size: 0.75rem;
                        "
                    >
                        View
                        <svg
                            width="9"
                            height="8"
                            viewBox="0 0 9 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                                fill="#4B9D30"
                            />
                            <path
                                d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                                fill="#4B9D30"
                            />
                        </svg>
                    </div>
                </a>

                <a
                    v-else-if="
                        payment?.payable_type !== 'Order' &&
                        payment?.status !== 'Paid'
                    "
                    :href="'/four/admin/upcoming-payments/' + payment?.id"
                    type="button"
                    style="
                        color: #4b9d30;
                        text-align: right;
                        font-size: 0.75rem;
                        text-decoration: none;
                    "
                >
                    <div
                        style="
                            color: #4b9d30;
                            text-align: right;
                            font-size: 0.75rem;
                        "
                    >
                        Pay
                        <svg
                            width="9"
                            height="8"
                            viewBox="0 0 9 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                                fill="#4B9D30"
                            />
                            <path
                                d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                                fill="#4B9D30"
                            />
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div
        v-else
        class="tw-border-2 tw-border-dashed tw-border-gray-300 tw-p-12 tw-text-center hover:tw-border-gray-400"
    >
        <svg
            class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
        >
            <path
                vector-effect="non-scaling-stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
        </svg>
        <h3 class="tw-mt-2 tw-text-sm tw-font-semibold tw-text-gray-900">
            No upcoming payments.
        </h3>
        <p class="tw-mt-1 tw-text-sm tw-text-gray-500">
            You have no upcoming payments. Make an order or add a fee.
        </p>
    </div>

    <!-- <div class="d-flex flex-column flex-md-row border border-2 rounded border-secondary flex-row py-2 px-4 my-2"> -->
    <!--     <div class="col-2 d-flex align-items-center"><span class="dot-danger"></span></div> -->
    <!--     <div class="d-flex flex-column col-md-8"> -->
    <!--         <div><strong>Purchase </strong> from <strong>Hafsah CoAmana</strong><span><i -->
    <!--             class="fa-solid fa-face-meh-blank fa-lg" style="color: rgb(108, 117, 125);"></i></span></div> -->
    <!--         <div> -->
    <!--             <div><strong>Market</strong>: October 14</div> -->
    <!--         </div> -->
    <!--         <div><strong>Due</strong>: Mon, Dec 11, 2023 9:45 AM</div> -->
    <!--     </div> -->
    <!--     <div class="d-flex flex-column col-2"> -->
    <!--         <div>₦100.00</div> -->
    <!--         <a href="/four/admin/upcoming-payments/49" type="button" class="warn-text"> -->
    <!--             <div class="warn-text"><strong>View</strong></div> -->
    <!--         </a></div> -->
    <!-- </div> -->
</template>
