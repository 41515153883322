<script setup>

import {onBeforeUnmount, onMounted, ref, watch} from "vue";

const props = defineProps(['user']);

const emits = defineEmits(['productset','amountset', 'quantityset']);

onMounted(function () {
  // getItems();
});

const products = ref('');
const product = ref('');
const loading = ref(true);
const amount = ref('');
const quantity = ref(null);
const errors = ref({});
const userid = ref('');
const showExtraInformation = ref(false);
const currencySign = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN

watch(amount, async (newAmount, oldAmount) => {
    if (newAmount < 0) {
        amount.value = 0;
        emits('amountset',0);
        return;
    }
  emits('amountset',newAmount);
})
watch(quantity, async (newAmount, oldAmount) => {

    if (newAmount < 0) {
        quantity.value = 0;
        emits('quantityset',0);
        return;
    }
  emits('quantityset',newAmount);
})

function setAmountError() {
    // set the error for amount
    errors.value.amount = 'Please enter a valid amount';
}
function getItems() {
  // axios.post(`/api/getItems/product_and_services`, {
  //   user_id: userid.value
  // }).then(res => {
  //   products.value = res.data;
  // });
}

function selectProduct(productData) {
  product.value = productData;
  showExtraInformation.value = true;
  emits('productset',productData)
}
defineExpose({
  getItems,
  selectProduct,
  userid,
  product,
    setAmountError
})
</script>

<template>
  <div>
    <div class="jumbotron jumbotron-fluid">
      <div class="container-fluid">
        <template v-if="showExtraInformation">
          <div class="card">
            <div class="card-body">
              <div class="row">
              <div class="col-md-12 mb-3" v-if="product != 'skip'">
                <div class="transaction-history-success-table py-2 px-4">
                  <p class="icon-active-color fw-bold m-0"> {{ product.product.name }}</p>
                  <p class="fw-bold m-0"> {{ product.member.name }}</p>
                  <p class="m-0"> {{ product.measurement_unit.name }}</p>
                  <p class="m-0"> {{ product.market.name }}</p>
                  <p class="icon-active-color fw-bold">  {{currencySign}}{{ product.amount }}</p>
                </div>

              </div>
              <div class="col-md-6">
                <!--Label: Amount, Attributes:amount -->
                <div class="form-floating">
                  <input type="number" class="form-control"
                         name="amount"
                         min="1"
                         id="amount" v-model="amount"
                         aria-describedby="amount-help"
                         :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                  <label class="form-label" for="amount">How much are you paying?</label>

                  <div class="invalid-feedback" v-if="errors.amount">
                    {{ errors.amount.toString() }}
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="product != 'skip'">
                <!--Label: Quantity, Attributes:quantity -->
                <div class="form-floating">

                  <input type="number" class="form-control" id="quantity" v-model="quantity"
                         aria-describedby="quantity-help"
                         :class="[errors.quantity ? 'is-invalid': '',!errors.quantity && Object.keys(errors).length > 1 ? 'is-valid': '']"
                         >
                  <label for="quantity" class="form-label">How many
                    <strong>{{ product.measurement_unit.name }}</strong>
                    of
                    <strong>{{ product.product.name}}</strong>
                    are you paying for
                  </label>
                  <div class="invalid-feedback" v-if="errors.quantity">
                    {{ errors.quantity.toString() }}
                  </div>
                </div>
              </div>
            </div></div>
          </div>
        </template>
        <template v-else>
          <div class="border border-3 p-3 rounded">
            <div class="col-md-6">
              <!--Label: Amount, Attributes:amount -->
              <div class="form-floating">
                <input type="number" class="form-control"
                       name="amount"
                       id="amount" v-model="amount"
                       aria-describedby="amount-help"
                       :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                >
                <label class="form-label" for="amount">How much are you paying?</label>

                <div class="invalid-feedback" v-if="errors.amount">
                  {{ errors.amount.toString() }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
