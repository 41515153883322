<script setup>
import { ref, watch } from "vue";

const otp = ref("");
const emit = defineEmits(["updatedotp"]);
const props = defineProps(["errors"]);

watch(otp, function (newValue, oldValue) {
    emit("updatedotp", newValue);
});
</script>

<template>
    <div>
        <div class="col-12">
            <!--Label: OTP, Attributes:otp -->
            <div class="form-floating">
                <input
                    type="text"
                    class="form-control"
                    id="otp"
                    v-model="otp"
                    aria-describedby="otp-help"
                    :class="[
                        errors.otp ? 'is-invalid' : '',
                        !errors.otp && Object.keys(errors).length > 1
                            ? 'is-valid'
                            : '',
                    ]"
                />
                <label for="otp" class="form-label">Insert OTP</label>
                <div class="invalid-feedback" v-if="errors.otp">
                    {{ errors.otp.toString() }}
                </div>
            </div>
        </div>
        <div class="col-12 mt-3">
            <div class="alert alert-success" role="alert">
                <div class="d-flex justify-content-between">
                    <div>
                        <i class="fa fa-info-circle fa-3x"></i>
                    </div>
                    <div class="px-4 flex-grow-1">
                        <p>
                            An OTP has been sent to the
                            <strong>phone number</strong> associated with your
                            account. Please input the OTP Code to continue
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

