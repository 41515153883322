<script setup>
import SendOTPForPayment from "./wallet/payments/sendOTPForPayment.vue";
import {ref} from "vue";

const props = defineProps([
  'phone',
  'user_id',
  'id'
]);

const mode = ref('');
const otp = ref('');
const errors = ref({});

function sendOTP(data) {
  mode.value = data;
  axios.post('/apis/v4/send_otp', {
    'phone': props.phone,
    'user_id': props.user_id,
    message:
    'Your one-time password to accept payment on Amana Market is {otp} and is valid for the next 2 minutes.',
    'key': 'accept_or_reject_order',
  })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
}

const saving = ref(false);

function complete() {
  saving.value = true;
  if (mode.value === 'accept') {
    axios.post(`/four/admin/orders/approve`, {
      'otp': otp.value,
      'user_id': props.user_id,
      'id': props.id,
    })
        .then(resp => {
          window.location.href += "?approved=1";
        }).catch(err => {
      errors.value = err.response.data.errors;
    }).finally(() => {
      saving.value = false;
    });
    return;
  }
  axios.post(`/four/admin/orders/approve?reject=1`, {
    'otp': otp.value,
    'user_id': props.user_id,
    'id': props.id,
  })
      .then(resp => {
        location.reload();
      }).catch(err => {
    errors.value = err.response.data.errors;
  }).finally(() => {
    saving.value = false;
  });
}

</script>

<template>
  <button @click="sendOTP('accept')" type="button" class="btn btn-primary btn-sm radius-30 px-4" data-bs-toggle="modal"
          data-bs-target="#accept">
    Accept
  </button>
  <button @click="sendOTP('reject')" type="button" class="btn mx-2 btn-outline-danger btn-sm radius-30 px-4"
          data-bs-toggle="modal"
          data-bs-target="#accept">
    Reject
  </button>

  <!-- Modal -->
  <div class="modal fade" id="accept" tabindex="-1" role="dialog"
       aria-labelledby="accept" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="accept">
            <template v-if="mode==='accept'">Accepting Order</template>
            <template v-else>Rejecting Order</template>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <div v-if="mode==='accept'">
            <div class="alert bg-light-success" role="alert">
              <strong>By Accepting this order. Money will be transferred into your wallet.</strong>
            </div>
          </div>
          <div v-else>
            <div class="alert bg-light-danger" role="alert">
              <strong>By Rejecting, Money will be returned to the sellers wallet.</strong>
            </div>
          </div>
          <send-o-t-p-for-payment
              :errors="errors"
              @updatedotp="args => otp = args"
          ></send-o-t-p-for-payment>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary"
                  data-bs-dismiss="modal">Close
          </button>
          <button type="button"
                  :disabled="saving"
                  class="btn btn-success" @click.prevent="complete" v-if="mode==='accept'">
            Accept
            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
          </button>
          <button type="button" class="btn btn-danger"
                  :disabled="saving"
                  @click.prevent="complete" v-else>
            Reject
            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
