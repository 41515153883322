<script setup>
import { onMounted, ref, watch } from "vue";
import InlineTopUp from "./inlinetopup/InlineTopUp.vue";
import _ from "lodash";

const props = defineProps([
    "product",
    "amount",
    "quantity",
    "borrowinglimit_clear",
    "balance_clear_prop",
    "market",
    "borrowinglimit_clear_prop",
]);
const emit = defineEmits(["enablepayment", "totalamountbilled"]);

const charges = ref("");

function updateBalance(balance) {
    balance_clear.value = balance;
    // totalAmount();
}

function updateCreditBalance(balance) {
    credit_limit_clear.value = balance;
    // totalAmount();
}

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const errors = ref("");
const number_of_days_to_pay = ref();
const showpop = ref(false);
const selectedmethod = ref("");
const balance_clear = ref(0);
const credit_limit_clear = ref(0);
const forsomeoneelse = ref(false);

function updateBalanceCredit(balance) {
    credit_limit_clear.value = balance;
    forsomeoneelse.value = true;
}

const withoutMarketInsurance = ref({});
onMounted(function () {
    balance_clear.value = props.balance_clear_prop;
    /*optimize this as its a quick fix*/
    axios.get("/userFees?method=wallet").then((resp) => {
        charges.value = resp.data;
        charges.value = resp.data;
        withoutMarketInsurance.value = resp.data;
        selectedmethod.value = "wallet";
    });
});

function currencyFormat(amount) {
    let amounts = new Intl.NumberFormat("en-KE", {
        maximumSignificantDigits: 3,
    }).format(amount);
    return `${currency}${amounts}`;
}

watch(number_of_days_to_pay, function (newValue, oldValue) {
    emit("updatenumberofdays", newValue);
});

function updatePaymentMethod(method) {
    selectedmethod.value = method;

    if (method === "wallet") {
        charges.value = withoutMarketInsurance.value;
        if (
            props.amount + 0.025 * props.amount <
            parseInt(props.balance_clear)
        ) {
            emit("enablepayment", false, method);
            return;
        }

        emit("enablepayment", true, method);
        emit("selectedmethod", "wallet");
    }
    if (method === "credit") {
        axios.get("/userFees").then((resp) => {
            charges.value = resp.data;
            charges.value = resp.data;
            selectedmethod.value = "credit";
        });
        let total =
            props.amount +
            0.025 * props.amount +
            0.003 * props.amount * number_of_days_to_pay.value;

        if (total < parseInt(credit_limit_clear.value)) {
            emit("enablepayment", false, method);
            return;
        }
        emit("enablepayment", true, method);
        emit("selectedmethod", "credit");
    }
}

watch(number_of_days_to_pay, function (newValue, oldValue) {
    updatePaymentMethod("credit");
    if (newValue > charges?.value.bnpl?.daily_fee_max_days ?? 365) {
        errors.value = {
            number_of_days_to_pay: `The maximum number of days you can take to pay is ${charges?.value.bnpl?.daily_fee_max_days}.`,
        };
    } else if (
        !number_of_days_to_pay.value ||
        number_of_days_to_pay.value < 1
    ) {
        errors.value = {
            number_of_days_to_pay: `Please input a valid number of days`,
        };
    } else {
        errors.value = {};
    }
});

watch(selectedmethod, async (newQuestion, oldQuestion) => {
    totalAmount();
});

watch(number_of_days_to_pay, async (newQuestion, oldQuestion) => {
    totalAmount();
});

function resetMethod() {
    document.getElementById("pills-home-tab").click();
}

defineExpose({
    updatePaymentMethod,
    resetMethod,
    updateBalance,
    updateCreditBalance,
    updateBalanceCredit,
});

function totalAmount() {
    let fee = 0;

    if (selectedmethod.value === "credit") {
        fee =
            (charges.value.bnpl?.fee +
                number_of_days_to_pay?.value * charges.value.bnpl?.daily_fee) *
            props.amount;
    }
    if (selectedmethod.value === "wallet") {
        fee = charges.value?.marketFee?.fee * props.amount;
    }
    let totalCharges = props.amount * charges.value.charges_total;
    let total = fee + totalCharges + props.amount;
    emit("totalamountbilled", total);
    return total;
}
</script>

<template>
    <div class="row">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    :style="
                        selectedmethod === 'wallet'
                            ? { backgroundColor: '#00AB4D', color: 'white' }
                            : { color: 'green' }
                    "
                    @click="updatePaymentMethod('wallet')"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                >
                    Pay With Wallet
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    :style="
                        selectedmethod === 'credit'
                            ? { backgroundColor: '#00AB4D', color: white }
                            : { color: 'green' }
                    "
                    id="pills-profile-tab"
                    @click="updatePaymentMethod('credit')"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                >
                    Pay With Credit
                </button>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
            >
                <div class="card bg-blue" v-if="selectedmethod == 'wallet'">
                    <div class="card-body">
                        <h4 class="card-title text-black">Payment Summary</h4>

                        <div class="col-12 text-black">
                            <div
                                class="transaction-history-success-table d-flex d-md-none p-4 mt-3"
                            >
                                <div
                                    class="flex-grow-1 d-flex flex-column align-items-start"
                                >
                                    <p class="m-0 icon-active-color fw-bold">
                                        Total Due
                                    </p>
                                    <p class="m-0 icon-active-color fw-bold">
                                        {{ currency
                                        }}{{ totalAmount().toLocaleString() }}
                                    </p>
                                    <p class="m-0 mt-2">Payment to Seller</p>
                                    <p class="m-0">
                                        {{ currencyFormat(props.amount) }}
                                    </p>
                                    <p class="m-0 mt-2">Amana Market Charges</p>
                                    <p class="m-0">
                                        {{
                                            currencyFormat(
                                                charges.marketFee?.fee *
                                                    props.amount
                                            )
                                        }}
                                    </p>

                                    <p class="m-0 mt-2">
                                        {{ market }} Market Charges
                                        <span class="text-muted">
                                            {{ charges.all_charges }}
                                        </span>
                                    </p>
                                    <p class="m-0"></p>
                                    <p class="m-0">
                                        {{
                                            currencyFormat(
                                                charges.charges_total *
                                                    props.amount
                                            )
                                        }}
                                    </p>

                                    <p class="m-0 mt-2">Wallet Balance</p>

                                    <div
                                        class="m-0"
                                        v-if="
                                            totalAmount() >
                                            parseInt(balance_clear_prop)
                                        "
                                    >
                                        <div>
                                            <!-- <strong -->
                                            <!--     >{{ currency -->
                                            <!--     }}{{ -->
                                            <!--         ( -->
                                            <!--             parseFloat( -->
                                            <!--                 totalAmount() -->
                                            <!--             ).toFixed() - -->
                                            <!--             parseFloat( -->
                                            <!--                 balance_clear_prop -->
                                            <!--             ).toFixed() -->
                                            <!--         ).toLocaleString() -->
                                            <!--     }}</strong -->
                                            <!-- > -->
                                            <strong
                                                >{{ currency
                                                }}{{
                                                    parseFloat(
                                                        balance_clear_prop
                                                    )
                                                        .toFixed()
                                                        .toLocaleString()
                                                }}</strong
                                            >
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{ currencyFormat(balance_clear_prop) }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="transaction-history-success-table d-flex p-4 d-none d-md-flex"
                            >
                                <div
                                    class="flex-grow-1 d-flex flex-column align-items-center"
                                >
                                    <p class="m-0 icon-active-color fw-bold">
                                        Total Due
                                    </p>
                                    <p class="m-0">Payment to Seller</p>
                                    <p class="m-0">Amana Market Charges</p>
                                    <p class="m-0">
                                        {{ market }} Market Charges
                                        <span class="text-muted">
                                            {{ charges.all_charges }}
                                        </span>
                                    </p>
                                    <p class="m-0">Wallet Balance</p>
                                </div>
                                <div class="flex-grow-1">
                                    <p class="m-0 icon-active-color fw-bold">
                                        {{ currency
                                        }}{{ totalAmount().toLocaleString() }}
                                    </p>
                                    <p class="m-0">
                                        {{ currencyFormat(props.amount) }}
                                    </p>
                                    <p class="m-0">
                                        {{
                                            currencyFormat(
                                                charges.marketFee?.fee *
                                                    props.amount
                                            )
                                        }}
                                    </p>
                                    <p class="m-0">
                                        {{
                                            currencyFormat(
                                                charges.charges_total *
                                                    props.amount
                                            )
                                        }}
                                    </p>
                                    <div
                                        class="m-0"
                                        v-if="
                                            totalAmount() >
                                            parseInt(balance_clear_prop)
                                        "
                                    >
                                        <div>
                                            <strong
                                                >{{ currency
                                                }}{{
                                                    parseFloat(
                                                        balance_clear_prop
                                                    )
                                                        .toFixed()
                                                        .toLocaleString()
                                                }}</strong
                                            >
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{ currencyFormat(balance_clear_prop) }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="d-flex mt-4 d-sm-block"
                                v-if="
                                    totalAmount() > parseInt(balance_clear_prop)
                                "
                            >
                                <div
                                    :style="{
                                        background: '#fff',
                                        border: '1px solid #ff7900 !important',
                                        color: '#ff7900 !important',
                                    }"
                                    class="alert alert-danger m-0"
                                    role="alert"
                                >
                                    <div>
                                        <p>
                                            Your wallet balance is
                                            <strong
                                                >{{ currency
                                                }}{{
                                                    parseFloat(
                                                        balance_clear_prop
                                                    )
                                                        .toFixed()
                                                        .toLocaleString()
                                                }}</strong
                                            >
                                            proceed and top up wallet. Third
                                            party payment charges may apply.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
            >
                <div
                    class="transaction-history-success-table d-md-flex d-none p-4"
                >
                    <div
                        class="flex-grow-1 d-flex flex-column align-items-center"
                    >
                        <p class="m-0">Payment to Seller</p>
                        <p class="m-0">Amana Market Charges</p>
                        <p class="m-0">
                            {{ market }} Market Charges
                            <span class="text-muted">
                                {{ charges.all_charges }}
                            </span>
                        </p>
                        <p class="m-0">Credit Balance</p>
                    </div>

                    <div class="flex-grow-1">
                        <p class="m-0">{{ currencyFormat(props.amount) }}</p>
                        <p class="m-0">
                            {{
                                currencyFormat(
                                    charges.bnpl?.fee * amount +
                                        (number_of_days_to_pay ?? 0) *
                                            charges?.bnpl?.daily_fee *
                                            amount
                                )
                            }}
                        </p>
                        <p class="m-0">
                            {{
                                currencyFormat(
                                    charges.charges_total * props.amount
                                )
                            }}
                        </p>

                        <div
                            class=""
                            v-if="
                                parseInt(borrowinglimit_clear) < totalAmount()
                            "
                        >
                            <strong>{{
                                currencyFormat(parseInt(borrowinglimit_clear))
                            }}</strong>
                        </div>
                        <div v-else>
                            {{ currency
                            }}{{ borrowinglimit_clear.toLocaleString() }}
                        </div>
                    </div>
                </div>

                <div
                    class="transaction-history-success-table d-flex d-md-none p-4"
                >
                    <div
                        class="flex-grow-1 d-flex flex-column align-items-start"
                    >
                        <p class="m-0">Payment to Seller</p>
                        <p class="m-0">{{ currencyFormat(props.amount) }}</p>
                        <p class="m-0 mt-2">Amana Market Charges</p>
                        <p class="m-0">
                            {{
                                currencyFormat(
                                    charges.bnpl?.fee * amount +
                                        (number_of_days_to_pay ?? 0) *
                                            charges?.bnpl?.daily_fee *
                                            amount
                                )
                            }}
                        </p>
                        <p class="m-0 mt-2">
                            {{ market }} Market Charges
                            <span class="text-muted">
                                {{ charges.all_charges }}
                            </span>
                        </p>
                        <p class="m-0">
                            {{
                                currencyFormat(
                                    charges.charges_total * props.amount
                                )
                            }}
                        </p>
                        <p class="m-0 mt-2">Credit Balance</p>

                        <div
                            class=""
                            v-if="parseInt(balance_clear) < totalAmount()"
                        >
                            <strong>{{
                                currencyFormat(parseInt(balance_clear))
                            }}</strong>
                        </div>
                        <div v-else>
                            {{ currencyFormat(balance_clear_prop) }}
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <!--Label: Number of days to pay, Attributes:number_of_days_to_pay -->
                    <div class="form-floating">
                        <input
                            type="number"
                            class="form-control"
                            id="number_of_days_to_pay"
                            v-model="number_of_days_to_pay"
                            style="border: #8a8e92 1px solid"
                            aria-describedby="number_of_days_to_pay-help"
                            :class="[
                                errors.number_of_days_to_pay
                                    ? 'is-invalid'
                                    : '',
                                !errors.number_of_days_to_pay &&
                                Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        />
                        <label
                            class="form-label"
                            for="number_of_days_to_pay"
                            style="color: #8a8e92"
                        >
                            <strong> Number of days to pay</strong>
                        </label>
                        <div
                            class="invalid-feedback"
                            v-if="errors.number_of_days_to_pay"
                        >
                            {{ errors.number_of_days_to_pay.toString() }}
                        </div>
                    </div>
                </div>
                <div
                    class="d-md-flex mt-4"
                    v-if="parseInt(borrowinglimit_clear) < totalAmount()"
                >
                    <div
                        :style="{
                            background: '#fff',
                            border: '1px solid #ff7900 !important',
                            color: '#ff7900 !important',
                        }"
                        class="alert alert-danger m-0"
                        role="alert"
                    >
                        <div>
                            <p>
                                Your credit balance is
                                <!-- <strong -->
                                <!--     >{{ currency -->
                                <!--     }}{{ -->
                                <!--         ( -->
                                <!--             parseFloat( -->
                                <!--                 totalAmount() -->
                                <!--             ).toFixed() - -->
                                <!--             parseFloat( -->
                                <!--                 balance_clear_prop -->
                                <!--             ).toFixed() -->
                                <!--         ).toLocaleString() -->
                                <!--     }}</strong -->
                                <!-- > -->
                                <strong
                                    >{{ currency
                                    }}{{
                                        parseFloat(borrowinglimit_clear)
                                            .toFixed()
                                            .toLocaleString()
                                    }}</strong
                                >
                                ,proceed and top up wallet. Third party payment
                                charges may apply.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
