<script setup>
import {ref} from "vue";
import moment from "moment";

const props = defineProps({
  'balance': {},
  'borrowinglimit': {},
  'currency': {},
});
let search = ref('');
let errors = ref({});
let results = ref({});
let timeline = ref(1);
let selectedUser = ref({});
let showResults = ref(true);
let showOTP = ref(false);
let products = ref({});
let selectedProduct = ref(null);
let payment_purpose = ref('');
let amount = ref(0);
let quantity = ref(0);
let otp = ref("");

function makeOrder() {
  let user = JSON.parse(document.querySelector('meta[name="user"]').content);

  axios.post('/api/paywithwallet/makeorder', {
    product: selectedProduct.value,
    quantity: quantity.value,
    amount: amount.value,
    seller: selectedUser.value,
    user_id: user.id,
    otp: otp.value,
  }).then(() => {
    errors.value = {};
    location.reload();
  }).catch(err => {
    errors.value = err.response.data.errors;
  });
}

function sendOTP() {
  let user = JSON.parse(document.querySelector('meta[name="user"]').content);

  axios.post('/api/paywithwallet/sendOTP', {
    product: selectedProduct.value,
    quantity: quantity.value,
    amount: amount.value,
    seller: selectedUser.value,
    user_id: user.id,
  }).then(() => {
    showOTP.value = true;
  });
}

function addDays(days) {
  return moment().add(days, 'd').format('L');
}

function searchPhoneNumber() {
  axios.post('/api/searchPhoneNumber', {
    phone: search.value
  }).then(res => {
    results.value = res.data;
  });
}

function selectProduct(product) {
  selectedProduct.value = product;
}

function getItems() {
  axios.post(`/api/getItems/${payment_purpose.value}`, {
    user_id: selectedUser.value.id
  }).then(res => {
    products.value = res.data;
  });
}

function selectUser(user) {
  selectedUser.value = user;
  showResults.value = false;
}
</script>

<template>
  <div class="row">
    <div class="col-12" v-if="showResults">
      <label for="search">Phone Number of Recipient</label>
      <div class="input-group">
        <input type="text" class="form-control" id="search" v-model="search"
               aria-describedby="search-help"
               :class="[errors.search ? 'is-invalid': '',!errors.search && Object.keys(errors).length > 1 ? 'is-valid': '']"
               placeholder="Search" required>
        <span class="input-group-btn" @click.prevent="searchPhoneNumber">
    		<button class="btn btn-light" style="border: transparent" type="button"
                aria-label="search">
          Search 
          <i class="fa fa-search"></i>
        </button>
      </span>
        <div class="invalid-feedback" v-if="errors.search">
          {{ errors.search.toString() }}
        </div>
      </div>
    </div>
    <div class="col-12" v-if="showResults">
      <table class="table">
        <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Phone Number</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user,index) in results">
          <td scope="row">{{ index + 1 }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.formattedphone }}</td>
          <td>
            <button class="btn btn-primary" @click="selectUser(user)">
              Select
            </button>
          </td>
        </tr>
        <tr>
          <td scope="row"></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-if="!showResults">

      <div class="col-12">
        <div class="row">
          <div class="col-md-6">
            <!--Label: Purpose of payment, Attributes:payment_purpose -->
            <div class="form-group">
              <label for="payment_purpose">Purpose of payment</label>
              <select type="text" class="form-control" id="payment_purpose" v-model="payment_purpose"
                      aria-describedby="payment_purpose-help"
                      @change="getItems"
                      :class="[errors.payment_purpose ? 'is-invalid': '',!errors.payment_purpose && Object.keys(errors).length > 1 ? 'is-valid': '']"
              >
                <option value="" selected disabled>Select Purpose of payment</option>
                <option value="product_and_services">Product and Services</option>
                <option value="market_fees">Market Fees, rent or taxes</option>
                <option value="other">Other</option>
              </select>
              <div class="invalid-feedback" v-if="errors.payment_purpose">
                {{ errors.payment_purpose.toString() }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div v-if="selectedProduct !== null">
              <div class="jumbotron mb-3">
                Product: {{ selectedProduct.product.name }} <br>
                Measurement Unit: {{ selectedProduct.measurement_unit.name }} <br>
              </div>
            </div>
            <div v-else>
              <div v-if="Object.keys(products).length > 0">
                <table class="table">
                  <thead>
                  <tr>
                    <th>Product</th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(product,index) in products">
                    <td scope="row">{{ index + 1 }}</td>
                    <td>
                      {{ product.product.name }}
                      <span class="text-muted">
{{ product.measurement_unit.name}}
                      </span>
                      {{ product.grade }}
                    </td>
                    <td>
                      <button class="btn btn-primary" @click.prevent="selectProduct(product)">
                        Select Product
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
          <div class="col-md-6" v-if="selectedProduct !== null">
            <!--Label: Quantity, Attributes:quantity -->
            <div class="form-group">
              <label for="quantity">Quantity</label>
              <input type="text" class="form-control" id="quantity" v-model="quantity"
                     aria-describedby="quantity-help"
                     :class="[errors.quantity ? 'is-invalid': '',!errors.quantity && Object.keys(errors).length > 1 ? 'is-valid': '']"
                     placeholder="Quantity" required>
              <div class="invalid-feedback" v-if="errors.quantity">
                {{ errors.quantity.toString() }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!--Label: Amount, Attributes:amount -->
            <div class="form-group">
              <label for="amount">Amount</label>
              <input type="text" class="form-control" id="amount" v-model="amount"
                     aria-describedby="amount-help"
                     :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                     placeholder="Amount" required>
              <div class="invalid-feedback" v-if="errors.amount">
                {{ errors.amount.toString() }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="alert alert-light" role="alert">

              <div v-if="parseInt(amount) < parseInt(balance)">
                <div class="col-12">
                  <div>
                    <table>
                      <tbody>
                      <tr>
                        <th>Total Amount Due:</th>
                        <td>{{ currency }}{{ parseInt(parseInt(amount) + parseInt((0.025 * amount))).toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <th>Amount Due before fees:</th>
                        <td>{{ currency }}{{ amount }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Market Fees</th>
                      </tr>
                      <tr>
                        <th>Service Fee:</th>
                        <td>
                          {{ currency }}{{ parseInt((0.025 * amount)) }}
                        </td>
                      </tr>
                      <tr>
                        <th>Community Trust:</th>
                        <td>
                          {{ currency }}{{ parseInt((0.001 * amount)) }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-12" v-if="amount > 0">
                  <div>
                    <p>
                      <strong>{{ currency }}{{ amount.toLocaleString() }} </strong> will be deducted from your wallet
                    </p>
                  </div>

                  <div v-if="showOTP">
                    <!--Label: OTP, Attributes:otp -->
                    <div class="form-group mt-5">
                      <label for="otp">OTP</label>
                      <input type="text" class="form-control" id="otp" v-model="otp"
                             aria-describedby="otp-help"
                             :class="[errors.otp ? 'is-invalid': '',!errors.otp && Object.keys(errors).length > 1 ? 'is-valid': '']"
                             placeholder="OTP" required>
                      <div class="invalid-feedback" v-if="errors.otp">
                        {{ errors.otp.toString() }}
                      </div>
                    </div>
                  </div>
                  <div class="text-right float-right" v-if="showOTP">
                    <div>
                      <button class="btn btn-primary" @click="makeOrder">
                        Complete
                      </button>
                    </div>

                  </div>
                  <div class="text-right float-right" v-else>
                    <div>
                      <button class="btn btn-primary" @click="sendOTP">
                        Confirm
                      </button>
                    </div>

                  </div>
                </div>
              </div>
              <div v-if="parseInt(amount) > parseInt(balance) && parseInt(amount) < parseInt(borrowinglimit)">
                <div class="col-12">
                  <!--Label: How many days will you clear payment up to time line, Attributes:timeline -->
                  <div class="form-group">
                    <label for="timeline">How many days will you clear payment up to time line</label>
                    <input type="text" class="form-control" id="timeline" v-model.number="timeline"
                           aria-describedby="timeline-help"
                           :class="[errors.timeline ? 'is-invalid': '',!errors.timeline && Object.keys(errors).length > 1 ? 'is-valid': '']"
                           placeholder="How many days will you clear payment up to time line" required>
                    <div class="invalid-feedback" v-if="errors.timeline">
                      {{ errors.timeline.toString() }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div>
                    <table>
                      <tbody>
                      <tr>
                        <th>Total Amount Due:</th>
                        <td>{{ currency }}{{
                            parseInt(amount) + parseInt((0.025 * amount)) + parseInt(parseInt((0.0015 * amount)) * timeline)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th>Amount Due before fees:</th>
                        <td>{{ currency }}{{ amount }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Market Fees</th>
                      </tr>
                      <tr>
                        <th>Service Fee:</th>
                        <td>
                          {{ currency }}{{ parseInt((0.025 * amount)) }}
                        </td>
                      </tr>
                      <tr>
                        <th>Community Trust:</th>
                        <td>
                          {{ currency }}{{ parseInt((0.001 * amount)) }}
                        </td>
                      </tr>
                      <tr>
                        <th>Daily Fee:</th>
                        <td>
                          {{ currency }}{{ parseInt(parseInt((0.0015 * amount)) * timeline).toFixed(2) }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 mt-5" v-if="amount > 0">
                    <div>
                      <p>
                        <strong>{{ currency }}{{ amount.toLocaleString() }} </strong>
                        amount will be deducted from your account for the payment and you owe
                        of <strong>{{ currency }}{{
                          parseInt(amount) + parseInt((0.025 * amount)) + parseInt(parseInt((0.0015 * amount)) * timeline)
                        }}</strong>
                        by <strong>{{ addDays(timeline) }}</strong>
                      </p>
                    </div>

                    <div class="text-right float-right">
                      <div>
                        <button class="btn btn-primary" @click="sendOTP">
                          Confirm
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div v-if="parseInt(amount) > parseInt(borrowinglimit) && parseInt(amount) > parseInt(balance)">
                <div class="alert alert-danger" role="alert">
                  Amount is above what is your wallet or your purchase credit limit
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>

</style>