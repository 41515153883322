<script setup>
import { ref, watch } from "vue";

let emits = defineEmits([
    "userSelected",
    "productChosen",
    "loggedInUserUpdate",
]);

const props = defineProps(["borrowinglimit_clear", "members", "user_id"]);
const step = ref(false);
const currentPageIs = ref(true);

function gotonextstep() {
    currentPageIs.value = false;
}

const showResults = ref(false);
const skip = ref(false);
const formarketmember = ref(false);
const searching = ref(false);
const search = ref("");
const selectedUser = ref("");
const results = ref("");
const errors = ref({});
const member = ref("");
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

const searchedUser = ref("");

const clickedCount = ref(0);
const showSkipbutton = ref(false);

const metaObject = document.querySelector('meta[name="user_object"]').content;
const userObject = JSON.parse(metaObject);
// watch search and run searchphonenumber
// watch(search, () => {
//     searchPhoneNumber();
// });
const oldPhone = ref("");
function searchPhoneNumber() {
    if (search.value.length < 1) {
        errors.value = {
            search: "Please enter a valid phone number",
        };
        return;
    }
    oldPhone.value = search.value;
    errors.value = [];
    searching.value = true;
    showSkipbutton.value = true;
    axios
        .post("/ajx/search_products", {
            search: search.value,
        })
        .then((res) => {
            searching.value = false;
            searchedUser.value = res.data.user;
            showResults.value = true;
            results.value = res.data.payload;
            console.log(">>: ", res.data);
        });
}

function selectUser(user) {
    selectedUser.value = user;
    emits("userSelected", user);
}

function chooseProduct(product) {
    skip.value = product === "skip";
    if (!skip.value) {
        var myModalEl = document.querySelector("#blacklistedandassortedPopUp");
        var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

        modal.hide();
    }
    if (skip.value) {
        emits("productskipped", true);
        return;
    }
    selectedUser.value = product.member;
    emits("userSelected", product.member);
    emits("productChosen", product);
}

function chooseProductForModal(product) {
    skip.value = false;
    if (oldPhone.value !== search.value) {
        searchPhoneNumber();
        return;
    }
    var myModalEl = document.querySelector("#blacklistedandassortedPopUp");
    var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    modal.toggle();
    if (product === "skip") {
        selectedUser.value = searchedUser.value;
        skip.value = true;
        emits("productChosen", "skip");
        emits("userSelected", searchedUser.value);
        return;
    }
    selectedUser.value = product;
}

function changeUser() {
    emits("loggedInUserUpdate", member.value);
}

function updateSearch(data, product) {
    search.value = data;
    showResults.value = true;
    results.value = {
        products: [product],
    };

    chooseProductForModal(data);

    window.addEventListener("DOMContentLoaded", () => {
        let button = document.getElementById(
            "blacklistedandassortedPopUpButton0"
        );
        button.click();
    });
}

defineExpose({
    updateSearch,
});
</script>

<template>
    <div @keyup.prevent.enter="searchPhoneNumber">
        <div class="col-12">
            <div class="border-3 border p-4 rounded">
                <div class="input-group mb-3">
                    <input
                        type="text"
                        v-model="search"
                        :class="[
                            errors.search ? 'is-invalid' : '',
                            !errors.search && Object.keys(errors).length > 1
                                ? 'is-valid'
                                : '',
                        ]"
                        class="form-control"
                        id="searchforseler"
                        placeholder="Phone Number of Recipient"
                        aria-label="Phone Number of Recipient"
                        aria-describedby="button-addon2"
                    />
                    <button
                        class="btn btn-primary"
                        style="
                            color: white;
                            background-color: #00ab4d;
                            border-color: #00ab4d;
                        "
                        type="button"
                        id="button-addon2"
                        v-if="!showSkipbutton"
                        @click.prevent="searchPhoneNumber"
                    >
                        <strong>Proceed</strong>
                        <i class="fa fa-spin fa-spinner" v-if="searching"></i>
                    </button>
                    <button
                        v-else
                        type="button"
                        class="btn btn-primary"
                        style="
                            color: white;
                            background-color: #00ab4d;
                            border-color: #00ab4d;
                        "
                        @click.prevent="chooseProductForModal('skip')"
                    >
                        Next
                    </button>
                    <div class="invalid-feedback" v-if="errors.search">
                        {{ errors.search.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="showResults">
                <template
                    v-if="searchedUser == null || searchedUser == undefined"
                >
                    <div class="alert alert-danger mt-2" role="alert">
                        <strong
                            >This number is not assigned to a user on the
                            platform, please confirm the phone number</strong
                        >
                    </div>
                </template>
                <template v-else-if="Object.keys(results.products).length < 1">
                    <div
                        class="alert mt-2"
                        role="alert"
                        :style="{
                            color: '#ff7900',
                            border: '1px solid #ff7900',
                        }"
                    >
                        This user has not added a product. If you still want to
                        pay them, click Proceed
                    </div>
                    <button
                        :style="{
                            color: '#ff7900 !important',
                        }"
                        class="btn warn-button-border"
                        @click.prevent="chooseProduct('skip')"
                    >
                        Proceed
                    </button>
                </template>
                <template v-else>
                    <div
                        class="row m-1 d-none d-sm-none d-xs-none d-md-block"
                        v-for="result in results.products"
                        :key="result.products"
                    >
                        <div
                            class="d-flex flex-row products-services-card py-2 px-4 my-2"
                        >
                            <div class="col-2 d-flex align-items-center">
                                <img
                                    width="100"
                                    :src="result.product_image_paths"
                                />
                            </div>
                            <div class="d-flex flex-column col-8">
                                <div>
                                    <strong>{{ result.product.name }}</strong>
                                    {{ result.measurement_unit.name }}
                                </div>
                                <div>
                                    <strong>Listed by</strong>:
                                    {{ result.member?.name }}
                                    <span
                                        v-if="
                                            typeof result.member?.verifications
                                                ?.metadata?.failed ===
                                                'object' &&
                                            Object.keys(
                                                result.member?.verifications
                                                    ?.metadata?.failed
                                            ).length > 0
                                        "
                                    >
                                        <i
                                            class="fa-solid fa-face-angry fa-lg"
                                            style="color: #6c757d"
                                        ></i>
                                    </span>
                                    <span v-if="!result.member?.verifications">
                                        <i
                                            class="fa-solid fa-face-meh-blank fa-lg"
                                            style="color: #6c757d"
                                        ></i>
                                    </span>
                                    <span
                                        v-if="
                                            typeof result.member?.verifications
                                                ?.metadata?.failed ===
                                                'object' &&
                                            Object.keys(
                                                result.member?.verifications
                                                    ?.metadata?.failed
                                            ).length < 0
                                        "
                                    >
                                        <i
                                            class="fa-solid fa-face-smile fa-lg"
                                            style="color: #6c757d"
                                        ></i>
                                    </span>
                                    in {{ result.market?.name }} |
                                    {{ result.updated_at }}
                                </div>
                                <br />
                                <div>
                                    <strong
                                        >Last Sold at
                                        {{ result?.market?.name }}</strong
                                    >: {{ currency }}{{ result.last_sold_at }}:
                                    1 x{{ result.measurement_unit.name }} |
                                    {{ result.updated_at }}
                                </div>
                                <div>
                                    <strong
                                        >{{ result?.market?.name }} Recommended
                                        Price</strong
                                    >: {{ currency }}{{ result.last_sold_at }}:
                                    1 x{{ result.measurement_unit.name }} |
                                    {{ result.updated_at }}
                                </div>
                            </div>
                            <div class="d-flex flex-column col-2">
                                <div>
                                    {{ currency
                                    }}{{ result.amount.toLocaleString() }}
                                </div>
                                <div
                                    style="cursor: pointer"
                                    class="success-text"
                                    id="`blacklistedandassortedPopUpButton${index}`"
                                    @click="chooseProductForModal(result)"
                                    data-bs-toggle="modal"
                                    data-bs-target="#blacklistedandassortedPopUp"
                                >
                                    <strong>Buy</strong>
                                </div>
                            </div>
                        </div>

                        <div
                            class="offcanvas offcanvas-end"
                            tabindex="-1"
                            id="offcanvasRight"
                            aria-labelledby="offcanvasRightLabel"
                        >
                            <div class="offcanvas-header">
                                <h5 id="offcanvasRightLabel">
                                    Update
                                    <strong>{{
                                        product?.product?.name
                                    }}</strong>
                                    Price
                                </h5>
                                <button
                                    type="button"
                                    class="btn-close text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div class="offcanvas-body">
                                <div
                                    class="card border-dark mb-5"
                                    v-if="is_member === 1"
                                >
                                    <div class="card-header">
                                        Update <strong>Product</strong> Price
                                    </div>
                                    <div class="card-body text-dark">
                                        <form class="form-floating">
                                            <input
                                                type="number"
                                                :class="[
                                                    errors.price
                                                        ? 'is-invalid'
                                                        : '',
                                                    !errors.price &&
                                                    Object.keys(errors).length >
                                                        1
                                                        ? 'is-valid'
                                                        : '',
                                                ]"
                                                class="form-control"
                                                id="floatingInputValue"
                                                v-model="price"
                                            />
                                            <label for="floatingInputValue">
                                                Price
                                                {{ currency }}
                                            </label>
                                            <div
                                                class="invalid-feedback"
                                                v-if="errors.price"
                                            >
                                                {{ errors.price.toString() }}
                                            </div>
                                        </form>
                                        <div
                                            class="mt-2 d-flex justify-content-end"
                                        >
                                            <button
                                                id="updatePrice"
                                                class="btn btn-dark btn-sm"
                                                @click.prevent="
                                                    updatePrice('self')
                                                "
                                            >
                                                Update Price
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="is_member === 0">
                                    <div
                                        class="alert alert-success mt-5"
                                        role="alert"
                                    >
                                        <!--              <h4 class="alert-heading">Well done!</h4>-->
                                        <p>
                                            You are a market leader and can
                                            update this products price in the
                                            market.
                                        </p>
                                        <hr />
                                    </div>
                                    <div class="card border-dark mb-3">
                                        <div class="card-header">
                                            Update <strong>Market</strong> Price
                                        </div>
                                        <div class="card-body text-dark">
                                            <form class="form-floating">
                                                <input
                                                    type="number"
                                                    :class="[
                                                        errors.price
                                                            ? 'is-invalid'
                                                            : '',
                                                        !errors.price &&
                                                        Object.keys(errors)
                                                            .length > 1
                                                            ? 'is-valid'
                                                            : '',
                                                    ]"
                                                    class="form-control"
                                                    id="floatingInputValue"
                                                    v-model="price"
                                                />
                                                <label for="floatingInputValue">
                                                    Price
                                                    {{ currency }}
                                                </label>
                                                <div
                                                    class="invalid-feedback"
                                                    v-if="errors.price"
                                                >
                                                    {{
                                                        errors.price.toString()
                                                    }}
                                                </div>
                                            </form>
                                            <div
                                                class="mt-2 d-flex justify-content-end"
                                            >
                                                <button
                                                    class="btn btn-outline-dark btn-sm"
                                                    @click.prevent="
                                                        updatePrice('market')
                                                    "
                                                >
                                                    Update Market Price
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div
                            class="offcanvas offcanvas-end"
                            tabindex="-1"
                            id="makePayment"
                            aria-labelledby="makePaymentLabel"
                        >
                            <div class="offcanvas-header">
                                <h5 id="makePaymentLabel">
                                    Make payment for
                                    <strong>{{
                                        product?.product?.name
                                    }}</strong>
                                    Price
                                </h5>
                                <button
                                    type="button"
                                    class="btn-close text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div class="offcanvas-body">
                                <form action="">
                                    <div class="row g-3">
                                        <div class="col-md-12">
                                            <!--Label: Amount, Attributes:amount -->
                                            <div class="form-group">
                                                <label for="amount"
                                                    >Amount</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="amount"
                                                    v-model="amount"
                                                    aria-describedby="amount-help"
                                                    :class="[
                                                        errors.amount
                                                            ? 'is-invalid'
                                                            : '',
                                                        !errors.amount &&
                                                        Object.keys(errors)
                                                            .length > 1
                                                            ? 'is-valid'
                                                            : '',
                                                    ]"
                                                    placeholder="Amount"
                                                    required
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-if="errors.amount"
                                                >
                                                    {{
                                                        errors.amount.toString()
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <!--Label: Quantity, Attributes:quantity -->
                                            <div class="form-group">
                                                <label for="quantity"
                                                    >Quantity</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="quantity"
                                                    v-model="quantity"
                                                    aria-describedby="quantity-help"
                                                    :class="[
                                                        errors.quantity
                                                            ? 'is-invalid'
                                                            : '',
                                                        !errors.quantity &&
                                                        Object.keys(errors)
                                                            .length > 1
                                                            ? 'is-valid'
                                                            : '',
                                                    ]"
                                                    placeholder="Quantity"
                                                    required
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-if="errors.quantity"
                                                >
                                                    {{
                                                        errors.quantity.toString()
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--            Card for Mobile-->
                    <div
                        class="row m-1"
                        v-for="result in results.products"
                        :key="result.products"
                    >
                        <div
                            class="products-services-card py-2 px-4 my-2 d-md-none d-lg-none d-xl-none"
                        >
                            <div class="col-md-2">
                                <img
                                    width="100"
                                    :src="result.product_image_paths"
                                />
                            </div>
                            <div class="col-md-8">
                                <div>
                                    <strong>{{ result.product.name }}</strong>
                                    {{ result.measurement_unit.name }}
                                </div>
                                <div>
                                    <strong>Listed by</strong>:
                                    {{ result.member?.name }}

                                    <span
                                        v-if="
                                            result.member?.verification ===
                                            'verified'
                                        "
                                    >
                                        <i
                                            class="fa-solid fa-face-angry fa-lg"
                                            style="color: #6c757d"
                                        ></i>
                                    </span>
                                    <span
                                        v-if="
                                            result.member?.verification ===
                                            'verified'
                                        "
                                    >
                                        <i
                                            class="fa-solid fa-face-meh-blank fa-lg"
                                            style="color: #6c757d"
                                        ></i>
                                    </span>
                                    <span
                                        v-if="
                                            result.member?.verification ===
                                            'verified'
                                        "
                                    >
                                        <i
                                            class="fa-solid fa-face-smile fa-lg"
                                            style="color: #6c757d"
                                        ></i> </span
                                    >in {{ result.market?.name }} |
                                    {{ result.updated_at }}
                                </div>
                                <br />
                                <div>
                                    <strong
                                        >Last Sold at
                                        {{ result?.market?.name }}</strong
                                    >: {{ currency }}{{ result.last_sold_at }}:
                                    1 x{{ result.measurement_unit.name }} |
                                    {{ result.updated_at }}
                                </div>
                                <div>
                                    <strong
                                        >{{ result?.market?.name }} Recommended
                                        Price</strong
                                    >: {{ currency }}{{ result.last_sold_at }}:
                                    1 x{{ result.measurement_unit.name }} |
                                    {{ result.updated_at }}
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="strong leading-3">
                                    <strong>
                                        {{ currency
                                        }}{{
                                            result.amount.toLocaleString()
                                        }}</strong
                                    >
                                </div>
                                <div
                                    style="cursor: pointer"
                                    class="success-text text-center"
                                    id="`blacklistedandassortedPopUpButton${index}`"
                                    @click="chooseProductForModal(result)"
                                    data-bs-toggle="modal"
                                    data-bs-target="#blacklistedandassortedPopUp"
                                >
                                    <strong>Buy</strong>
                                </div>
                            </div>
                        </div>

                        <div
                            class="offcanvas offcanvas-end"
                            tabindex="-1"
                            id="offcanvasRight"
                            aria-labelledby="offcanvasRightLabel"
                        >
                            <div class="offcanvas-header">
                                <h5 id="offcanvasRightLabel">
                                    Update
                                    <strong>{{
                                        product?.product?.name
                                    }}</strong>
                                    Price
                                </h5>
                                <button
                                    type="button"
                                    class="btn-close text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div class="offcanvas-body">
                                <div
                                    class="card border-dark mb-5"
                                    v-if="is_member === 1"
                                >
                                    <div class="card-header">
                                        Update <strong>Product</strong> Price
                                    </div>
                                    <div class="card-body text-dark">
                                        <form class="form-floating">
                                            <input
                                                type="number"
                                                :class="[
                                                    errors.price
                                                        ? 'is-invalid'
                                                        : '',
                                                    !errors.price &&
                                                    Object.keys(errors).length >
                                                        1
                                                        ? 'is-valid'
                                                        : '',
                                                ]"
                                                class="form-control"
                                                id="floatingInputValue"
                                                v-model="price"
                                            />
                                            <label for="floatingInputValue">
                                                Price
                                                {{ currency }}
                                            </label>
                                            <div
                                                class="invalid-feedback"
                                                v-if="errors.price"
                                            >
                                                {{ errors.price.toString() }}
                                            </div>
                                        </form>
                                        <div
                                            class="mt-2 d-flex justify-content-end"
                                        >
                                            <button
                                                id="updatePrice"
                                                class="btn btn-dark btn-sm"
                                                @click.prevent="
                                                    updatePrice('self')
                                                "
                                            >
                                                Update Price
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="is_member === 0">
                                    <div
                                        class="alert alert-success mt-5"
                                        role="alert"
                                    >
                                        <!--              <h4 class="alert-heading">Well done!</h4>-->
                                        <p>
                                            You are a market leader and can
                                            update this products price in the
                                            market.
                                        </p>
                                        <hr />
                                    </div>
                                    <div class="card border-dark mb-3">
                                        <div class="card-header">
                                            Update <strong>Market</strong> Price
                                        </div>
                                        <div class="card-body text-dark">
                                            <form class="form-floating">
                                                <input
                                                    type="number"
                                                    :class="[
                                                        errors.price
                                                            ? 'is-invalid'
                                                            : '',
                                                        !errors.price &&
                                                        Object.keys(errors)
                                                            .length > 1
                                                            ? 'is-valid'
                                                            : '',
                                                    ]"
                                                    class="form-control"
                                                    id="floatingInputValue"
                                                    v-model="price"
                                                />
                                                <label for="floatingInputValue">
                                                    Price
                                                    {{ currency }}
                                                </label>
                                                <div
                                                    class="invalid-feedback"
                                                    v-if="errors.price"
                                                >
                                                    {{
                                                        errors.price.toString()
                                                    }}
                                                </div>
                                            </form>
                                            <div
                                                class="mt-2 d-flex justify-content-end"
                                            >
                                                <button
                                                    class="btn btn-outline-dark btn-sm"
                                                    @click.prevent="
                                                        updatePrice('market')
                                                    "
                                                >
                                                    Update Market Price
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div
                            class="offcanvas offcanvas-end"
                            tabindex="-1"
                            id="makePayment"
                            aria-labelledby="makePaymentLabel"
                        >
                            <div class="offcanvas-header">
                                <h5 id="makePaymentLabel">
                                    Make payment for
                                    <strong>{{
                                        product?.product?.name
                                    }}</strong>
                                    Price
                                </h5>
                                <button
                                    type="button"
                                    class="btn-close text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div class="offcanvas-body">
                                <form action="">
                                    <div class="row g-3">
                                        <div class="col-md-12">
                                            <!--Label: Amount, Attributes:amount -->
                                            <div class="form-group">
                                                <label for="amount"
                                                    >Amount</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="amount"
                                                    v-model="amount"
                                                    aria-describedby="amount-help"
                                                    :class="[
                                                        errors.amount
                                                            ? 'is-invalid'
                                                            : '',
                                                        !errors.amount &&
                                                        Object.keys(errors)
                                                            .length > 1
                                                            ? 'is-valid'
                                                            : '',
                                                    ]"
                                                    placeholder="Amount"
                                                    required
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-if="errors.amount"
                                                >
                                                    {{
                                                        errors.amount.toString()
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <!--Label: Quantity, Attributes:quantity -->
                                            <div class="form-group">
                                                <label for="quantity"
                                                    >Quantity</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="quantity"
                                                    v-model="quantity"
                                                    aria-describedby="quantity-help"
                                                    :class="[
                                                        errors.quantity
                                                            ? 'is-invalid'
                                                            : '',
                                                        !errors.quantity &&
                                                        Object.keys(errors)
                                                            .length > 1
                                                            ? 'is-valid'
                                                            : '',
                                                    ]"
                                                    placeholder="Quantity"
                                                    required
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    v-if="errors.quantity"
                                                >
                                                    {{
                                                        errors.quantity.toString()
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="">
                        <div class="col-12 d-flex justify-content-between mt-3">
                            <!--                                  <div>-->
                            <!--                                    <p>Choose a Product and Service to pay for</p>-->
                            <!--                                  </div>-->
                            <div></div>
                        </div>

                        <hr />
                        <!--                                <table class="table table-bordered table-striped table-sm mt-4" id="search">-->
                        <!--                                  <thead>-->
                        <!--                                  <tr>-->
                        <!--                                    <th>Product</th>-->
                        <!--                                    <th>Last Sold Price (Market)</th>-->
                        <!--                                    <th>Market Recommended Price</th>-->
                        <!--                                    <th>Sellers Price</th>-->
                        <!--                                  </tr>-->
                        <!--                                  </thead>-->
                        <!--                                  <tbody>-->
                        <!--                                  <tr v-for="(result,index) in results.products">-->
                        <!--                                    <td class="align-middle">-->
                        <!--                                      <div class="d-flex align-content-center">-->
                        <!--                                        <div class="mx-3">-->
                        <!--                                          <p class="m-0"> {{ result.product.name }}</p>-->
                        <!--                                          <p class="m-0 text-muted">-->
                        <!--                                            {{ result.measurement_unit.name }}-->
                        <!--                                          </p>-->
                        <!--                                          <span class="badge bg-secondary text-dark text-white"-->
                        <!--                                                v-if="user_id === result.member_id"-->
                        <!--                                          >-->
                        <!--                                        My Product-->
                        <!--                                      </span>-->
                        <!--                                          <template v-else>-->
                        <!--                                            <div>-->
                        <!--                                              <span class="badge bg-secondary text-dark text-white">-->
                        <!--                                                {{ result.member.name }}-->
                        <!--                                              </span>-->
                        <!--                                            </div>-->
                        <!--                                            <div>-->
                        <!--                                              <span class="badge bg-secondary text-dark text-white">-->
                        <!--                                              {{ result.market.name }} Market-->
                        <!--                                              </span>-->
                        <!--                                            </div>-->
                        <!--                                          </template>-->
                        <!--                                        </div>-->
                        <!--                                      </div>-->
                        <!--                                    </td>-->
                        <!--                                    <td class="align-middle">-->
                        <!--                                      <div class="mx-3 mt-3">-->
                        <!--                                        <template v-if="result.last_sold_at">-->
                        <!--                                          {{ currency }}{{ parseFloat(result.last_sold_at).toLocaleString() }} <br>-->
                        <!--                                          <span class="text-muted"> for <strong>{{ result.quantity }}</strong>x{{-->
                        <!--                                              result.measurement_unit.name-->
                        <!--                                            }}</span>-->
                        <!--                                        </template>-->
                        <!--                                        <template v-else>-->
                        <!--                                          <small class="text-muted">-->
                        <!--                                            Not Available.-->
                        <!--                                          </small>-->
                        <!--                                        </template>-->
                        <!--                                      </div>-->
                        <!--                                      <template v-if="result.last_sold_at" class="mt-0">-->
                        <!--                                        <div>-->

                        <!--                                          <small>-->
                        <!--                                            <p class="mx-3 m-0 text-muted">-->
                        <!--                                              Sold at: <strong>{{ result.last_sold_at_date }}</strong>-->
                        <!--                                            </p>-->
                        <!--                                          </small>-->
                        <!--                                        </div>-->
                        <!--                                      </template>-->

                        <!--                                    </td>-->
                        <!--                                    <td class="align-middle">-->
                        <!--                                      <template v-if="result.market_price">-->
                        <!--                                        <div class="mx-3">-->
                        <!--                                          {{ currency }}{{ parseFloat(result.market_price).toLocaleString() }} <br>-->
                        <!--                                          <span class="text-muted"> for <strong>1</strong>x{{-->
                        <!--                                              result.measurement_unit.name-->
                        <!--                                            }}</span>-->
                        <!--                                        </div>-->
                        <!--                                      </template>-->
                        <!--                                      <template v-else>-->
                        <!--                                        <small class="text-muted my-2 mx-3 ml-3">-->
                        <!--                                          Not Available.-->
                        <!--                                        </small>-->
                        <!--                                      </template>-->
                        <!--                                      <div class="mx-3" v-if="result.market_price">-->
                        <!--                                        <small>-->
                        <!--                                          <p class="m-0 text-muted">-->
                        <!--                                            Last updated at:-->
                        <!--                                            <strong>-->
                        <!--                                              <em>-->
                        <!--                                                {{ result.market_price_updated_at }}-->
                        <!--                                              </em>-->
                        <!--                                            </strong>-->
                        <!--                                          </p>-->
                        <!--                                        </small>-->
                        <!--                                      </div>-->
                        <!--                                    </td>-->
                        <!--                                    <td>-->
                        <!--                                      {{ currency }}{{ result.amount.toLocaleString() }} <br>-->
                        <!--                                      <span class="text-muted"> for <strong>1</strong>x{{-->
                        <!--                                          result.measurement_unit.name-->
                        <!--                                        }}</span>-->
                        <!--                                      <small>-->
                        <!--                                        <p class="m-0 text-muted">-->
                        <!--                                          Last updated at:-->
                        <!--                                          <strong>-->
                        <!--                                            <em>-->
                        <!--                                              {{ result.updated_at }}-->
                        <!--                                            </em>-->
                        <!--                                          </strong>-->
                        <!--                                        </p>-->
                        <!--                                      </small>-->

                        <!--                                    </td>-->
                        <!--                                    <td>-->

                        <!--                                      <button type="button" class="btn btn-secondary btn-sm"-->
                        <!--                                              :id="`blacklistedandassortedPopUpButton${index}`"-->
                        <!--                                              @click="chooseProductForModal(result)"-->
                        <!--                                              data-bs-toggle="modal"-->
                        <!--                                              data-bs-target="#blacklistedandassortedPopUp">-->
                        <!--                                        Buy-->
                        <!--                                        <i class="fa fa-cart-plus"></i>-->
                        <!--                                      </button>-->
                        <!--                                    </td>-->
                        <!--                                  </tr>-->
                        <!--                                  </tbody>-->
                        <!--                                </table>-->

                        <div
                            class="modal fade"
                            id="blacklistedandassortedPopUp"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="blacklistedandassortedPopUp"
                            aria-hidden="true"
                        >
                            <div class="modal-dialog modal-md" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="sub-header-font">
                                            Proceed with Purchase
                                        </h4>
                                        <button
                                            type="button"
                                            class="btn-close warn-text"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div class="modal-body">
                                        <template v-if="skip">
                                            <template v-if="currentPageIs">
                                                <div
                                                    v-if="
                                                        selectedUser?.blacklisted
                                                    "
                                                >
                                                    <div
                                                        class="alert alert-danger"
                                                        role="alert"
                                                    >
                                                        <h4
                                                            class="sub-header-font"
                                                        >
                                                            Suspended or
                                                            blacklisted seller:
                                                        </h4>
                                                        <p>
                                                            You cannot make a
                                                            purchase from this
                                                            seller. - This
                                                            seller was
                                                            blacklisted/suspended
                                                            by x market.
                                                        </p>
                                                        <hr />
                                                        <p class="mb-0"></p>
                                                    </div>
                                                </div>
                                                <div
                                                    v-else-if="
                                                        selectedUser.member
                                                            ?.credit_score < 100
                                                    "
                                                >
                                                    <div
                                                        class="alert alert-warning"
                                                        role="alert"
                                                    >
                                                        <h4
                                                            class="sub-header-font"
                                                        >
                                                            Unverified seller
                                                        </h4>
                                                        <p>
                                                            This is an
                                                            unverified seller,
                                                            we recommend you pay
                                                            at pick-up of goods
                                                            or service. Note
                                                            that you will be
                                                            taking full
                                                            responsibility for
                                                            this purchase.
                                                        </p>
                                                        <hr />
                                                        <p class="mb-0"></p>
                                                    </div>
                                                </div>
                                                <div
                                                    v-else-if="
                                                        selectedUser?.credit_score >=
                                                        100
                                                    "
                                                >
                                                    <div
                                                        class="alert alert-warning"
                                                        role="alert"
                                                    >
                                                        <h4
                                                            class="sub-header-font"
                                                        >
                                                            Verified seller
                                                        </h4>
                                                        <p>
                                                            Based on our
                                                            assessment, this
                                                            seller can supply
                                                            good(s) up to
                                                            {{ currency
                                                            }}{{
                                                                results.bnpl_balance.toLocaleString()
                                                            }}
                                                        </p>
                                                        <hr />
                                                        <p class="mb-0"></p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="alert alert-warning"
                                                    role="alert"
                                                    v-else
                                                >
                                                    <h4 class="sub-header-font">
                                                        Unverified seller
                                                    </h4>
                                                    <p>
                                                        This is an unverified
                                                        seller, we recommend you
                                                        pay at pick-up of goods
                                                        or service. Note that
                                                        you will be taking full
                                                        responsibility for this
                                                        purchase.
                                                    </p>
                                                    <hr />
                                                    <p class="mb-0"></p>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div>
                                                    <div
                                                        class="alert alert-success"
                                                        role="alert"
                                                    >
                                                        <strong
                                                            >You are a market
                                                            leader and you can
                                                            purchase goods and
                                                            services on behalf
                                                            of your
                                                            users.</strong
                                                        >

                                                        <div class="row mt-3">
                                                            <div
                                                                class="col-md-6"
                                                            >
                                                                <a href="">
                                                                    <div
                                                                        class="card"
                                                                        :style="
                                                                            !formarketmember
                                                                                ? {
                                                                                      backgroundColor:
                                                                                          '#00AB4D',
                                                                                      color: 'white',
                                                                                  }
                                                                                : ''
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="card-body"
                                                                            @click.prevent="
                                                                                formarketmember = false
                                                                            "
                                                                        >
                                                                            For
                                                                            you
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                class="col-md-6"
                                                            >
                                                                <a
                                                                    href=""
                                                                    @click.prevent="
                                                                        formarketmember = true
                                                                    "
                                                                >
                                                                    <div
                                                                        class="card"
                                                                        :style="
                                                                            formarketmember
                                                                                ? {
                                                                                      backgroundColor:
                                                                                          '#00AB4D',
                                                                                      color: 'white',
                                                                                  }
                                                                                : ''
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="card-body"
                                                                        >
                                                                            For
                                                                            Market
                                                                            Member
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                class="col-md-6"
                                                            ></div>
                                                        </div>
                                                        <div
                                                            class="row g-3"
                                                            v-if="
                                                                formarketmember
                                                            "
                                                        >
                                                            <form action="">
                                                                <div
                                                                    class="col-md-12 mt-3"
                                                                >
                                                                    <!--Label: Member, Attributes:member -->
                                                                    <div
                                                                        class="form-floating"
                                                                    >
                                                                        <select
                                                                            class="form-select"
                                                                            id="member"
                                                                            v-model="
                                                                                member
                                                                            "
                                                                            aria-describedby="member-help"
                                                                            @change="
                                                                                changeUser
                                                                            "
                                                                            :class="[
                                                                                errors.member
                                                                                    ? 'is-invalid'
                                                                                    : '',
                                                                                !errors.member &&
                                                                                Object.keys(
                                                                                    errors
                                                                                )
                                                                                    .length >
                                                                                    1
                                                                                    ? 'is-valid'
                                                                                    : '',
                                                                            ]"
                                                                        >
                                                                            <option
                                                                                :value="
                                                                                    member.id
                                                                                "
                                                                                v-for="member in props.members"
                                                                                :key="
                                                                                    member.id
                                                                                "
                                                                            >
                                                                                {{
                                                                                    member.name
                                                                                }}
                                                                                -
                                                                                <span
                                                                                    class="text-muted"
                                                                                    >{{
                                                                                        member.phone
                                                                                    }}</span
                                                                                >
                                                                            </option>
                                                                        </select>
                                                                        <label
                                                                            for="member"
                                                                            >Member</label
                                                                        >

                                                                        <div
                                                                            class="invalid-feedback"
                                                                            v-if="
                                                                                errors.member
                                                                            "
                                                                        >
                                                                            {{
                                                                                errors.member.toString()
                                                                            }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-if="currentPageIs">
                                                <div
                                                    v-if="
                                                        selectedUser?.blacklisted
                                                    "
                                                >
                                                    <div
                                                        class="alert alert-danger"
                                                        role="alert"
                                                    >
                                                        <h4
                                                            class="sub-header-font"
                                                        >
                                                            Suspended or
                                                            blacklisted seller:
                                                        </h4>
                                                        <p>
                                                            You cannot make a
                                                            purchase from this
                                                            seller. - This
                                                            seller was
                                                            blacklisted/suspended
                                                            by x market.
                                                        </p>
                                                        <hr />
                                                        <p class="mb-0"></p>
                                                    </div>
                                                </div>
                                                <div
                                                    v-else-if="
                                                        selectedUser.member
                                                            ?.credit_score < 100
                                                    "
                                                >
                                                    <div
                                                        class="alert alert-warning"
                                                        role="alert"
                                                    >
                                                        <h4
                                                            class="sub-header-font"
                                                        >
                                                            Unverified seller
                                                        </h4>
                                                        <p>
                                                            This is an
                                                            unverified seller,
                                                            we recommend you pay
                                                            at pick-up of goods
                                                            or service. Note
                                                            that you will be
                                                            taking full
                                                            responsibility for
                                                            this purchase.
                                                        </p>
                                                        <hr />
                                                        <p class="mb-0"></p>
                                                    </div>
                                                </div>
                                                <div
                                                    v-else-if="
                                                        selectedUser.member
                                                            ?.credit_score >=
                                                        100
                                                    "
                                                >
                                                    <div
                                                        class="alert alert-warning"
                                                        role="alert"
                                                    >
                                                        <h4
                                                            class="sub-header-font"
                                                        >
                                                            Verified seller
                                                        </h4>
                                                        <p>
                                                            Based on our
                                                            assessment, this
                                                            seller can supplier
                                                            good up to
                                                            {{ currency
                                                            }}{{
                                                                results.bnpl_balance.toLocaleString()
                                                            }}
                                                        </p>
                                                        <hr />
                                                        <p class="mb-0"></p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="alert alert-warning"
                                                    role="alert"
                                                    v-else
                                                >
                                                    <h4 class="sub-header-font">
                                                        Unverified seller
                                                    </h4>
                                                    <p>
                                                        This is an unverified
                                                        seller, we recommend you
                                                        pay at pick-up of goods
                                                        or service. Note that
                                                        you will be taking full
                                                        responsibility for this
                                                        purchase.
                                                    </p>
                                                    <hr />
                                                    <p class="mb-0"></p>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div>
                                                    <div
                                                        class="alert alert-success"
                                                        role="alert"
                                                    >
                                                        <strong
                                                            >You are a market
                                                            leader and you can
                                                            purchase goods and
                                                            services on behalf
                                                            of your
                                                            users.</strong
                                                        >

                                                        <div class="row mt-3">
                                                            <div
                                                                class="col-md-6"
                                                            >
                                                                <a href="">
                                                                    <div
                                                                        class="card"
                                                                    >
                                                                        <div
                                                                            class="card-body text-black"
                                                                            :class="{
                                                                                'active-card-purchase text-white':
                                                                                    !formarketmember,
                                                                            }"
                                                                            @click.prevent="
                                                                                formarketmember = false
                                                                            "
                                                                        >
                                                                            For
                                                                            you
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                class="col-md-6"
                                                            >
                                                                <a
                                                                    href=""
                                                                    @click.prevent="
                                                                        formarketmember = true
                                                                    "
                                                                >
                                                                    <div
                                                                        class="card"
                                                                    >
                                                                        <div
                                                                            class="card-body"
                                                                            :class="{
                                                                                'active-card-purchase text-white':
                                                                                    formarketmember,
                                                                            }"
                                                                        >
                                                                            For
                                                                            Market
                                                                            Member
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div
                                                                class="col-md-6"
                                                            ></div>
                                                        </div>
                                                        <div
                                                            class="row g-3"
                                                            v-if="
                                                                formarketmember
                                                            "
                                                        >
                                                            <form action="">
                                                                <div
                                                                    class="col-md-12 mt-3"
                                                                >
                                                                    <!--Label: Member, Attributes:member -->
                                                                    <div
                                                                        class="form-floating"
                                                                    >
                                                                        <select
                                                                            class="form-select"
                                                                            id="member"
                                                                            v-model="
                                                                                member
                                                                            "
                                                                            aria-describedby="member-help"
                                                                            @change="
                                                                                changeUser
                                                                            "
                                                                            :class="[
                                                                                errors.member
                                                                                    ? 'is-invalid'
                                                                                    : '',
                                                                                !errors.member &&
                                                                                Object.keys(
                                                                                    errors
                                                                                )
                                                                                    .length >
                                                                                    1
                                                                                    ? 'is-valid'
                                                                                    : '',
                                                                            ]"
                                                                        >
                                                                            <option
                                                                                :value="
                                                                                    member.id
                                                                                "
                                                                                v-for="member in props.members"
                                                                                :key="
                                                                                    member.id
                                                                                "
                                                                            >
                                                                                {{
                                                                                    member.name
                                                                                }}
                                                                                -
                                                                                <span
                                                                                    class="text-muted"
                                                                                    >{{
                                                                                        member.phone
                                                                                    }}</span
                                                                                >
                                                                            </option>
                                                                        </select>
                                                                        <label
                                                                            for="member"
                                                                            >Member</label
                                                                        >

                                                                        <div
                                                                            class="invalid-feedback"
                                                                            v-if="
                                                                                errors.member
                                                                            "
                                                                        >
                                                                            {{
                                                                                errors.member.toString()
                                                                            }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                    <div
                                        class="modal-footer d-flex justify-content-between"
                                    >
                                        <div>
                                            <button
                                                type="button"
                                                class="btn primary-button-coamana text-white"
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                        </div>
                                        <div class="">
                                            <div>
                                                <template v-if="skip">
                                                    <template
                                                        v-if="
                                                            results?.blacklisted
                                                        "
                                                    ></template>
                                                    <template v-else>
                                                        <template
                                                            v-if="currentPageIs"
                                                        >
                                                            <button
                                                                type="button"
                                                                id="proceedButton"
                                                                @click.prevent="
                                                                    gotonextstep
                                                                "
                                                                class="btn text-white add-button-coamana"
                                                            >
                                                                Proceed
                                                                <i
                                                                    class="fa fa-step-forward"
                                                                ></i>
                                                            </button>
                                                        </template>
                                                        <template v-else>
                                                            <button
                                                                type="button"
                                                                id="proceedButton"
                                                                @click.prevent="
                                                                    chooseProduct(
                                                                        'skip'
                                                                    )
                                                                "
                                                                class="btn text-white add-button-coamana"
                                                            >
                                                                Proceed
                                                                <i
                                                                    class="fa fa-step-forward"
                                                                ></i>
                                                            </button>
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template
                                                        v-if="
                                                            selectedUser?.blacklisted
                                                        "
                                                    ></template>
                                                    <template v-else>
                                                        <template
                                                            v-if="currentPageIs"
                                                        >
                                                            <button
                                                                type="button"
                                                                @click.prevent="
                                                                    gotonextstep
                                                                "
                                                                class="btn text-white add-button-coamana"
                                                            >
                                                                Proceed
                                                                <i
                                                                    class="fa fa-step-forward"
                                                                ></i>
                                                            </button>
                                                        </template>
                                                        <template v-else>
                                                            <button
                                                                type="button"
                                                                :disabled="
                                                                    formarketmember &&
                                                                    !member
                                                                "
                                                                id="proceedButtonForward"
                                                                @click.prevent="
                                                                    chooseProduct(
                                                                        selectedUser
                                                                    )
                                                                "
                                                                class="btn text-white add-button-coamana"
                                                            >
                                                                Proceed
                                                                <i
                                                                    class="fa fa-ban"
                                                                    v-if="
                                                                        formarketmember &&
                                                                        !member
                                                                    "
                                                                ></i>
                                                                <i
                                                                    class="fa fa-step-forward"
                                                                    v-else
                                                                ></i>
                                                            </button>
                                                        </template>
                                                    </template>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
