<script setup>
import { computed, ref, onMounted } from "vue";
import _ from 'lodash'
import ResendOTP from "../../ResendOTP.vue";

const props = defineProps(['banks', 'balance', 'market_id']);

const bank = ref('');
const account_number = ref('');
const amount = ref('');
const otp = ref('');
const errors = ref({});
const success = ref(null);

const showOTPScreen = ref(false);
const withdrawing = ref(false);
const childRef = ref();


const setChildRef = (el) => {
    childRef.value = el;
};

let user = JSON.parse(document.querySelector('meta[name="user"]').content);

function getBank(code) {
    return _.find(props.banks, function (bank) {
        return bank.code === code;
    });
}

function isInValidAmount() {
    return amount.value < 1 || props.balance < amount.value;
}

function goBack() {
    childRef.value.stopTimer();
    otp.value = '';
    withdrawing.value = false;
    showOTPScreen.value = false;
    errors.value = {};
}

function validateAmount() {
    const message = amount.value > props.balance
        ? `Amount entered is greater than the current balance of ${props.balance}`
        : 'Invalid amount'
    errors.value.amount = isInValidAmount(amount.value, props.balance) ? message : '';
}

const isValidForm = computed(() => !isInValidAmount() && bank.value !== '' && account_number.value !== '');

function sendOTP() {
    showOTPScreen.value = true;

    axios.post('/apis/v4/otp', {
        'user_id': user.id,
        'key': 'withdrawFromMarketWallet'
    }).then(() => {
        childRef.value.startTimer();
    });
}

function withDraw() {
    withdrawing.value = true;

    axios.post('/apis/v4/withdraw', {
        'withdrawFrom': 'marketWallet',
        'key': 'withdrawFromMarketWallet',
        'otp': otp.value,
        'amount': amount.value,
        'bank': bank.value,
        'account_number': account_number.value,
        'market_id': props.market_id,
        'user_id': user.id
    }).then(resp => {
        withdrawing.value = false
        success.value = resp.data.message;
        setTimeout(function(){
            window.location.reload();
        }, 5000);
    }).catch(err => {
        withdrawing.value = false
        errors.value = err.response.data.errors;
    }).finally(() => {
        withdrawing.value = false
        errors.value = err.response.data.errors;
    });
}
</script>

<template>
    <!-- Button trigger modal -->
    <!--  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#payoutFromMarketWallet">-->
    <!--    Payout-->
    <!--  </button>-->

    <!-- Modal -->
    <div class="modal fade" id="payoutFromMarketWallet" tabindex="-1" role="dialog"
         aria-labelledby="payoutFromMarketWalletId"
         aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="payoutFromMarketWalletId">WithDraw from Market Wallet </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
<!--                    <div class="col-12" v-if="amount < 1 || balance < amount">-->
<!--                        <div class="alert alert-danger" role="alert">-->
<!--                            <strong>You cannot withdraw. Your market wallet account does not have enough funds.</strong>-->
<!--                        </div>-->
<!--                    </div>-->
                    <form action="" v-if="!showOTPScreen">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <!--Label: Bank, Attributes:bank -->
                                <div class="form-floating">
                                    <select type="text" class="form-select" id="bank" v-model="bank"
                                            aria-describedby="bank-help"
                                            :class="[errors.bank ? 'is-invalid': '',!errors.bank && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                    >
                                        <option value="" selected disabled>
                                            Select Your Bank
                                        </option>
                                        <option :value="bank.code" v-for="bank in banks">
                                            {{ bank.name }}
                                        </option>
                                    </select>
                                    <label for="bank">Bank</label>

                                    <div class="invalid-feedback" v-if="errors.bank">
                                        {{ errors.bank.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <!--Label: Bank Account Number, Attributes:account_number -->
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="account_number" v-model="account_number"
                                           aria-describedby="account_number-help"
                                           :class="[errors.account_number ? 'is-invalid': '',!errors.account_number && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                           required>
                                    <label for="account_number">Bank Account Number</label>

                                    <div class="invalid-feedback" v-if="errors.account_number">
                                        {{ errors.account_number.toString() }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <!--Label: Amount to withdraw, Attributes:amount -->
                                <div class="form-floating">
                                    <input type="number" class="form-control" id="amount" v-model="amount"
                                           @blur="validateAmount"
                                           aria-describedby="amount-help"
                                           :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                           required>
                                    <label for="amount">Amount to withdraw</label>

                                    <div class="invalid-feedback" v-if="errors.amount">
                                        {{ errors.amount.toString() }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                    <form action="" v-else>
                        <div class="col-md-12 border border-3 rounded p-2 mb-2">
                            <table class="table table-sm table-border-style">
                                <tbody>
                                <tr>
                                    <th>Amount to Withdraw</th>
                                    <td>{{ amount.toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <th>Bank</th>
                                    <td>{{ getBank(bank).name }}</td>
                                </tr>
                                <tr>
                                    <th>Bank Account Number</th>
                                    <td>{{ account_number }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12">
                            <!--Label: OTP Code, Attributes:otp -->
                            <div class="form-floating">
                                <input type="text" class="form-control" id="otp" v-model="otp"
                                       aria-describedby="otp-help"
                                       :class="[errors.otp ? 'is-invalid': '',!errors.otp && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                       required>
                                <label for="otp">OTP Code</label>
                                <div class="invalid-feedback" v-if="errors.otp">
                                    {{ errors.otp.toString() }}
                                </div>
                            </div>
                        </div>
                        <div class="mt-2" v-if="errors.account_details">
                            <div class="alert alert-danger" role="alert">
                                <strong>{{ errors.account_details.toString() }}</strong>
                            </div>
                        </div>
                        <div class="mt-2" v-if="success">
                            <div class="alert alert-success" role="alert">
                                <strong>{{ success }}</strong>
                                <p class="text-muted">
                                    This page will automatically refresh in 5 seconds.
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <ResendOTP
                        :send-otp="sendOTP"
                        id="otp-resend"
                        v-if="showOTPScreen"
                        :ref="setChildRef" />
                    <button type="button"
                            class="btn btn-outline-secondary"
                            @click.prevent="goBack"
                            v-if="showOTPScreen">
                        Back
                    </button>
                    <button type="button"
                            class="btn btn-outline-secondary"
                            data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="button"
                            v-if="!showOTPScreen"
                            @click.prevent="sendOTP"
                            :disabled="!isValidForm"
                            class="btn btn-outline-success">
                        Next
                    </button>
                    <button type="button"
                            @click.prevent="withDraw"
                            v-else
                            :disabled="withdrawing"
                            class="btn btn-secondary">
                        WithDraw
                        <span class="fa fa-spin fa-spinner" v-if="withdrawing"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
