<script setup>

import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import Products from "./forms/products.vue";
import Fees from "../payments/forms/fees.vue";

const props = defineProps(['user']);
const emit = defineEmits(['setproduct']);


const purpose = ref('product');
const currentUser = ref('');
const productsRef = ref('');
const product = ref('');
const amount = ref('');
const quantity = ref('');

function productsetevent(productData) {
  product.value = productData;
  emit('setproduct', productData)
}

function amountsetevent(productData) {
  amount.value = productData;
  emit('amountsetevent', productData)
}

function quantitysetevent(productData) {
  quantity.value = productData;
  emit('quantitysetevent', productData)
}

const key = ref(1);

function setPurpose(parameter) {
  purpose.value = parameter;
  key.value++;
}

const mutisya = ref(0);

function updateReference() {
    mutisya.value++;
}

defineExpose({
  key,
  purpose,
  productsRef,
  updateReference
})
</script>

<template>
  <div class="col-12">
    <div class="row">
      <!--      <div class="col-4" @click="setPurpose('product')">-->
      <!--        <div class="choice" data-toggle="wizard-checkbox">-->
      <!--          <input type="checkbox" name="jobb" value="Design">-->
      <!--          <div class="card card-checkboxes card-hover-effect">-->
      <!--            <i class="ti-paint-roller"></i>-->
      <!--            <p>Products and Services</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-4" @click="setPurpose('fees')">-->
      <!--        <div class="choice" data-toggle="wizard-checkbox">-->
      <!--          <input type="checkbox" name="jobb" value="Code">-->
      <!--          <div class="card card-checkboxes card-hover-effect">-->
      <!--            <i class="ti-pencil-alt"></i>-->
      <!--            <p>Market Fees, rent or taxes</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-4" @click="setPurpose('skip')">-->
      <!--        <div class="choice" data-toggle="wizard-checkbox">-->
      <!--          <input type="checkbox" name="jobb" value="Develop">-->
      <!--          <div class="">-->
      <!--            <i class="fa fa-arrow-right"></i>-->
      <!--            <p>Skip</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <products
        :user="props.user"
        :key="mutisya"
        ref="productsRef"
        @productset="productsetevent"
        @amountset="amountsetevent"
        @quantityset="quantitysetevent"
    ></products>
    <div v-if="purpose === 'fees'">
      <fees :user="props.user" :key="key"></fees>
    </div>

    <div></div>
  </div>
</template>

<style scoped>

</style>
