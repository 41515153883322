<script setup xmlns="http://www.w3.org/1999/html">
import { onMounted, ref } from "vue";
import _ from "lodash";
import axios from "axios";
import CameraCapture from "../../userpicture/CameraCapture.vue";
import InlineTopUp from "../../wallet/payments/inlinetopup/InlineTopUp.vue";

const props = defineProps([
    "subscription_plan",
    "user",
    "country",
    "market",
    "verifications",
    "livephoto",
    "call_center_verified",
    "limits",
    "currency",
    "wallet_balance",
    "verification_paid_for",
    "subscriptions_url",
    "verification_submitted",
]);

const verifications = ref(props?.verifications);

const ninVerification = ref();

const bvnVerification = ref();

const businessAddressVerification = ref();

const callCenterVerification = ref();

const liveImageVerification = ref("");

const subscription = ref();

const getVerified = ref(false);

const marketAddress = ref();

const marketLimit = ref();

const verifiedStatus = ref();

const trustLevelPercent = ref("0%");

const loadingVerifications = ref(true);

const reloadPage = () => {
    location.reload();
};

const photoFileChange = (e) => {
    // console.log(e.target.files[0]);
    photoFile.value = e.target.files[0];
};

const fullyVerified =
    ninVerification.value === "success" &&
    bvnVerification.value === "success" &&
    businessAddressVerification.value === "success" &&
    liveImageVerification.value === "success" &&
    (callCenterVerification.value === "Correct" ||
        callCenterVerification.value === "Verified");

onMounted(() => {
    axios.get("/apis/v4/saas/verifications").then((res) => {
        // console.log(res?.data);
        ninVerification.value = res?.data?.recent_results?.nin ?? "";
        bvnVerification.value = res?.data?.recent_results?.bvn ?? "";
        businessAddressVerification.value =
            res?.data?.recent_results?.address ?? "";
        liveImageVerification.value =
            res?.data?.recent_results?.face_match ?? "";

        axios.get("/apis/v4/verifications/market-member/setup").then((res) => {
            // console.log(res?.data);
            loadingVerifications.value = false;

            callCenterVerification.value =
                res?.data?.verifications?.finance_verifications?.verifications
                    ?.call_center_verified ?? "";

            getVerified.value =
                !!res?.data?.recent_results?.nin ||
                !!res?.data?.recent_results?.bvn ||
                !!res?.data?.recent_results?.address ||
                !!res?.data?.recent_results?.face_match ||
                !!res?.data?.verifications?.finance_verifications?.verifications
                    ?.call_center_verified;
        });
    });

    axios.get("/apis/v4/verifications/market/setup").then((res) => {
        // console.log(res?.data);
        marketLimit.value = res?.data?.market?.market_limit?.member_limit ?? "";
        marketAddress.value = res?.data?.market?.address ?? "";
        subscription.value = res?.data?.subscription ?? "";
        verifiedStatus.value =
            res?.data?.market?.market_limit?.id &&
            res?.data?.market?.market_limit?.id > 0;
        trustLevelPercent.value = `${
            res?.data?.market?.market_limit?.id
                ? (res?.data?.market?.market_limit?.id / 8) * 100
                : 0
        }%`;
    });
});

const subscription_plan = props?.subscription_plan ?? "Free";

const file = ref("");
const photoFile = ref("");

const errors = ref([]);

const uploadFile = (param) => {
    file.value = param;
};

const confirm = ref("");
const openUpgrade = ref(false);
const user = ref(props?.user);

const nin = ref(user.value?.national_id);
const bvn = ref(user.value?.bvn);
const marketCity = ref("");

const locale = import.meta.env.VITE_LOCALIZATION_COUNTRY_ABBR;

const toggleOpenUpgrade = () => {
    openUpgrade.value = !openUpgrade.value;
};

const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
    } catch (err) {
        console.error("Failed to copy!", err);
    }
};

const lastPoint = _.last(props?.limits)?.minimum_credit_points;
const firstPoint = _.first(props?.limits)?.minimum_credit_points;
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

function setGetVerified() {
    getVerified.value = !getVerified.value;
}

const addressDoc = ref("");

function createImage(file) {
    var image = new Image();
    var reader = new FileReader();

    reader.onload = (e) => {
        addressDoc.value = e.target.result;
    };
    reader.readAsDataURL(file);
}

function onFileChange(e) {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    createImage(files[0]);
}

const saveLoading = ref(false);

const showError = ref("");

const saveMemberInfo = () => {
    if (
        locale !== "ke" &&
        (!bvn.value || isNaN(bvn.value) || `${bvn.value}`.length !== 11)
    ) {
        showError.value = "Valid BVN is required";
        return;
    }
    if (locale !== "ke" && !marketCity.value) {
        showError.value =
            "Fill in the market city field under the Location Verification Section";
        return;
    }
    if (locale !== "ke" && !marketAddress.value) {
        showError.value = "Market address are required";
        return;
    }
    if (locale !== "ke" && nin.value && `${nin.value}`.length !== 11) {
        showError.value = "Valid NIN is required";
        return;
    }

    showError.value = "";

    if (
        locale === "ke" ||
        subscription_plan === null ||
        subscription_plan !== "Free"
    ) {
        showError.value = "";
        saveLoading.value = true;
        axios
            .post("/apis/v4/savedetails", {
                bvn: bvn.value,
                national_id: nin.value,
                shopaddress: marketAddress.value,
                city: marketCity.value,
                shopphoto: addressDoc.value,
                // krapin
                // gender
                // age
                // signedagentform
                // leaseAgreement
                // bankstatement
            })
            .then(() => {
                if (locale !== "ke") {
                    submitQoreId();
                    return;
                }

                var myModalEl = document.querySelector("#showMessage");
                var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);
                modal.show();
            })
            .catch((err) => {
                showError.value = err.response.data.errors;
                saveLoading.value = false;
                this.errors = err.response.data.errors;
            });
    } else {
        toggleOpenUpgrade();
    }
};

const submitQoreId = () => {
    const form = new FormData();

    form.append("verification_type", "market");
    form.append("market_id", props.market?.id);
    form.append("user_id", props.user?.id);

    if (
        bvn.value &&
        bvnVerification.value !== "success" &&
        bvnVerification !== "pending"
    ) {
        form.append("types[]", "bvn");
        form.append("bvn", bvn.value);
    }

    if (
        nin.value &&
        ninVerification.value !== "success" &&
        ninVerification !== "pending"
    ) {
        form.append("types[]", "nin");
        form.append("nin", nin.value);
    }

    if (
        (photoFile.value || file.value) &&
        liveImageVerification !== "success" &&
        liveImageVerification !== "pending"
    ) {
        form.append("types[]", "face_match");
        form.append(
            "face_match[photo]",
            photoFile.value ? photoFile.value : file.value
        );
        if (bvn) {
            form.append("face_match[id_number]", bvn.value);
            form.append("face_match[id_type]", "bvn");
        } else if (nin) {
            form.append("face_match[id_number]", nin.value);
            form.append("face_match[id_type]", "nin");
        }
    }

    if (
        marketAddress.value &&
        marketCity.value &&
        businessAddressVerification !== "success" &&
        businessAddressVerification !== "pending"
    ) {
        form.append("types[]", "address");

        form.append("address[lga]", props?.market?.l_g_a?.name);
        form.append("address[state]", props?.market?.geo_state?.name);
        form.append("address[city]", marketCity.value);
        form.append("address[street]", marketAddress.value);
    }

    saveLoading.value = true;

    axios
        .post("/apis/v4/saas/verifications", form)
        .then(() => {
            saveLoading.value = false;
            window.location.reload();
        })
        .catch((err) => {
            showError.value = err.response.data.errors;
            saveLoading.value = false;
            console.log(err);
        })
        .finally(() => {
            saveLoading.value = false;
        });
};

function makePayment() {
    var myModalEl = document.querySelector("#topupInline");
    var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    modal.show();
}

function walletToppedUp(data) {
    saveMemberInfo();
    window.location.reload();
}
</script>

<template>
    <div
        class="border-bottom-info page-breadcrumb border-bottom-1 d-none d-sm-flex align-items-center mb-3"
    >
        <div class="breadcrumb-title pe-3">
            <div style="font-weight: 700; color: rgba(29, 36, 46, 0.5)">
                {{ props?.market?.name }}
            </div>
        </div>
        <div class="ps-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0 p-0">
                    <li
                        style="font-weight: 700; color: #1d242e"
                        class="breadcrumb-item active"
                        aria-current="page"
                    >
                        Profile
                    </li>
                </ol>
            </nav>
        </div>
        <div class="ms-auto"></div>
    </div>
    <div class="row">
        <!-- <div class="col-12 d-flex justify-content-end mb-3"> -->
        <!--     <div -->
        <!--         class="modal fade" -->
        <!--         id="creditConfirmation" -->
        <!--         tabindex="-1" -->
        <!--         role="dialog" -->
        <!--         aria-labelledby="modelTitleId" -->
        <!--         aria-hidden="true" -->
        <!--     > -->
        <!--         <div class="modal-dialog" role="document"> -->
        <!--             <div class="modal-content"> -->
        <!--                 <div class="modal-header"> -->
        <!--                     <h4 class="modal-title" id="modelTitleId"></h4> -->
        <!--                     <button -->
        <!--                         type="button" -->
        <!--                         class="btn-close" -->
        <!--                         data-bs-dismiss="modal" -->
        <!--                         aria-label="Close" -->
        <!--                     ></button> -->
        <!--                 </div> -->
        <!--                 <div class="modal-body"> -->
        <!--                     <div class="alert alert-info" role="alert"> -->
        <!--                         <strong> -->
        <!--                             Do you want to be able to request for your -->
        <!--                             market to pay for your goods or services and -->
        <!--                             you can pay later? Please submit information -->
        <!--                             be approved to make purchases on -->
        <!--                             credit.</strong -->
        <!--                         > -->
        <!--                     </div> -->
        <!--                     <div class="row"> -->
        <!--                         <div class=""> -->
        <!--                             <form -->
        <!--                                 action="http://localhost:8080/change-current-group" -->
        <!--                                 method="POST" -->
        <!--                                 name="frmGroupChange" -->
        <!--                                 id="frmGroupChange" -->
        <!--                             > -->
        <!--                                 <input -->
        <!--                                     type="hidden" -->
        <!--                                     name="_token" -->
        <!--                                     value="CaqWMIMneod9A2I417ZFNSZLlm7DiRont66nVN7H" -->
        <!--                                 /> -->
        <!--                                 <div class="form-floating"> -->
        <!--                                     <select -->
        <!--                                         name="group_id" -->
        <!--                                         id="group_id" -->
        <!--                                         class="form-select" -->
        <!--                                         onchange="this.form.submit()" -->
        <!--                                     > -->
        <!--                                         <option disabled="" value=""> -->
        <!--                                             Select Market -->
        <!--                                         </option> -->
        <!--                                         <option value="2"> -->
        <!--                                             {{ props?.market?.name }}. -->
        <!--                                         </option> -->
        <!--                                     </select> -->
        <!--                                 </div> -->
        <!--                             </form> -->
        <!--                         </div> -->
        <!--                     </div> -->
        <!--                 </div> -->
        <!--                 <div class="modal-footer"> -->
        <!--                     <button -->
        <!--                         type="button" -->
        <!--                         class="btn btn-secondary" -->
        <!--                         data-bs-dismiss="modal" -->
        <!--                     > -->
        <!--                         Close</button -->
        <!--                     ><button type="button" class="btn btn-primary"> -->
        <!--                         Save -->
        <!--                     </button> -->
        <!--                 </div> -->
        <!--             </div> -->
        <!--         </div> -->
        <!--     </div> -->
        <!-- </div> -->
        <div class="col-md-6">
            <div
                class="card"
                style="
                    border-radius: 0.25rem;
                    border: 0.2px solid #1d242e;
                    box-shadow: none;
                "
            >
                <div class="card-body">
                    <div class="row">
                        <div class="table-responsive">
                            <table class="table row table-borderless">
                                <tbody class="">
                                    <tr>
                                        <td style="padding: 0.15rem 0.5rem">
                                            <template
                                                v-if="props.user.user_avatar"
                                            >
                                                <img
                                                    :src="
                                                        props.user.user_avatar
                                                    "
                                                    class="img-fluid rounded"
                                                    alt="user avatar"
                                                    style="
                                                        width: 150px;
                                                        height: 150px;
                                                        background-position: center;
                                                        background-size: cover;
                                                    "
                                                />
                                            </template>
                                            <template v-else>
                                                <svg
                                                    width="162"
                                                    height="124"
                                                    viewBox="0 0 162 124"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style="
                                                        border-radius: 0.3125rem;
                                                        overflow: hidden;
                                                    "
                                                >
                                                    <rect
                                                        width="162"
                                                        height="124"
                                                        fill="#D9D9D9"
                                                    />
                                                    <g
                                                        clip-path="url(#clip0_617_48)"
                                                    >
                                                        <path
                                                            d="M81 62.1429C88.5776 62.1429 95.8449 59.1327 101.203 53.7745C106.561 48.4163 109.571 41.149 109.571 33.5714C109.571 25.9938 106.561 18.7266 101.203 13.3684C95.8449 8.0102 88.5776 5 81 5C73.4224 5 66.1551 8.0102 60.7969 13.3684C55.4388 18.7266 52.4286 25.9938 52.4286 33.5714C52.4286 41.149 55.4388 48.4163 60.7969 53.7745C66.1551 59.1327 73.4224 62.1429 81 62.1429ZM70.7991 72.8571C48.8125 72.8571 31 90.6696 31 112.656C31 116.317 33.9688 119.286 37.6295 119.286H124.371C128.031 119.286 131 116.317 131 112.656C131 90.6696 113.188 72.8571 91.2009 72.8571H70.7991Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath
                                                            id="clip0_617_48"
                                                        >
                                                            <rect
                                                                width="100"
                                                                height="114.286"
                                                                fill="white"
                                                                transform="translate(31 5)"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </template>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 0.15rem 0.5rem">
                                            <strong>Name :</strong>
                                            {{ props?.user?.name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 0.15rem 0.5rem">
                                            <strong>Gender :</strong>
                                            {{ props?.user?.gender }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 0.15rem 0.5rem">
                                            <strong>Markets:</strong>
                                            {{ props?.market?.name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 0.15rem 0.5rem">
                                            <strong>Phone Number:</strong>
                                            {{ props?.user?.formattedphone }}
                                        </td>
                                    </tr>
                                    <!--                                    <tr>-->
                                    <!--                                        <td style="padding: 0.15rem 0.5rem">-->
                                    <!--                                            <strong-->
                                    <!--                                                >Verification Level :</strong-->
                                    <!--                                            >-->
                                    <!--                                            {{ props?.call_center_verified }}-->
                                    <!--                                        </td>-->
                                    <!--                                    </tr>-->
                                    <tr>
                                        <td style="padding: 0.15rem 0.5rem">
                                            <strong>BNPL Limit :</strong>
                                            {{ currency }}
                                            {{
                                                props?.user?.agent_limit
                                                    ?.allocation ?? 0
                                            }}
                                        </td>
                                    </tr>
                                    <hr style="color: grey" />
                                    <tr>
                                        <td style="padding: 0.15rem 0.5rem">
                                            <a
                                                href="/four/my-profile"
                                                class="btn btn-outline-secondary btn-sm"
                                                style="border-radius: 1rem"
                                            >
                                                Update Details
                                                <i
                                                    class="fa-light fa-angle-right"
                                                ></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="card"
                style="
                    border-radius: 0.25rem;
                    border: 0.2px solid #1d242e;
                    box-shadow: none;
                    padding: 1rem;
                    background: #fff;
                "
            >
                <div
                    class="card-body"
                    style="
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                    "
                >
                    <div
                        style="
                            width: 100%;
                            padding: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            flex: 1;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                flex-wrap: wrap;
                                width: 100%;
                                gap: 0.5rem;
                            "
                        >
                            <h4 style="font-size: 18px; padding: 0; margin: 0">
                                Share your referral code
                            </h4>
                            <a
                                href="/four/my-profile?referral_program=true"
                                class="btn btn-outline-secondary btn-sm"
                                style="
                                    border-radius: 1rem;
                                    font-size: 12px;
                                    color: #4f5d75;
                                    background: #f5f5f5;
                                    border: 1px solid #f5f5f5;
                                "
                            >
                                My referral dashboard
                                <i
                                    style="transform: scale(0.6)"
                                    class="fa-light fa-angle-right"
                                ></i>
                            </a>
                        </div>
                        <p style="padding: 0; margin: 0.5rem 0">
                            Earn rewards on Amana Market by referring new users
                            and markets
                        </p>
                    </div>

                    <div
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 0.3rem;
                            width: 100%;
                            background: #f5f5f5;
                            border: 1px solid #f5f5f5;
                            padding: 11px 24px;
                            flex-wrap: wrap;
                            border-radius: 6px;
                            overflow-x: hidden;
                        "
                    >
                        <p
                            style="
                                margin: 0;
                                font-weight: 600;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            "
                        >
                            {{ props?.user?.referral_code?.code }}
                        </p>
                        <div
                            style="
                                color: #00ab4d;
                                font-size: 12px;
                                display: flex;
                                justify-content: center;
                                gap: 0.2rem;
                                align-items: center;
                                cursor: pointer;
                            "
                            @click="
                                copyToClipboard(
                                    props?.user?.referral_code?.code
                                )
                            "
                        >
                            <i
                                class="fa-light fa-copy"
                                style="cursor: pointer; color: #00ab4d"
                            ></i>
                            <p style="margin: 0; padding: 0">Copy code</p>
                        </div>
                    </div>

                    <hr style="color: grey; width: 100%; margin: 1.5rem 0" />

                    <div
                        style="
                            width: 100%;
                            padding: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            flex: 1;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                flex-wrap: wrap;
                                width: 100%;
                            "
                        >
                            <h4 style="font-size: 18px; padding: 0; margin: 0">
                                What is the “Referral Program”?
                            </h4>
                        </div>
                        <p style="padding: 0; margin: 0.5rem 0">
                            The referral program is a program that enables you
                            to earn money by making referrals (inviting) friends
                            and other markets to the platform
                        </p>
                    </div>

                    <hr style="color: grey; width: 100%; margin: 1.5rem 0" />

                    <div
                        style="
                            width: 100%;
                            padding: 0;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            flex: 1;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                flex-wrap: wrap;
                                width: 100%;
                            "
                        >
                            <h4 style="font-size: 18px; padding: 0; margin: 0">
                                Benefits of the “Referral Program”
                            </h4>
                        </div>
                        <p style="padding: 0; margin: 0.5rem 0">
                            When you refer people to use market services with
                            your referral code, you get:
                        </p>
                        <ol style="margin: 0">
                            <li style="color: #4f5d75; font-size: 14px">
                                {{ currency }}100 for each user who signs up and
                                gets verified on Amana Market
                            </li>
                            <li style="color: #4f5d75; font-size: 14px">
                                {{ currency }}4,000 per each subscription a
                                market leader pays for their market on Amana
                                Market
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="">
                <div class="col-12">
                    <div
                        v-if="
                            props?.market?.market_limit?.id &&
                            props?.market?.market_limit?.id > 0
                        "
                        class="card mb-3"
                        style="
                            border-radius: 0.25rem;
                            border: 0.2px solid #fff;
                            box-shadow: none;
                        "
                    >
                        <div class="card-body">
                            <h4 class="text-success">Verified!</h4>
                            <div
                                style="
                                    display: flex;
                                    border-radius: 1.25rem;
                                    border: 1px solid #4b9d30;
                                    padding: 0.5rem 0.8rem;
                                    justify-content: space-between;
                                    align-items: center;
                                    width: 100%;
                                    gap: 1rem;
                                    margin-bottom: 0.5rem;
                                "
                            >
                                <div
                                    style="
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex-direction: column;
                                        gap: 0.2rem;
                                    "
                                >
                                    <div
                                        style="
                                            border-radius: 50%;
                                            display: grid;
                                            place-items: center;
                                            border: 1px solid #4b9d30;
                                            padding: 1rem;
                                        "
                                    >
                                        <div
                                            style="
                                                border-radius: 50%;
                                                width: 0.3125rem;
                                                height: 0.3125rem;
                                                background: #4b9d30;
                                            "
                                        ></div>
                                    </div>
                                    <p
                                        style="
                                            color: #4b9d30;
                                            text-align: center;
                                            font-size: 0.625rem;
                                            margin-bottom: 0;
                                        "
                                    >
                                        {{ firstPoint }}
                                    </p>
                                </div>
                                <div
                                    style="
                                        display: flex;
                                        justify-content: center;
                                        align-items: flex-start;
                                        flex-direction: column;
                                        gap: 0.2rem;
                                        width: 100%;
                                    "
                                >
                                    <p
                                        style="
                                            margin-bottom: 0;
                                            color: #262626;
                                            text-align: center;
                                            font-size: 0.875rem;
                                            font-style: normal;
                                            font-weight: 700;
                                        "
                                    >
                                        Trust level
                                    </p>
                                    <div
                                        style="
                                            display: flex;
                                            justify-content: flex-start;
                                            align-items: center;
                                            width: 100%;
                                            background: #e0e0e0;
                                            border-radius: 4rem;
                                            height: 0.5rem;
                                        "
                                    >
                                        <div
                                            style="
                                                display: flex;
                                                justify-content: flex-start;
                                                align-items: center;
                                                background: #4b9d30;
                                                border-radius: 4rem;
                                                height: 0.5rem;
                                            "
                                            :style="{
                                                width: trustLevelPercent,
                                            }"
                                        ></div>
                                    </div>
                                </div>
                                <div
                                    style="
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex-direction: column;
                                        gap: 0.2rem;
                                    "
                                >
                                    <div
                                        style="
                                            border-radius: 50%;
                                            display: grid;
                                            place-items: center;
                                            border: 1px solid #4b9d30;
                                            background: #4b9d30;
                                            padding: 1rem;
                                        "
                                    >
                                        <div
                                            style="
                                                border-radius: 50%;
                                                width: 0.3125rem;
                                                height: 0.3125rem;
                                                background: #4b9d30;
                                            "
                                        ></div>
                                    </div>
                                    <p
                                        style="
                                            color: #4b9d30;
                                            text-align: center;
                                            font-size: 0.625rem;
                                            margin-bottom: 0;
                                        "
                                    >
                                        {{ lastPoint }}
                                    </p>
                                </div>
                            </div>
                            <p style="color: #1d242e; margin-bottom: 0.5rem">
                                You have a credit score of
                                <span style="color: #4b9d30">
                                    {{ props?.user?.credit_score }}
                                </span>
                                and market has access to
                                <span style="color: #4b9d30">
                                    {{ currency }}
                                    {{
                                        props?.user?.agent_limit?.allocation ??
                                        0
                                    }}
                                </span>
                                for trade finance
                            </p>
                            <button
                                class="btn btn-success"
                                @click="setGetVerified"
                            >
                                View Verification
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="!getVerified && !loadingVerifications"
                        class="card mb-3"
                        style="
                            border-radius: 0.25rem;
                            border: 0.2px solid #fff;
                            box-shadow: none;
                        "
                    >
                        <div class="card-body">
                            <h4 class="text-success">Get Verified</h4>
                            <p style="color: rgba(29, 36, 46, 0.5)">
                                Verify Your account so vendors can trust you and
                                you can access financing and insurance options
                                depending on your region.
                            </p>
                            <button
                                class="btn btn-success"
                                @click="toggleOpenUpgrade"
                                v-if="subscription_plan === 'Free'"
                            >
                                Start Verification
                            </button>
                            <button
                                v-else
                                class="btn btn-success"
                                @click="setGetVerified"
                            >
                                Start Verification
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="openUpgrade"
                        style="
                            position: fixed;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            display: grid;
                            place-items: center;
                            padding: 1rem;
                            background-color: rgba(0, 0, 0, 0.3);
                            z-index: 50;
                        "
                    >
                        <div
                            class="card mb-3"
                            style="
                                position: relative;
                                border-radius: 0.25rem;
                                border: 0.2px solid #fff;
                                box-shadow: none;
                                max-width: 500px;
                            "
                        >
                            <svg
                                style="
                                    position: absolute;
                                    top: 0.5rem;
                                    right: 0.5rem;
                                    cursor: pointer;
                                "
                                @click="toggleOpenUpgrade"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clip-path="url(#clip0_8_6557)">
                                    <path
                                        d="M13.89 8.7L12 10.59L10.11 8.7C9.72 8.31 9.09 8.31 8.7 8.7C8.31 9.09 8.31 9.72 8.7 10.11L10.59 12L8.7 13.89C8.31 14.28 8.31 14.91 8.7 15.3C9.09 15.69 9.72 15.69 10.11 15.3L12 13.41L13.89 15.3C14.28 15.69 14.91 15.69 15.3 15.3C15.69 14.91 15.69 14.28 15.3 13.89L13.41 12L15.3 10.11C15.69 9.72 15.69 9.09 15.3 8.7C14.91 8.32 14.27 8.32 13.89 8.7ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                                        fill="#1D242E"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_8_6557">
                                        <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>

                            <div class="card-body">
                                <h4 class="text-success">
                                    Upgrade Your Market
                                </h4>
                                <p style="color: #283342">
                                    Your market is currently on the free plan.
                                    In order to get verified, your market leader
                                    needs to upgrade your market to enable you
                                    to get verified on Amana Market
                                </p>
                                <button
                                    class="btn btn-success"
                                    style="padding: 0.5rem 2rem"
                                    @click="toggleOpenUpgrade"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>



                <div
                    v-if="getVerified && !loadingVerifications"
                    class="accordion mt-2"
                    id="accordionExample3"
                >
                    <div
                        class="accordion-item mt-2"
                        style="border-color: rgba(29, 36, 46, 0.3)"
                    >
                        <h2 class="accordion-header" id="headingTwo">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                <div
                                    class="d-flex justify-content-between align-items-center"
                                    style="width: 100vw"
                                >
                                    <div v-if="locale === 'ke'">
                                        KRA PIN Verification
                                    </div>
                                    <div v-else>BVN Verification</div>
                                    <div>
                                        <span
                                            class="badge badge-pill badge-danger"
                                            v-if="bvnVerification === 'failed'"
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#FFF2F0"
                                                />
                                                <path
                                                    d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                                    stroke="#E2341D"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span
                                            class="badge badge-pill badge-success"
                                            v-else-if="
                                                bvnVerification === 'success'
                                            "
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#41AE49"
                                                />
                                                <path
                                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                                    stroke="#F8FBF8"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span
                                            class="badge badge-pill badge-success"
                                            v-else-if="
                                                bvnVerification === 'pending'
                                            "
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#FFF8EB"
                                                />
                                                <path
                                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                                    stroke="#D78100"
                                                    stroke-width="1.5"
                                                />
                                                <path
                                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                                    stroke="#D78100"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div
                            id="collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body">
                                <div>
                                    <div
                                        class="alert alert-danger"
                                        role="alert"
                                        v-if="bvnVerification === 'failed'"
                                        style="
                                            background-color: #fff1ef;
                                            border-color: #fff1ef;
                                        "
                                    >
                                        <strong>
                                            <span
                                                v-if="
                                                    bvnVerification === 'failed'
                                                "
                                            >
                                                <template v-if="locale === 'ke'"
                                                    >KRA PIN Verification
                                                    Failed</template
                                                >
                                                <template v-else
                                                    >BVN Verification
                                                    Failed</template
                                                >
                                            </span>
                                            <span
                                                v-else-if="
                                                    bvnVerification ===
                                                    'success'
                                                "
                                            >
                                                <template v-if="locale === 'ke'"
                                                    >KRA PIN Verification
                                                    Complete</template
                                                >
                                                <template v-else
                                                    >BVN Verification
                                                    Verification
                                                    Complete</template
                                                >
                                            </span>
                                            <span
                                                v-else-if="
                                                    bvnVerification ===
                                                    'pending'
                                                "
                                            >
                                                <template v-if="locale === 'ke'"
                                                    >KRA PIN Verification In
                                                    Progress</template
                                                >
                                                <template v-else
                                                    >BVN Verification In
                                                    Progress</template
                                                >
                                            </span>
                                        </strong>

                                        <div class="table-responsive">
                                            <table
                                                class="table table-sm table-borderless"
                                            >
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">
                                                            <span class="">
                                                                <template
                                                                    v-if="
                                                                        locale ===
                                                                        'ke'
                                                                    "
                                                                    >KRA PIN
                                                                    Verification</template
                                                                >
                                                                <template v-else
                                                                    >BVN
                                                                    Verification</template
                                                                >
                                                            </span>
                                                        </th>
                                                        <td>
                                                            <template
                                                                v-if="
                                                                    bvnVerification ===
                                                                    'failed'
                                                                "
                                                            >
                                                                <i
                                                                    class="fa-solid fa-shield-xmark fa-lg text-danger"
                                                                ></i>
                                                            </template>
                                                            <template
                                                                v-if="
                                                                    bvnVerification ===
                                                                    'success'
                                                                "
                                                            >
                                                                <i
                                                                    class="fa-solid fa-shield-check fa-lg text-success"
                                                                ></i>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <label
                                            >Enter your
                                            <template v-if="locale === 'ke'"
                                                >KRA PIN</template
                                            >
                                            <template v-else>BVN</template>
                                        </label>
                                        <input
                                            :type="
                                                locale === 'ke' ? text : number
                                            "
                                            class="form-control"
                                            id="bvn"
                                            aria-describedby="bvn-help"
                                            required=""
                                            v-model="bvn"
                                            style="padding: 0.25rem 0.5rem"
                                            :disabled="
                                                bvnVerification === 'success' ||
                                                bvnVerification === 'pending'
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="getVerified && !loadingVerifications"
                    class="accordion mt-2"
                    id="accordionExample8"
                >
                    <div
                        class="accordion-item mt-2"
                        style="border-color: rgba(29, 36, 46, 0.3)"
                    >
                        <h2 class="accordion-header" id="headingEight">
                            <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseEight"
                                aria-expanded="false"
                                aria-controls="collapseEight"
                            >
                                <div
                                    class="d-flex justify-content-between align-items-center"
                                    style="width: 100vw"
                                >
                                    <div>NIN Verification</div>
                                    <div>
                                        <span
                                            class="badge badge-pill badge-danger"
                                            v-if="ninVerification === 'failed'"
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#FFF2F0"
                                                />
                                                <path
                                                    d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                                    stroke="#E2341D"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span
                                            class="badge badge-pill badge-success"
                                            v-else-if="
                                                ninVerification === 'success'
                                            "
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#41AE49"
                                                />
                                                <path
                                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                                    stroke="#F8FBF8"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span
                                            class="badge badge-pill badge-success"
                                            v-else-if="
                                                ninVerification === 'pending'
                                            "
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#FFF8EB"
                                                />
                                                <path
                                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                                    stroke="#D78100"
                                                    stroke-width="1.5"
                                                />
                                                <path
                                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                                    stroke="#D78100"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div
                            id="collapseEight"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingEight"
                            data-bs-parent="#accordionExample8"
                        >
                            <div class="accordion-body">
                                <div>
                                    <div>
                                        <strong>
                                            <span
                                                v-if="
                                                    ninVerification === 'failed'
                                                "
                                            >
                                                NIN Verification Failed
                                            </span>
                                            <span
                                                v-else-if="
                                                    ninVerification ===
                                                    'success'
                                                "
                                            >
                                                NIN Verification Complete
                                            </span>
                                            <span
                                                v-else-if="
                                                    ninVerification ===
                                                    'pending'
                                                "
                                            >
                                                NIN Verification In Progress
                                            </span>
                                        </strong>

                                        <div class="table-responsive">
                                            <table
                                                class="table table-sm table-borderless"
                                            >
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">
                                                            <span class=""
                                                                >NIN
                                                                Verification</span
                                                            >
                                                        </th>
                                                        <td>
                                                            <template
                                                                v-if="
                                                                    ninVerification ===
                                                                    'failed'
                                                                "
                                                            >
                                                                <i
                                                                    class="fa-solid fa-shield-xmark fa-lg text-danger"
                                                                ></i>
                                                            </template>
                                                            <template
                                                                v-if="
                                                                    ninVerification ===
                                                                    'success'
                                                                "
                                                            >
                                                                <i
                                                                    class="fa-solid fa-shield-check fa-lg text-success"
                                                                ></i>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <label>Enter your NIN</label>
                                        <input
                                            type="number"
                                            class="form-control"
                                            id="nin"
                                            aria-describedby="nin-help"
                                            required=""
                                            v-model="nin"
                                            placeholder="Enter your NIN"
                                            style="padding: 0.25rem 0.5rem"
                                            :disabled="
                                                ninVerification === 'success' ||
                                                ninVerification === 'pending'
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="getVerified && !loadingVerifications"
                    class="accordion mt-2"
                    id="accordionExample4"
                >
                    <div
                        class="accordion-item"
                        style="border-color: rgba(29, 36, 46, 0.3)"
                    >
                        <h2 class="accordion-header" id="headingFive">
                            <button
                                class="d-flex justify-content-start align-items-center accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                            >
                                <div
                                    class="d-flex justify-content-between align-items-center"
                                    style="width: 100vw"
                                >
                                    <div>Live Verification</div>
                                    <div>
                                        <span
                                            class="badge badge-pill badge-danger"
                                            v-if="
                                                liveImageVerification ===
                                                'failed'
                                            "
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#FFF2F0"
                                                />
                                                <path
                                                    d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                                    stroke="#E2341D"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span
                                            class="badge badge-pill badge-success"
                                            v-else-if="
                                                liveImageVerification ===
                                                'success'
                                            "
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#41AE49"
                                                />
                                                <path
                                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                                    stroke="#F8FBF8"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span
                                            class="badge badge-pill badge-success"
                                            v-else-if="
                                                liveImageVerification ===
                                                'pending'
                                            "
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#FFF8EB"
                                                />
                                                <path
                                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                                    stroke="#D78100"
                                                    stroke-width="1.5"
                                                />
                                                <path
                                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                                    stroke="#D78100"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div
                            id="collapseFive"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingFiver"
                            data-bs-parent="#accordionExampled"
                        >
                            <div class="accordion-body">
                                <CameraCapture
                                    @upload-file="uploadFile"
                                    :uploaded_photo="null"
                                    :user_id="props?.user.id"
                                    v-if="
                                        liveImageVerification.value !==
                                            'success' ||
                                        liveImageVerification.value !==
                                            'pending'
                                    "
                                ></CameraCapture>
                                <p
                                    v-if="
                                        liveImageVerification.value !==
                                            'success' ||
                                        liveImageVerification.value !==
                                            'pending'
                                    "
                                >
                                    Please ensure your picture is taken clearly
                                    with good lighting
                                </p>

                                <div
                                    v-if="
                                        liveImageVerification !== 'success' ||
                                        liveImageVerification !== 'pending'
                                    "
                                    class="col-12"
                                >
                                    <p style="text-align: center">OR</p>
                                    <label>Upload your photo</label>
                                    <input
                                        type="file"
                                        class="form-control"
                                        @change="photoFileChange"
                                        id="photo"
                                        required=""
                                        placeholder="Upload your photo"
                                        style="padding: 0.25rem 0.5rem"
                                        :disabled="
                                            liveImageVerification ===
                                                'success' ||
                                            liveImageVerification === 'pending'
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="getVerified && !loadingVerifications"
                    class="accordion mt-2"
                    id="accordionExample2"
                >
                    <div
                        class="accordion-item"
                        style="border-color: rgba(29, 36, 46, 0.3)"
                    >
                        <h2 class="accordion-header" id="headingThree">
                            <button
                                class="d-flex justify-content-start align-items-center accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                            >
                                <div
                                    class="d-flex justify-content-between align-items-center"
                                    style="width: 100vw"
                                >
                                    <div>Location Verification</div>
                                    <div>
                                        <span
                                            class="badge badge-pill badge-danger"
                                            v-if="
                                                businessAddressVerification ===
                                                'failed'
                                            "
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#FFF2F0"
                                                />
                                                <path
                                                    d="M21.0835 12.9165L12.9169 21.0832M12.9169 12.9165L21.0835 21.0832"
                                                    stroke="#E2341D"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span
                                            class="badge badge-pill badge-success"
                                            v-else-if="
                                                businessAddressVerification ===
                                                'success'
                                            "
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#41AE49"
                                                />
                                                <path
                                                    d="M12.9166 18.1665L14.9583 20.2082L21.0833 13.7915"
                                                    stroke="#F8FBF8"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span
                                            class="badge badge-pill badge-success"
                                            v-else-if="
                                                businessAddressVerification ===
                                                'pending'
                                            "
                                        >
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect
                                                    width="34"
                                                    height="34"
                                                    rx="17"
                                                    fill="#FFF8EB"
                                                />
                                                <path
                                                    d="M21.0834 11.1665V12.9165C21.0834 15.1717 19.2552 16.9998 17.0001 16.9998M12.9167 11.1665V12.9165C12.9167 15.1717 14.7449 16.9998 17.0001 16.9998M17.0001 16.9998C19.2552 16.9998 21.0834 18.828 21.0834 21.0832V22.8332M17.0001 16.9998C14.7449 16.9998 12.9167 18.828 12.9167 21.0832V22.8332"
                                                    stroke="#D78100"
                                                    stroke-width="1.5"
                                                />
                                                <path
                                                    d="M12.3334 11.1665H21.6667M21.6667 22.8332H12.3334"
                                                    stroke="#D78100"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div
                            id="collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExampled"
                        >
                            <div class="accordion-body">
                                <div>
                                    <div
                                        class="alert alert-danger"
                                        role="alert"
                                        v-if="
                                            businessAddressVerification ===
                                            'failed'
                                        "
                                        style="
                                            background-color: #fff1ef;
                                            border-color: #fff1ef;
                                        "
                                    >
                                        <strong>
                                            <span
                                                v-if="
                                                    businessAddressVerification ===
                                                    'failed'
                                                "
                                            >
                                                Market Location Verification
                                                Failed
                                            </span>
                                            <span
                                                v-else-if="
                                                    businessAddressVerification ===
                                                    'success'
                                                "
                                            >
                                                Location Verification Complete
                                            </span>
                                            <span
                                                v-else-if="
                                                    businessAddressVerification ===
                                                    'pending'
                                                "
                                            >
                                                Market Location Verification In
                                                Progress
                                            </span>
                                        </strong>

                                        <div class="table-responsive">
                                            <table
                                                class="table table-sm table-borderless"
                                            >
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">
                                                            <span class=""
                                                                >Address
                                                                Verification</span
                                                            >
                                                        </th>
                                                        <td>
                                                            <template
                                                                v-if="
                                                                    businessAddressVerification ===
                                                                    'failed'
                                                                "
                                                            >
                                                                <i
                                                                    class="fa-solid fa-shield-xmark fa-lg text-danger"
                                                                ></i>
                                                            </template>
                                                            <template
                                                                v-if="
                                                                    businessAddressVerification ===
                                                                    'success'
                                                                "
                                                            >
                                                                <i
                                                                    class="fa-solid fa-shield-check fa-lg text-success"
                                                                ></i>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-3">
                                    <div class="col-12">
                                        <!--Label: Marekt Address, Attributes:address -->

                                        <div class="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="marketAddress"
                                                required=""
                                                v-model="marketAddress"
                                                style="padding: 0.25rem 0.5rem"
                                                :disabled="
                                                    businessAddressVerification ===
                                                        'success' ||
                                                    businessAddressVerification ===
                                                        'pending'
                                                "
                                            />
                                            <label
                                                >Enter your market
                                                address</label
                                            >
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="marketCity"
                                                required=""
                                                v-model="marketCity"
                                                style="padding: 0.45rem 0.5rem"
                                                :disabled="
                                                    businessAddressVerification ===
                                                        'success' ||
                                                    businessAddressVerification ===
                                                        'pending'
                                                "
                                            />
                                            <label
                                                >Enter your market city</label
                                            >
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <label
                                            >Rent Agreement or Proof of
                                            Ownership</label
                                        >
                                        <input
                                            type="file"
                                            @change="onFileChange"
                                            class="form-control"
                                            placeholder="Upload your utility bill"
                                            :disabled="
                                                businessAddressVerification ===
                                                    'success' ||
                                                businessAddressVerification ===
                                                    'pending'
                                            "
                                            style="padding: 0.25rem 0.5rem"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showError" class="col-12 mt-2">
                    <div class="alert alert-danger" role="alert">
                        <strong> {{ showError }}</strong>
                    </div>
                </div>

                <div
                    v-if="
                        getVerified && !loadingVerifications && !fullyVerified
                    "
                    class="col-12 mt-2"
                >
                    <div class="d-flex justify-content-end">
                        <button
                            v-if="verification_paid_for"
                            class="btn btn-success mr-3 mb-3"
                            @click.prevent="saveMemberInfo"
                        >
                            Submit
                            <spanidentity
                                class="fa fa-spin fa-spinner"
                                v-if="saveLoading"
                            ></spanidentity>
                        </button>
                        <div
                            v-else-if="
                                !verification_paid_for && wallet_balance >= 150
                            "
                        >
                            <div class="alert alert-secondary" role="alert">
                                <strong>
                                    You have enough funds in your wallet to be
                                    deducted for the verication fee. To
                                    continue, click the button below.
                                </strong>
                            </div>
                            <button
                                class="btn btn-secondary mr-3 mb-3"
                                @click.prevent="saveMemberInfo"
                            >
                                Submit and pay {{ currency }}150
                                <spanidentity
                                    class="fa fa-spin fa-spinner"
                                    v-if="saveLoading"
                                ></spanidentity>
                            </button>
                        </div>

                        <div v-else>
                            <div class="alert alert-secondary" role="alert">
                                <strong>
                                    You will be required to pay a
                                    {{ currency }}150 search fee in order to
                                    enable us check the status of your
                                    submission
                                </strong>
                            </div>
                            <button
                                class="btn btn-secondary mr-3 mb-3"
                                @click.prevent="makePayment"
                            >
                                Top up & Submit
                                <spanidentity
                                    class="fa fa-spin fa-spinner"
                                    v-if="saveLoading"
                                ></spanidentity>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="topupInline"
        tabindex="-1"
        role="dialog"
        aria-labelledby="topupInline"
        aria-hidden="true"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="topupInline">
                        Make Verification Payment
                    </h4>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <InlineTopUp
                            ref="inlineTopUp"
                            @walletToppedUp="walletToppedUp"
                            :amountToPay="150"
                        ></InlineTopUp>
                    </div>
                </div>
                <!--                              <div class="modal-footer">-->
                <!--                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
                <!--                                <button type="button" class="btn btn-primary">Save</button>-->
                <!--                              </div>-->
            </div>
        </div>
    </div>
    <div
        class="modal fade"
        id="showMessage"
        tabindex="-1"
        role="dialog"
        aria-labelledby="showMessageId"
        aria-hidden="true"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="showMessageId">
                        Verification Submitted
                    </h4>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    Thank you for your submission. Your. verification will be
                    reviewed and you will receive a response within 48 hours. If
                    you don't get a response after 48 hours, please call
                    0791489171
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="reloadPage"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
