<script setup>
import moment from "moment";
import {onMounted, ref, watch} from "vue";
import {_} from "lodash";
import 'animate.css';
import Auditable from "../Auditable.vue";
import  Swal from "sweetalert2";

const props = defineProps(['user', 'auth_id']);
const errors = ref({});
const choose_market = ref('');
const leader = ref('');
const selectedMarket = ref({});


/* Form*/
const showOtherQuestions = ref(false);
const reachable = ref('');
const phonenumber = ref('');
const corresponding_name = ref('');
const market_name_verified = ref('');
const products = ref('');
const products_selling = ref('');
const consented_to_being_on_amana_market = ref('');
const readterms = ref('');
const amamavalue = ref('');
const verifying = ref(false);
const enableSendButton = ref(false);


watch(readterms, function () {
  if (readterms.value === true && amamavalue.value === true) {
    enableSendButton.value = true;
    return;
  }
  enableSendButton.value = false;
})

watch(amamavalue, function () {
  if (readterms.value === true && amamavalue.value === true) {
    enableSendButton.value = true;
    return;
  }
  enableSendButton.value = false;
})

/* Form*/

onMounted(function () {
  if (Object.keys(props.user.groups).length === 1) {
    choose_market.value = props.user.groups[0].id;
    chooseMarket();
    marketLeader();
  }
  verifications();
});

function verifications() {
  if (props.user?.verifications?.verifications) {
    reachable.value = props.user?.verifications?.verifications?.reachable;
    phonenumber.value = props.user?.verifications?.verifications?.phonenumber;
    corresponding_name.value = props.user?.verifications?.verifications?.corresponding_name;
    market_name_verified.value = props.user?.verifications?.verifications?.market_name_verified;
    products.value = props.user?.verifications?.verifications?.products_they_buy;
    products_selling.value = props.user?.verifications?.verifications?.products_they_selling;
    consented_to_being_on_amana_market.value =
        props.user?.verifications?.verifications?.consented_to_being_on_amana_market;
    readterms.value = props.user?.verifications?.verifications?.read_them_text_to_complete_verification;
    amamavalue.value = props.user?.verifications?.verifications?.read_to_them_benefits_of_amana;
  }
}

function verify() {
  verifying.value = true;
  axios.post('/apis/v4/verifications', {
    'questions': {
      'reachable': reachable.value,
      'phonenumber': phonenumber.value,
      'corresponding_name': corresponding_name.value,
      'market_name_verified': market_name_verified.value,
      'products_they_buy': products.value,
      'products_they_selling': products_selling.value,
      'consented_to_being_on_amana_market': consented_to_being_on_amana_market.value,
      'read_them_text_to_complete_verification': readterms.value,
      'read_to_them_benefits_of_amana': amamavalue.value,
    },
    'market_id': selectedMarket.value.id,
    'user_id': props.user.id,
    'auth_id': props.auth_id,
    'type': 'call_center'
  }).then(resp => {
    location.reload();
    verifying.value = false;
    Swal.fire({
      position: 'top-right',
      icon: 'success',
      title: 'Your have done compliance for this user',
      showConfirmButton: false,
      timer: 1500
    });

  }).catch(err => {
    verifying.value = false;
  }).finally(() => {
    location.reload();
    verifying.value = false;
  });
}

function formatDate(date) {
  return moment(date).format("dddd, MMMM Do YYYY, h:mm:ss a")
}

function chooseMarket() {
  selectedMarket.value = _.find(props.user.groups, function (o) {
    return o.id == choose_market.value;
  });
  marketLeader();
}

watch(reachable, function (newValue) {
  if (reachable.value === 'Yes') {
    showOtherQuestions.value = true
    return;
  }
  showOtherQuestions.value = false;
});

function marketLeader() {
  leader.value = _.find(selectedMarket.value.members, function (o) {
    return o.pivot.user_group_role_id == 'Market Leader';
  });
}

</script>

<template>
  <div>

    <div v-if="props.user.groups.length > 1">
      <div class="alert alert-warning" role="alert">
        <div class="d-flex">
          <div class="">
            <i class=" fa-light fa-circle-info fa-2x fa"></i>
          </div>
          <div class="align-self-center mx-3">
            <div>
              <strong>
                This member belongs to multiple markets. Can you choose the market you are verifying them for.
              </strong>
            </div>
            <div class="mt-3">
              <!--Label: Choose Marekt, Attributes:choose_market -->
              <div class="form-floating">
                <select type="text" class="form-select" id="choose_market" v-model="choose_market"
                        aria-describedby="choose_market-help"
                        @change="chooseMarket"
                        :class="[errors.choose_market ? 'is-invalid': '',!errors.choose_market && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        required>
                  <label for="choose_market">Choose Market</label>
                  <option value="" disabled>Select Market</option>
                  <option :value="group.id" v-for="group in user.groups">
                    {{ group.name ?? '' }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="errors.choose_market">
                  {{ errors.choose_market.toString() }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="">
      <div class="row">
        <div class="d-flex justify-content-end">
          <!--    <span class="badge bg-success">-->
          <!--      In Progress-->
          <!--      <i class="fa-solid fa-arrow-progress"></i>-->
          <!--    </span>-->
        </div>
      </div>
      <div class="row">
        <div class="col mt-3">
          <div class="row">
            <div class="col-sm-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h4 class="card-title">User Details</h4>

                    </div>
                    <div>
                      <button
                          data-bs-toggle="modal" data-bs-target="#auditsId"
                          class="btn btn-outline-secondary btn-sm">
                        View Usage History
                      </button>


                      <!-- Modal -->
                      <div class="modal fade" id="auditsId" tabindex="-1" role="dialog" aria-labelledby="audtsId"
                           aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h4 class="modal-title" id="audtsId">Audits</h4>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                              </button>
                            </div>
                            <div class="modal-body">
                              <Auditable
                                  :user_id="props.user.id"
                              ></Auditable>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="table-responsive">
                      <table class="table">
                        <tbody>
                        <tr>
                          <th>Name</th>
                          <td>{{ user.name }}</td>
                        </tr>
                        <tr>
                          <th>Last Logged In At</th>
                          <td>
                            <template v-if="user.last_login_at">
                              {{ formatDate(user.last_login_at) }}
                            </template>
                            <template v-else>
                            <span class="badge bg-danger">
                              This user has not logged into Amana Market
                            </span>
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <th>Phone Number</th>
                          <td>{{ user.phone }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Market Details</h4>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{{ selectedMarket?.name }}</td>
                      </tr>
                      <tr>
                        <th>Market Leader</th>
                        <td>
                          <template v-if="leader?.name">
                            {{ leader.name }}
                          </template>
                          <template>
                          <span class="badge bg-danger">
                            This market has no market leader
                          </span>
                          </template>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12" v-if="typeof user?.verifications?.metadata?.failed === 'object' &&
            Object.keys(user?.verifications?.metadata?.failed).length > 0">
              <div class="card mb-3">
                <div class="card-header">This user has some failed Verifications. Please redo the verifications
                  again.
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered table-inverse">
                      <tbody>
                      <tr v-for="(failed,key) in user?.verifications?.metadata?.failed">
                        <td scope="row">{{ key }}</td>
                        <td>
                          <i class="fa-solid fa-circle-xmark" style="color: red"></i>
                          Failed Verification
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <template v-if="Object.keys(selectedMarket).length > 0">
                <form action="">
                  <div class="row g-3">
                    <div class="col-md-6">
                      <!--Label: Was the user Reachable, Attributes:reachable -->
                      <div class="form-floating">
                        <select type="text" class="form-select" id="reachable" v-model="reachable"
                                aria-describedby="reachable-help"
                                :class="[errors.reachable ? 'is-invalid': '',!errors.reachable && Object.keys(errors).length > 1 ? 'is-valid': '']"
                        >
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                        <label for="reachable">Was the user Reachable</label>
                        <div class="invalid-feedback" v-if="errors.reachable">
                          {{ errors.reachable.toString() }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" v-if="showOtherQuestions">
                    <div class="row g-3 mt-3 animate__bounceInLeft">
                      <div class="col-md-6">
                        <!--Label: What is the caller ID Phone number, Attributes:phonenumber -->
                        <div class="form-floating">
                          <input type="text" class="form-control" id="phonenumber" v-model="phonenumber"
                                 aria-describedby="phonenumber-help"
                                 :class="[errors.phonenumber ? 'is-invalid': '',!errors.phonenumber && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                 required>
                          <label for="phonenumber">What is the caller ID Phone number</label>


                          <div class="invalid-feedback" v-if="errors.phonenumber">
                            {{ errors.phonenumber.toString() }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <!--Label: Does the name correspond to sign up details, Attributes:corresponding_name -->
                        <div class="form-floating">
                          <select type="text" class="form-select" id="corresponding_name" v-model="corresponding_name"
                                  aria-describedby="corresponding_name-help"
                                  :class="[errors.corresponding_name ? 'is-invalid': '',!errors.corresponding_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                  required>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                          <label for="corresponding_name">Does the name correspond to sign up details</label>

                          <div class="invalid-feedback" v-if="errors.corresponding_name">
                            {{ errors.corresponding_name.toString() }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <!--Label: Verify market name, Attributes:market_name_verified -->
                        <div class="form-floating">
                          <select type="text" class="form-select" id="market_name_verified"
                                  v-model="market_name_verified"
                                  aria-describedby="market_name_verified-help"
                                  :class="[errors.market_name_verified ? 'is-invalid': '',!errors.market_name_verified && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                  required>
                            <option value="Correct">Correct</option>
                            <option value="Incorrect">Incorrect</option>
                          </select>
                          <label for="market_name_verified">Verify market name</label>

                          <div class="invalid-feedback" v-if="errors.market_name_verified">
                            {{ errors.market_name_verified.toString() }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <!--Label: What products do they buy, Attributes:products -->
                        <div class="form-floating">
                          <input type="text" class="form-control" id="products" v-model="products"
                                 aria-describedby="products-help"
                                 :class="[errors.products ? 'is-invalid': '',!errors.products && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                 required>
                          <label for="products">What products do they buy</label>

                          <div class="invalid-feedback" v-if="errors.products">
                            {{ errors.products.toString() }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <!--Label: What products do they sell, Attributes:products_selling -->
                        <div class="form-floating">
                          <input type="text" class="form-control" id="products_selling" v-model="products_selling"
                                 aria-describedby="products_selling-help"
                                 :class="[errors.products_selling ? 'is-invalid': '',!errors.products_selling && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                 required>
                          <label for="products_selling">What products do they sell</label>
                          <div class="invalid-feedback" v-if="errors.products_selling">
                            {{ errors.products_selling.toString() }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <!--Label: Have they consented to being a member of Amana Market, Attributes:consented_to_being_on_amana_market -->
                        <div class="form-floating">
                          <select type="text" class="form-select" id="consented_to_being_on_amana_market"
                                  v-model="consented_to_being_on_amana_market"
                                  aria-describedby="consented_to_being_on_amana_market-help"
                                  :class="[errors.consented_to_being_on_amana_market ? 'is-invalid': '',!errors.consented_to_being_on_amana_market && Object.keys(errors).length > 1 ? 'is-valid': '']"
                                  required>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                          <label for="consented_to_being_on_amana_market">Have they consented to being a member of Amana
                            Market</label>

                          <div class="invalid-feedback" v-if="errors.consented_to_being_on_amana_market">
                            {{ errors.consented_to_being_on_amana_market.toString() }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="checkbox"
                                   v-model="readterms"
                                   class="form-check-input" name="consentdone" id="consentdone" value="checkedValue"
                                   checked>
                            I have read the following to the user: <br>
                            To complete your verification, please sign into Amana Market via amanamarket.ng or our app
                            on
                            app store or by dialing *483*4040# with your registered phone number
                          </label>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="checkbox"
                                   v-model="amamavalue"
                                   class="form-check-input" name="amanavalue" id="amanavalue" value="checkedValue"
                                   checked>
                            I have explained the value of Amana Market to the user including: <br>
                            You can login to amana market to 1) check and compare prices in other markets, 2) buy and
                            sell
                            to members of your market or other markets via a trusted system that your market leaders can
                            support 3) check whether or not the person you are buying or selling to has a history of bad
                            behavior using their phone number.
                          </label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-sm btn-outline-dark"
                                  v-if="enableSendButton"
                                  @click="verify"
                                  :disabled="verifying"
                          >
                            Complete
                            <i class="fa fa-spin fa-spinner" v-if="verifying"></i>
                            <i class="fa fa-thin fa-2xs fa-solid fa-clipboard-list-check" v-else></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </template>
              <template v-else>
                <div class="alert alert-danger rounded" role="alert">
                  <strong>This member has multiple markets</strong>
                  <p class="mt-3">Please select a market to continue.</p>
                </div>
              </template>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>