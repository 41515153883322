<script setup>
import {ref} from "vue";
import Swal from "sweetalert2";

const errors = ref({});
const name = ref('');
const type = ref('');
const percentage = ref('');
const saving = ref(false);

function createCharge() {
  saving.value = true;
  axios.post('/charge/create', {
    'name': name.value,
    'type': type.value,
    'percentage': percentage.value,
  }).then(resp => {
    name.value = '';
    type.value = '';
    percentage.value = '';
    errors.value= {};
    saving.value = false;

    Swal.fire({
      'text': "Charge Added Successfully",
      'position': 'center',
      showConfirmButton: true,
      showCloseButton: false,
      icon: 'success',
    }).then((result) => {
      if (result.isConfirmed) {
        location.reload();
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    });


  }).catch(err => {
    saving.value = false;
    errors.value = err.response.data.errors;
  });
}
</script>

<template>
  <form action="">
    <div class="row g-3">
      <div class="col-md-6">
        <!--Label: Charge Name, Attributes:name -->
        <div class="form-floating">
          <input type="text" class="form-control" id="name" v-model="name"
                 aria-describedby="name-help"
                 :class="[errors.name ? 'is-invalid': '',!errors.name && Object.keys(errors).length > 1 ? 'is-valid': '']"
          >
          <label class="form-label" for="name">Charge Name</label>

          <div class="invalid-feedback" v-if="errors.name">
            {{ errors.name.toString() }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!--Label: Charge Type, Attributes:type -->
        <div class="form-floating">
          <select type="text" class="form-control" id="type" v-model="type"
                  aria-describedby="type-help"
                  :class="[errors.type ? 'is-invalid': '',!errors.type && Object.keys(errors).length > 1 ? 'is-valid': '']"
          >
            <option value="" disabled>Select Type</option>
            <option value="Tax">Tax</option>
            <option value="Transaction fee">Transaction fee</option>
            <option value="Market Insurance Contribution">Market Insurance Contribution</option>
          </select>
          <label class="form-label" for="type">Select Type</label>

          <div class="invalid-feedback" v-if="errors.type">
            {{ errors.type.toString() }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!--Label: Percentage, Attributes:percentage -->
        <div class="form-floating">
          <input type="number" class="form-control" id="percentage" v-model="percentage"
                 aria-describedby="percentage-help"
                 :class="[errors.percentage ? 'is-invalid': '',!errors.percentage && Object.keys(errors).length > 1 ? 'is-valid': '']"
          >
          <label class="form-label" for="percentage">Percentage on Sales/Payment Made</label>

          <div class="invalid-feedback" v-if="errors.percentage">
            {{ errors.percentage.toString() }}
          </div>
        </div>
      </div >
      <div class="col-md-12 d-flex justify-content-end">
        <button class="btn btn-primary"
                :disabled="saving"
                id="createChargeButton"
                @click.prevent="createCharge">
          Create Charge
          <i class="fa fa-spin fa-spinner" v-if="saving"></i>
          <i class="fa fa-plus" v-else></i>
        </button>
      </div>
    </div>

  </form>
</template>

<style scoped>

</style>