import './bootstrap'
import $ from 'jquery';

// import Vue from 'vue'
import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
// import Flutterwave from  'flutterwave-vue-v3'
import { createApp } from 'vue';
import BulkSMS from './components/BulkSms/index.vue';
import MpesaAccountIndex from './components/BulkSms/index.vue';
import AssignOrder from './components/Orders/SupplyAgent/AssignOrder.vue';
import PriceIndex from './components/PriceUpdates/Index.vue';
import WalletIndex from './components/wallet/index.vue';
import PaymentIndex from './components/wallet/payments/Index.vue';
import RequestPayment from './components/wallet/requestpayment/Index.vue';
import CreateFees from "./components/rent_and_fees/Create.vue";
import CreateCharge from "./components/rent_and_fees/CreateCharge.vue";
import DeleteChargeFee from "./components/rent_and_fees/DeleteChargeFee.vue";
import MarketsIndex from "./components/User/Markets/MarketsIndex.vue";
import ReceivePayment from "./components/Payments/ReceivePayment.vue";
import MarketMembers from "./components/v4/market/members/Index.vue";
import WithDrawFromMarketWallet from "./components/wallet/market/WithDrawFromMarketWallet.vue";
import PayoutToMemberFromOtherEarnings from "./components/wallet/market/PayoutToMemberFromOtherEarnings.vue";
import RBACIndex from "./components/v4/admin/rbac/RBACIndex.vue";
import AddUser from "./components/v4/admin/rbac/AddUser.vue";
import ComplianceIndex from "./components/v4/compliance/ComplianceIndex.vue";
import MarketCompliance from "./components/v4/market/compliance/MarketCompliance.vue";
import MarketComplianceFinance from "./components/v4/finance/compliance/MarketComplianceFinance.vue";
import FinanceUserCompliance from "./components/v4/compliance/FinanceUserCompliance.vue";
import CameraCapture from "./components/userpicture/CameraCapture.vue";
import PayForOrder from "./components/wallet/orders/PayForOrder.vue";
import UpcomingPayments from "./components/Landing/UpcomingPayments.vue";
import AcceptOrder from "./components/AcceptOrder.vue";
import MarketUpcomingPayments from "./components/v4/market/MarketUpcomingPayments.vue";
import IdentityVerification from "./components/v4/member_profile/IdentityVerification.vue";
import MemberVerification from "./components/v4/market/compliance/MemberCompliance/Index.vue";
import MarketVerification from "./components/v4/market/compliance/MarketCompliance/Index.vue";
import { createPinia } from 'pinia'
import PersonalInformationEditing from "./components/v4/profile/PersonalInformationEditing.vue";
import UpdatePassword from "./components/v4/profile/UpdatePassword.vue";
import UpdateAccountInformation from "./components/v4/profile/UpdateAccountInformation.vue";
import MarketProfile from "./components/v4/profile/MarketProfile/Index.vue";
import LoginPage from "./components/v4/signin/LoginPage.vue";
import MarketChanger from "./components/MarketChanger.vue";
import MarketCreation from "./components/Admin/Markets/MarketCreation.vue";
import FinanceSettings from "./components/v4/finance/settings/Index.vue";
import MemberManagement from "./components/v4/market/members/MemberManagement.vue";
import ProfilePage from "./components/User/Profile/ProfilePage.vue";
import UserAvatar from "./components/v4/profile/UserAvatar.vue";
import UserNotifications from "./components/v4/profile/UserNotifications.vue";
import WebsiteInformation from "./components/v4/profile/WebsiteInformation.vue";
import SummaryCards from "./components/v4/admin/loan_book/SummaryCards.vue";
const pinia = createPinia()
const app = createApp({});

// app.use(Flutterwave, { publicKey: import.meta.env.VITE_FLUTTERWAVE_PUBLIC_KEY } )

window.axios = axios;
app.component('bulksms-index', BulkSMS);
app.component('mpesa-accountsetup', MpesaAccountIndex);
app.component('supply-agent-assign-order', AssignOrder);
app.component('VueDatePicker', VueDatePicker);


// Price Updates
app.component('priceindex', PriceIndex);

// Wallet
app.component('top-up-wallet', WalletIndex);
app.component('make-wallet-payment', PaymentIndex);
app.component('request-payment', RequestPayment);

// fees_rent_and_taxes
app.component('create-fees', CreateFees);
app.component('create-charge', CreateCharge);
app.component('delete-charge-fee', DeleteChargeFee);

app.component('markets-index', MarketsIndex);
app.component('markets-members', MarketMembers);

app.component('payments-index', ReceivePayment);


app.component('loan-book-summary-cards', SummaryCards);
// withdraw
app.component('market-wallet-withdraw', WithDrawFromMarketWallet);
app.component('payout-to-member-from-earnings', PayoutToMemberFromOtherEarnings);

app.component('rbac-index', RBACIndex);
app.component('rbac-add-admin', AddUser);

app.component('compliance-index', ComplianceIndex);
app.component('market-compliance-index', MarketCompliance);
app.component('market-compliance-finance-index', MarketComplianceFinance);
app.component('finance-user-compliance', FinanceUserCompliance);

app.component('camera-capture', CameraCapture);
app.component('payfororder', PayForOrder);
app.component('upcomingpayments', UpcomingPayments);
app.component('marketupcomingpayments', MarketUpcomingPayments);
app.component('acceptorder', AcceptOrder);

app.component('identityverification', IdentityVerification);
app.component('memberverification', MemberVerification);
app.component('marketverification', MarketVerification);
app.component('personalinfoedit', PersonalInformationEditing);
app.component('updatepassword', UpdatePassword);
app.component('accountinformation', UpdateAccountInformation);
app.component('MarketProfile', MarketProfile);
app.component('LoginPage', LoginPage);
app.component('market-changer', MarketChanger);
app.component('upcoming-payments-page', UpcomingPayments);
app.component('create-market', MarketCreation);
app.component('finance-settings', FinanceSettings);

app.component('rbac-index', RBACIndex);
app.component('member-management', MemberManagement);

/*Profile page*/
app.component('my-profile-management', ProfilePage);
app.component('user-avatar', UserAvatar);
app.component('notifications', UserNotifications);


// app.component('pending-payments-component', require('./components/BulkPayments/PendingPaymentIndex.vue').default);

app.config.productionTip = false;
app.use(pinia)
app.mount('#app')
