<template>
    <div>
        <div action=""> <!--Label: For you, Attributes:for_you -->
            <!--      <div class="form-group">-->
            <!--        <label for="for_you">For you</label>-->
            <!--        <select type="text" class="form-control" id="for_you" v-model="for_you"-->
            <!--                disabled-->
            <!--                aria-describedby="for_you-help"-->
            <!--                :class="[errors.for_you ? 'is-invalid': '',!errors.for_you && Object.keys(errors).length > 1 ? 'is-valid': '']"-->
            <!--        >-->
            <!--          <option value="for_you">Yes</option>-->
            <!--          <option value="for_you">No</option>-->
            <!--        </select>-->
            <!--        <div class="invalid-feedback" v-if="errors.for_you">-->
            <!--          {{ errors.for_you.toString() }}-->
            <!--        </div>-->
            <!--      </div>-->
            <!--      &lt;!&ndash;Label: Amount to Top Up, Attributes:amount &ndash;&gt;-->
            <div class="row">
                <div class="form-floating mt-3">
                    <input type="text" class="form-control" id="amount" v-model.number="amount"
                           aria-describedby="amount-help"
                           :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                    >
                    <label for="amount" class="form-label text-black p-3">Amount to Top Up</label>

                    <div class="invalid-feedback" v-if="errors.amount">
                        {{ errors.amount.toString() }}
                    </div>
                </div>

                <template v-if="locale === 'ke'">
                    <!--Label: Phone Number, Attributes:phone -->
                    <div class="form-floating mt-3">
                        <input type="text" class="form-control" id="phone" v-model="phone"
                               aria-describedby="phone-help"
                               :class="[errors.phone ? 'is-invalid': '',!errors.phone && Object.keys(errors).length > 1 ? 'is-valid': '']"
                               >
                        <label for="phone">Phone Number</label>
                        <div class="invalid-feedback" v-if="errors.phone">
                            {{ errors.phone.toString() }}
                        </div>
                    </div>
                    <div class="mt-3" id="topupaccount">
                        <button class="btn btn-primary" @click.prevent="depositViaMPESA">
                            Top Up
                            <i class="fa fa-spinner fa-spin"></i>
                        </button>
                    </div>
                </template>
                <template v-else>
                    <div class="form-floating mt-3">
                        <input type="text" class="form-control" name="gatewayfees" id="gatewayfees"
                               aria-describedby="gatewayHelpId"
                               disabled
                               :value="gatewayfees"
                               placeholder="gatewayfees">
                        <label for="gatewayfees" class="form-label text-black">Total Including Gateway Fees </label>

                        <small id="gatewayHelpId" class="form-text text-muted">Fees charged by our payment provider.</small>
                    </div>

                    <div class="mt-3" id="topupaccount">
                        <paystack
                            buttonClass="btn btn-primary"
                            buttonText="Top Up"
                            :publicKey="publicKey"
                            :email="email"
                            :amount="gatewayfees * 100"
                            :reference="reference"
                            :onSuccess="onSuccessfulPayment"
                            :onCancel="onCancelledPayment">
                        </paystack>
                    </div>
                </template>
            </div>
        </div>

    </div>
</template>
<script setup>
const emit = defineEmits(['walletToppedUp', 'submit'])

</script>
<script>
// import {FlutterwavePayButton} from "flutterwave-vue-v3"
import paystack from "vue3-paystack";

export default {
    name: "inlinetopup",
    props: [
        'amountToPay'
    ],
    components: {
        paystack,
    },
    mounted() {
        this.amount = this.amountToPay ?? 500;

        this.user = JSON.parse(document.querySelector('meta[name="user"]').content);

        this.paymentData.currency = this.currency;
        this.paymentData.meta.consumer_id = this.user.id;
        this.paymentData.meta.consumer_phone = this.user.phone;
        this.paymentData.customer.name = this.user.name;
        this.paymentData.customer.email = this.user.email ?? 'info@coamana.com';
        this.email = this.user.email ?? 'info@coamana.com';
        this.paymentData.customer.phone_number = this.user.phone;
    },
    data() {
        return {
            errors: '',
            gatewayfees: 500,
            withdrawalId: '',
            transactionInitiated: false,
            saving: false,
            for_you: 'for_you',
            user: '',
            amount: 1000,
            publicKey: import.meta.env.VITE_PAYSTACK_PUBLIC_KEY,
            email: 'info@coamana.com',
            reference: this.generateReference(),
            paymentData: {
                tx_ref: this.generateReference(),
                amount: this.amount,
                currency: "NGN",
                payment_options: "card,ussd",
                redirect_url: ``,
                meta: {
                    consumer_id: '',
                    consumer_phone: '',
                    consumer_mac: "kjs9s8ss7dd"
                },
                customer: {
                    name: "",
                    email: "",
                    phone_number: ""
                },
                customizations: {
                    title: "Amana Market",
                    description: "Amana Market",
                    logo: "https://flutterwave.com/images/logo-colored.svg"
                },
                callback: this.makePaymentCallback,
                onclose: this.closedPaymentModal
            },

            bank: '',
            account_number: '',
            totalamount: 0,
            amount_to_withdraw: '',
            otp: '',
            complete: false,
            message: "",
            phone: "",
            locale: import.meta.env.VITE_LOCALIZATION_COUNTRY_ABBR,
        }
    },
    methods: {
        depositViaMPESA(){
            let Self = this;

            this.depositing = true;
            axios.post('/apis/v4/topup/wallet/mpesa',{
                'phone': this.phone,
                'amount': this.amount,
                'reason': 'topup'
            }).then((resp)=>{
                this.depositing = false;
                Self.$emit('walletToppedUp', resp.data);
                emit('walletToppedUp', resp.data)
            }).finally(()=>{

            });
        },
        totalAmount(amountPassed) {
            this.amount = amountPassed;
        },
        sendOtp() {
            this.complete = true;
            this.saving = true;
            axios.post('/api/withdraw/otp', {
                'bank_id': this.bank,
                'account_number': this.account_number,
                'user_id': this.user.id,
                'amount': this.amount_to_withdraw
            }).then(resp => {
                this.saving = false;
                this.withdrawalId = resp.data.id;
            }).finally(() => {
                this.saving = false;
            });
        },
        changeStuff() {
            // `this` points to the vm instance
            let paystackFees = 0;

            if (parseInt(this.amount) <= 2000) {
                paystackFees = (this.amount * 0.015);
            } else {
                paystackFees = (this.amount * 0.015) + 100;
            }
            return this.amount + paystackFees;
        },
        onSuccessfulPayment(response) {
            let Self = this;
            axios.post('/api/wallet/topup', {
                success: response,
                payload: this.paymentData
            }).then(resp => {
                Self.$emit('walletToppedUp', resp.data);
                emit('walletToppedUp', resp.data)
            }).catch(err => {
            })

        },
        onCancelledPayment(response) {
            console.log(response);
        },
        makePaymentCallback(response) {
            axios.post('/api/wallet/topup', {
                success: response,
                payload: this.paymentData
            }).then(resp => {
                // window.location.reload();
            }).catch(err => {
                // window.location.reload();
            })
        },
        closedPaymentModal() {
            console.log('payment modal is closed');
        },
        generateReference() {
            let date = new Date();
            return date.getTime().toString();
        }
    },
    computed: {
        totalamount: function () {
            let amount = 0;

            if (this.amount_to_withdraw < 5000) {
                amount = this.amount_to_withdraw + 10;
            }

            if (this.amount_to_withdraw > 5000 && this.amount_to_withdraw <= 49999) {
                amount = this.amount_to_withdraw + 25;
            }

            if (this.amount_to_withdraw >= 50000) {
                amount = this.amount_to_withdraw + 50;
            }
            this.totalamount = amount;
            return amount;
        },
        chargedAmount: function () {
            // `this` points to the vm instance
            return this.amount
        },
        gatewayfees: function () {
            // `this` points to the vm instance
            let paystackFees = 0;

            if (parseInt(this.amount) <= 2000) {
                paystackFees = (this.amount * 0.015);
            } else {
                paystackFees = (this.amount * 0.015) + 100;
            }
            let total = paystackFees + 160;

            if (total >= 2500) {
                total = 2500;
            }
            return this.amount + total;
        }
    },
    watch: {
        amount(newValue, oldValue) {
            this.paymentData.amount = newValue
        }
    }
}
</script>

<style scoped>

</style>
