<script setup>
import axios from "axios";
import { onMounted, ref } from "vue";

const props = defineProps(["member", "group_id"]);
const locale = import.meta.env.VITE_LOCALIZATION_COUNTRY_ABBR;
const openUpgrade = ref(false);

const loading = ref(false);

const approveAsMemberValue = !!ref(props.member?.pivot?.approved).value;
const approveForCreditValue = !!ref(
    props.member?.pivot?.approved_for_credit_purchase
).value;
const blacklistedValue = !!ref(props.member?.pivot?.black_listed).value;

const blacklist = () => {
    loading.value = true;
    axios
        .post(`/c/group-member/${props.member?.id}/blackList`, {
            group_id: props?.group_id,
        })
        .then((resp) => {
            loading.value = false;
            window.location.reload();
        })
        .catch((err) => {
            loading.value = false;
            this.errors = err.response.data.errors;
        });
};
const removeUserFromMarket = () => {
    loading.value = true;
    axios
        .post(`/c/group-member/${props.member?.id}/remove`, {
            group_id: props?.group_id,
        })
        .then((resp) => {
            loading.value = false;
            window.location.reload();
        })
        .catch((err) => {
            loading.value = false;
            this.errors = err.response.data.errors;
        });
};

const approve = () => {
    loading.value = true;
    axios
        .post(`/c/group-member/${props.member?.id}/approve`, {
            group_id: props?.group_id,
        })
        .then((resp) => {
            loading.value = false;
            window.location.reload();
        })
        .catch((err) => {
            loading.value = false;
            this.errors = err.response.data.errors;
        });
};
const approveForCredit = () => {
    loading.value = true;
    axios
        .post(`/c/group-member/${props.member?.id}/approveforcredit`, {
            group_id: props?.group_id,
        })
        .then((resp) => {
            loading.value = false;
            window.location.reload();
        })
        .catch((err) => {
            loading.value = false;
            this.errors = err.response.data.errors;
        });
};
const toggleOpenUpgrade = () => {
    openUpgrade.value = !openUpgrade.value;
};
</script>

<template>
    <div
        class="d-flex flex-row transaction-history-success-table py-2 px-4 my-2"
        style="border-radius: 0.25rem; border: 0.2px solid rgb(29, 36, 46)"
    >
        <div class="d-flex flex-column col-8">
            <div>
                <strong>{{ member.name }}</strong>
            </div>
            <div class="success-text">
                {{ member.role }}
            </div>
        </div>
        <div class="d-flex flex-column col-2">
            <div>{{ member.phone }}</div>
            <div
                class="success-text"
                style="cursor: pointer"
                @click="toggleOpenUpgrade"
            >
                <strong>View</strong>
            </div>
        </div
        >
    </div>

    <div
        v-if="openUpgrade"
        style="
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: grid;
            place-items: center;
            padding: 1rem;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 50;
        "
    >
        <div
            class="card mb-3"
            style="
                position: relative;
                border-radius: 0.25rem;
                border: 0.2px solid #fff;
                box-shadow: none;
                max-width: 500px;
                width: 95%;
                padding: 1rem;
            "
        >
            <svg
                style="
                    position: absolute;
                    top: 0.5rem;
                    right: 0.5rem;
                    cursor: pointer;
                "
                @click="toggleOpenUpgrade"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_8_6557)">
                    <path
                        d="M13.89 8.7L12 10.59L10.11 8.7C9.72 8.31 9.09 8.31 8.7 8.7C8.31 9.09 8.31 9.72 8.7 10.11L10.59 12L8.7 13.89C8.31 14.28 8.31 14.91 8.7 15.3C9.09 15.69 9.72 15.69 10.11 15.3L12 13.41L13.89 15.3C14.28 15.69 14.91 15.69 15.3 15.3C15.69 14.91 15.69 14.28 15.3 13.89L13.41 12L15.3 10.11C15.69 9.72 15.69 9.09 15.3 8.7C14.91 8.32 14.27 8.32 13.89 8.7ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                        fill="#1D242E"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_8_6557">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>

            <div
                class="card-body"
                style="
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 1rem;
                "
            >
                <h4 class="text-success">
                    {{ member.name }}
                    <span class="fa fa-spin fa-spinner" v-if="loading"></span>
                </h4>

                <div
                    style="
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 0.2rem;
                        width: 100%;
                    "
                >
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <span style="font-weight: 700">Phone:</span>
                        {{ member?.formattedphone ?? "" }}
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <span style="font-weight: 700">Gender:</span>
                        {{ member?.gender ?? "" }}
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <span style="font-weight: 700">Age:</span>
                        {{ member?.year_of_birth ?? "" }}
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <span style="font-weight: 700">Designation:</span>
                        {{ member?.role ?? "" }}
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <template v-if="locale === 'ke'">
                            <span style="font-weight: 700">KRA PIN:</span>
                        </template>
                        <template v-else>
                            <span style="font-weight: 700">BVN:</span>
                        </template>
                        {{ member?.bvn ?? "" }}
                    </p>
                    <p style="margin: 0; color: #1d242e; font-size: 0.875rem">
                        <span style="font-weight: 700">NIN:</span>
                        {{ member?.national_id ?? "" }}
                    </p>
                    <!-- <p style="margin: 0; color: #1d242e; font-size: 0.875rem"> -->
                    <!--     <span style="font-weight: 700">Shop Address:</span> -->
                    <!--     {{ member?.formattedphone ?? "" }} -->
                    <!-- </p> -->

                    <div
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.25rem;
                            border: 0.4px solid #1d242e;
                            padding: 0.5rem 1.2rem;
                            width: 100%;
                            margin-top: 0.5rem;
                            margin-bottom: 0.5rem;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 0rem;
                            "
                        >
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                    font-weight: 700;
                                "
                            >
                                Approval as Member
                            </p>
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                "
                            >
                                This member is <span>not</span> approved
                            </p>
                        </div>
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="approveAsMember"
                                v-model="approveAsMemberValue"
                                @click="approve"
                                :disabled="loading"
                            />
                        </div>
                    </div>
                    <div
                        style="
                            margin-bottom: 0.5rem;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.25rem;
                            border: 0.4px solid #1d242e;
                            padding: 0.5rem 1.2rem;
                            width: 100%;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 0rem;
                            "
                        >
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                    font-weight: 700;
                                "
                            >
                                Approval for Credit
                            </p>
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                "
                            >
                                This member is <span>not</span> approved for
                                credit
                            </p>
                        </div>
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="approveForCredit"
                                v-model="approveForCreditValue"
                                @click="approveForCredit"
                                :disabled="loading"
                            />
                        </div>
                    </div>
                    <div
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 0.5rem;
                            border-radius: 0.25rem;
                            border: 0.4px solid #1d242e;
                            padding: 0.5rem 1.2rem;
                            width: 100%;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                gap: 0rem;
                            "
                        >
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                    font-weight: 700;
                                "
                            >
                                ❌Blacklist member
                            </p>
                            <p
                                style="
                                    margin: 0;
                                    color: #1d242e;
                                    font-size: 0.875rem;
                                "
                            >
                                This member <span>not</span> is blacklisted
                            </p>
                        </div>
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="blacklistedValue"
                                v-model="blacklistedValue"
                                @click="blacklist"
                                :disabled="loading"
                            />
                        </div>
                    </div>
                    <div class="row mt-3 d-flex justify-content-right">
                        <div class="">
                            <button class="btn btn-sm  btn-outline-danger" @click="removeUserFromMarket">
                                <i class="fa fa-trash fa-sm"></i>
                                Remove From My Market
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
