<script setup>
import {ref} from "vue";

const errors = ref({});
const first_name = ref('');
const last_name = ref('');
const phone_number = ref('');
const saving = ref(false);

function addUser() {
  saving.value = true;
  axios.post('/apis/v4/users', {
    'first_name': first_name.value,
    'last_name': last_name.value,
    'phone_number': phone_number.value,
  }).then(resp => {
    saving.value = false;
    location.reload();
  }).catch(err => {
    errors.value = err.response.data.errors;
  });
}

</script>

<template>
  <div class="card">
    <div class="card-body">
      <form class="row g-3">
        <div class="col-md-12">
          <!--Label: First Name, Attributes:first_name -->
          <div class="form-group">
            <label for="first_name">First Name</label>
            <input type="text" class="form-control" id="first_name" v-model="first_name"
                   aria-describedby="first_name-help"
                   :class="[errors.first_name ? 'is-invalid': '',!errors.first_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                   placeholder="First Name" required>
            <div class="invalid-feedback" v-if="errors.first_name">
              {{ errors.first_name.toString() }}
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <!--Label: Last Name, Attributes:last_name -->
          <div class="form-group">
            <label for="last_name">Last Name</label>
            <input type="text" class="form-control" id="last_name" v-model="last_name"
                   aria-describedby="last_name-help"
                   :class="[errors.last_name ? 'is-invalid': '',!errors.last_name && Object.keys(errors).length > 1 ? 'is-valid': '']"
                   placeholder="Last Name" required>
            <div class="invalid-feedback" v-if="errors.last_name">
              {{ errors.last_name.toString() }}
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <!--Label: Phone Number, Attributes:phone_number -->
          <div class="form-group">
            <label for="phone_number">Phone Number</label>
            <input type="text" class="form-control" id="phone_number" v-model="phone_number"
                   aria-describedby="phone_number-help"
                   :class="[errors.phone_number ? 'is-invalid': '',!errors.phone_number && Object.keys(errors).length > 1 ? 'is-valid': '']"
                   placeholder="Phone Number" required>
            <div class="invalid-feedback" v-if="errors.phone_number">
              {{ errors.phone_number.toString() }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <button class="btn btn-outline-dark"
                  :disabled="saving"
                  @click.prevent="addUser"
          >
            Add Admin User
            <i class="fa-solid fa-wand-magic-sparkles" v-if="!saving"></i>
            <i class="fa-spin fa-spinner" v-else></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>

</style>