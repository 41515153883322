<script setup>
import SearchforSeller from "../wallet/payments/searchforSeller.vue";
import Stepper from "bs-stepper";
import {onMounted, ref} from "vue";
import PaymentPurpose from "../wallet/payments/PaymentPurpose.vue";
import PaymentSummary from "../wallet/payments/PaymentSummary.vue";
import SendOTPForPayment from "../wallet/payments/sendOTPForPayment.vue";
import moment from "moment";
import InlineTopUp from "../wallet/payments/inlinetopup/InlineTopUp.vue";
import ResendOTP from "../ResendOTP.vue";

const props = defineProps([
    "borrowinglimit_clear",
    "balance_clear",
    "buyer_group_id",
    "market",
    "productstock",
    "members",
]);

const user = ref({});
const user_id = ref(null);
const product = ref({});
const payment = ref("");
const amount = ref("");
const quantity = ref("");
const paymentsummary = ref("");
const inlineTopUp = ref("");
const paymentMethod = ref("");
const makePayment = ref(true);
const sendOTPToUser = ref(false);
const finishing = ref(false);
const showSummary = ref(false);
const numberofdays = ref("");
const selectedmethod = ref("");
const otp = ref(false);
const errors = ref({});
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const borrowingLimitNew = ref(0);

const stepper1 = ref(null);
const childRef = ref();


const setChildRef = (el) => {
    childRef.value = el;
};

function handleNextStepForAmount() {
    if (amount.value === "") {
        payment.value.productsRef.setAmountError("Please enter amount");
        return;
    }
    stepperNextPayment();
}

function changeUserWhoIsLoggedIn(data) {
    user_id.value = data;

    axios
        .post("/apis/v4/walletbalances", {
            user_id: user_id.value,
        })
        .then((response) => {
            walletBalance.value = response.data.balance;
            borrowingLimitNew.value = response.data.credit_balance;
            paymentsummary.value.updateBalanceCredit(
                response.data.credit_balance
            );
        });
}

function walletToppedUp(amount) {
    paymentsummary.value.updateBalance(amount.current_balance);
    walletBalance.value = amount.current_balance;

    var myModalEl = document.querySelector("#topupInline");
    var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

    modal.hide();
    makeOrder();
}

defineExpose({
    walletToppedUp,
});

function updateTopUpAMount() {
    inlineTopUp.value.totalAmount(
        parseInt(paymentTotal.value) - parseInt(props.balance_clear)
    );

    axios
        .post("/apis/v4/validate_send_otp", {
            otp: otp.value,
            code: otp.value,
            key: `user_id:${user_id.id}code:${otp.value}:makepayment`,
        })
        .then((resp) => {
            var myModalEl = document.querySelector("#topupInline");
            var modal = bootstrap.Modal.getOrCreateInstance(myModalEl);

            modal.show();
        })
        .catch((err) => {
            errors.value = err.response.data.errors;
        });
}

function calculateDays(days) {
    return moment().add(days, "days");
}

function setUser(data) {
    // debugger;
    if (product.value[0] === "skip") {
        user.value = data;
        payment.value.purpose = "skip";
        payment.value.products = {};
        payment.value.productsRef.userid = data.id;
        // goToNextPage();
    } else {
        user.value = data;
        payment.value.purpose = "";
        payment.value.products = {};
        payment.value.productsRef.userid = data.id;
        payment.value.productsRef.getItems();
        // goToNextPage();
    }
}

function previous() {
    // debugger;
    stepper1.value.previous();
    childRef.value.stopTimer();
}

function previousWithReset() {
    // debugger;
    key.value++;
    stepper1.value.previous();
}

function skipproduct() {
    goToNextPage();
}

function setProduct(data) {
    if (data === "skip") {
        product.value = ["skip"];
        payment.value.productsRef.purpose = "skip";
        // goToNextPage();
        key.value++;
        return;
    }
    product.value = data;
    payment.value.productsRef.userid = data.id;
    payment.value.productsRef.selectProduct(data);
    // payment.value.productsRef.value.getItems()
    goToNextPage();
}

function goToNextPage() {
    stepper1.value.next();
}

function payments(enabled, method) {
    makePayment.value = enabled;
    paymentMethod.value = method;
}

const paymentTotal = ref(0);
const walletBalance = ref(0);
const searchForSellerRef = ref("");
onMounted(() => {
    walletBalance.value = props.balance_clear;
    borrowingLimitNew.value = props.borrowinglimit_clear;
    paymentsummary.value.updateCreditBalance(props.borrowinglimit_clear);

    if (props.productstock) {
        searchForSellerRef.value.updateSearch(
            props.productstock.member.phone,
            props.productstock
        );
    }
    stepper1.value = new Stepper(document.querySelector("#stepper1"));
});

function totalBilled(amount) {
    paymentTotal.value = amount;
}

function sendOTP() {
    sendOTPToUser.value = true;

    let buyer = JSON.parse(document.querySelector('meta[name="user"]').content);

    axios
        .post("/api/paywithwallet/sendOTP", {
            product: product.value,
            quantity: quantity.value,
            amount: amount.value,
            seller: user.value,
            user_id: user_id.value ?? buyer.id,
            type: paymentMethod.value,
        })
        .then(() => {
            sendOTPToUser.value = true;
            childRef.value.startTimer();
        });
}

function makeOrder() {
    let buyer = JSON.parse(document.querySelector('meta[name="user"]').content);
    finishing.value = true;
    axios
        .post("/api/paywithwallet/makeorder", {
            product: product.value,
            quantity: quantity.value ?? 1,
            amount: amount.value,
            seller: user.value,
            user_id: user_id.value ? user_id.value : buyer.id,
            buyer_group_id: props.buyer_group_id,
            paymentMethod: paymentMethod.value,
            number_of_days: numberofdays.value,
            otp: otp.value,
            total: paymentTotal.value,
        })
        .then(() => {
            errors.value = {};
            finishing.value = false;
            showSummary.value = true;
            // location.reload();
        })
        .catch((err) => {
            finishing.value = false;
            errors.value = err.response.data.errors;
        });
}

function check() {
    paymentsummary.value.updatePaymentMethod("wallet");
    paymentsummary.value.resetMethod();
}

function productset(productData) {
    product.value = productData;
}

function stepperNext() {
    stepper1.value.next();
}

function stepperNextPayment() {
    stepper1.value.next();
}

function stepperNextOTP() {
    sendOTP();
    stepper1.value.next();
}

const key = ref(0);
</script>
<template>
    <div>
        <div id="stepper1" class="bs-stepper" v-if="!showSummary">
            <div class="card">
                <div class="card-header">
                    <div
                        class="d-lg-flex flex-lg-row align-items-lg-center justify-content-lg-between"
                        role="tablist"
                        style="display: none !important"
                    >
                        <div class="step" data-target="#test-l-1">
                            <div
                                class="step-trigger"
                                role="tab"
                                id="stepper1trigger1"
                                aria-controls="test-l-1"
                            >
                                <div class="bs-stepper-circle">1</div>
                                <div class="">
                                    <h5 class="mb-0 steper-title">
                                        Seller Information
                                    </h5>
                                    <p class="mb-0 steper-sub-title">
                                        Enter the sellers phone number.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="bs-stepper-line"></div>
                        <div class="step" data-target="#test-l-2">
                            <div
                                class="step-trigger"
                                role="tab"
                                id="stepper1trigger2"
                                aria-controls="test-l-2"
                            >
                                <div class="bs-stepper-circle">2</div>
                                <div class="">
                                    <h5 class="mb-0 steper-title">
                                        Product Details
                                    </h5>
                                    <p class="mb-0 steper-sub-title">
                                        Choose a product.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="bs-stepper-line"></div>
                        <div class="step" data-target="#test-l-3">
                            <div
                                class="step-trigger"
                                role="tab"
                                id="stepper1trigger3"
                                aria-controls="test-l-3"
                            >
                                <div class="bs-stepper-circle">3</div>
                                <div class="">
                                    <h5 class="mb-0 steper-title">
                                        Payment Details
                                    </h5>
                                    <p class="mb-0 steper-sub-title">
                                        Choose Payment Method.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="bs-stepper-line"></div>
                        <div class="step" data-target="#test-l-4">
                            <div
                                class="step-trigger"
                                role="tab"
                                id="stepper1trigger4"
                                aria-controls="test-l-4"
                            >
                                <div class="bs-stepper-circle">4</div>
                                <div class="">
                                    <h5 class="mb-0 steper-title">
                                        Confirmation
                                    </h5>
                                    <p class="mb-0 steper-sub-title">
                                        Checkout
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="bs-stepper-content">
                        <form onSubmit="return false">
                            <div
                                id="test-l-1"
                                role="tabpanel"
                                class="bs-stepper-pane"
                                aria-labelledby="stepper1trigger1"
                            >
                                <h5 class="mb-1">Seller Information</h5>
                                <p class="mb-4">
                                    Please enter the phone number of the user
                                    you want to purchase from
                                </p>

                                <div class="row g-3">
                                    <searchfor-seller
                                        ref="searchForSellerRef"
                                        :members="members"
                                        @userSelected="setUser"
                                        @productskipped="skipproduct"
                                        :borrowinglimit_clear="
                                            borrowingLimitNew
                                        "
                                        @loggedInUserUpdate="
                                            changeUserWhoIsLoggedIn
                                        "
                                        @productChosen="setProduct"
                                    >
                                    </searchfor-seller>
                                    <div class="col-12 col-lg-6">
                                        <!--                    <button class="btn btn-primary px-4" @click.prevent="stepperNext">Next-->
                                        <!--                      <i class='bx bx-right-arrow-alt ms-2'></i>-->
                                        <!--                    </button>-->
                                    </div>
                                </div>
                                <!---end row-->
                            </div>

                            <div
                                id="test-l-2"
                                role="tabpanel"
                                class="bs-stepper-pane"
                                aria-labelledby="stepper1trigger2"
                            >
                                <h5 class="mb-1">
                                    Product or Service Information
                                </h5>
                                <div class="row g-3">
                                    <payment-purpose
                                        :user="user"
                                        :key="key"
                                        ref="payment"
                                        @setproduct="productset"
                                        @amountsetevent="(e) => (amount = e)"
                                        @quantitysetevent="
                                            (e) => (quantity = e)
                                        "
                                    ></payment-purpose>
                                    <div class="col-12">
                                        <div
                                            class="d-flex align-items-center gap-3"
                                        >
                                            <button
                                                :style="{
                                                    color: '#ff7900 !important',
                                                }"
                                                class="btn warn-button-border"
                                                @click.prevent="
                                                    previousWithReset
                                                "
                                            >
                                                <i
                                                    class="bx bx-left-arrow-alt me-2"
                                                    :style="{
                                                        color: '#ff7900 !important',
                                                    }"
                                                ></i
                                                >Previous
                                            </button>
                                            <button
                                                class="btn add-button-coamana text-white"
                                                @click.prevent="
                                                    handleNextStepForAmount
                                                "
                                            >
                                                Next<i
                                                class="bx bx-right-arrow-alt ms-2"
                                            ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!---end row-->
                            </div>

                            <div
                                id="test-l-3"
                                role="tabpanel"
                                class="bs-stepper-pane"
                                aria-labelledby="stepper1trigger3"
                            >
                                <div class="row g-3 mt-5">
                                    <PaymentSummary
                                        ref="paymentsummary"
                                        @enablepayment="payments"
                                        @totalamountbilled="totalBilled"
                                        :product="product"
                                        :market="market"
                                        :key="key"
                                        :amount="amount"
                                        :quantity="quantity"
                                        @selectedmethod="
                                            (args) => (selectedmethod = args)
                                        "
                                        @updatenumberofdays="
                                            (args) => (numberofdays = args)
                                        "
                                        :borrowinglimit_clear="
                                            borrowingLimitNew
                                        "
                                        :borrowinglimit_clear_prop="
                                            borrowingLimitNew
                                        "
                                        :balance_clear_prop="walletBalance"
                                    ></PaymentSummary>

                                    <div class="col-12">
                                        <div
                                            class="d-flex align-items-center gap-3"
                                        >
                                            <button
                                                :style="{
                                                    color: '#ff7900 !important',
                                                }"
                                                class="btn warn-button-border"
                                                @click.prevent="previous"
                                            >
                                                <i
                                                    :style="{
                                                        color: '#ff7900 !important',
                                                    }"
                                                    class="bx bx-left-arrow-alt me-2"
                                                ></i
                                                >Previous
                                            </button>
                                            <button
                                                class="btn add-button-coamana text-white"
                                                @click.prevent="stepperNextOTP"
                                                :disabled="
                                                    selectedmethod ===
                                                        'credit' &&
                                                    (!numberofdays ||
                                                        numberofdays < 0)
                                                "
                                            >
                                                Next
                                                <i
                                                    class="bx bx-right-arrow-alt ms-2"
                                                ></i>
                                            </button>
                                            <!--                      <template v-if="paymentTotal > parseInt(balance_clear) && paymentTotal > parseInt(borrowinglimit_clear)">-->
                                        </div>
                                    </div>
                                </div>
                                <!---end row-->
                            </div>

                            <div
                                id="test-l-4"
                                role="tabpanel"
                                class="bs-stepper-pane"
                                aria-labelledby="stepper1trigger4"
                            >
                                <div class="row g-3 mt-5">
                                    <template v-if="sendOTPToUser">
                                        <send-o-t-p-for-payment
                                            :errors="errors"
                                            @updatedotp="(args) => (otp = args)"
                                        ></send-o-t-p-for-payment>
                                    </template>
                                    <template v-else></template>
                                    <div
                                        class="col-12 d-flex justify-content-end"
                                    >
                                        <div
                                            class="d-flex align-items-center gap-3"
                                        >
                                            <ResendOTP :ref="setChildRef" v-if="sendOTPToUser" :send-otp="sendOTP"/>
                                            <button
                                                :style="{
                                                    color: '#ff7900 !important',
                                                }"
                                                class="btn warn-button-border"
                                                @click.prevent="previous"
                                            >
                                                <i
                                                    :style="{
                                                        color: '#ff7900 !important',
                                                    }"
                                                    class="bx bx-left-arrow-alt me-2"
                                                ></i
                                                >Previous
                                            </button>

                                            <template
                                                v-if="
                                                    paymentTotal >
                                                        parseInt(
                                                            walletBalance
                                                        ) &&
                                                    paymentMethod != 'credit'
                                                "
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-success px-4"
                                                    @click="updateTopUpAMount"
                                                    id="topupaccount"
                                                >
                                                    Complete Payment
                                                </button>

                                                <!--                        <button class="btn btn-secondary px-4" disabled>-->
                                                <!--                          Next-->
                                                <!--                          <i class='fa fa-ban fa-thin'></i>-->
                                                <!--                        </button>-->
                                            </template>
                                            <template
                                                v-else-if="
                                                    paymentTotal >
                                                        parseInt(
                                                            borrowingLimitNew
                                                        ) &&
                                                    paymentMethod === 'credit'
                                                "
                                            >
                                                <button
                                                    class="btn btn-secondary px-4"
                                                    disabled
                                                >
                                                    Next
                                                    <i
                                                        class="fa fa-ban fa-thin"
                                                    ></i>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button
                                                    class="btn btn-success px-4"
                                                    @click.prevent="makeOrder"
                                                    :disabled="finishing"
                                                >
                                                    Complete Payment
                                                    <i
                                                        class="fa fa-spin fa-spinner"
                                                        v-if="finishing"
                                                    ></i>
                                                </button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <!---end row-->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <template v-if="product[0] === 'skip'">
                <div class="d-flex justify-content-center">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">
                                Your Payment has been made
                            </h4>
                            <div class="table-responsive">
                                <table
                                    class="table table-sm table-bordered table-card"
                                >
                                    <tbody>
                                    <tr v-if="paymentMethod === 'credit'">
                                        <th>Method:</th>
                                        <!--                  <td>-->
                                        <!--                    You borrowed from {{ product.market.name }} market to pay this product or service-->
                                        <!--                  </td>-->
                                    </tr>
                                    <tr v-else>
                                        <th>
                                            Amount Deducted from your
                                            account:
                                        </th>
                                        <td>
                                            {{
                                                currency
                                            }}{{
                                                parseFloat(
                                                    paymentTotal
                                                ).toLocaleString()
                                            }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>Seller</th>
                                        <td>
                                            {{ user.name }}
                                            &mdash;
                                            <span class="text-muted">{{
                                                    user.phone
                                                }}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="paymentMethod === 'credit'">
                                        <th>Payment Due</th>
                                        <td>
                                            {{
                                                currency
                                            }}{{
                                                parseFloat(
                                                    amount
                                                ).toLocaleString()
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="paymentMethod === 'credit'">
                                        <th>Payment Due Date</th>
                                        <td>
                                            {{
                                                calculateDays(numberofdays)
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="paymentMethod === 'credit'">
                                        <th>Amount due on due Date</th>
                                        <td>
                                            {{
                                                currency
                                            }}{{
                                                parseFloat(
                                                    paymentTotal
                                                ).toLocaleString()
                                            }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="d-flex justify-content-center" v-else>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Your Payment has been made</h4>
                        <div class="table-responsive">
                            <table
                                class="table table-sm table-bordered table-card"
                            >
                                <tbody>
                                <tr v-if="paymentMethod === 'credit'">
                                    <th>Method:</th>
                                    <td>
                                        You borrowed from
                                        {{ product.market.name }} market to
                                        pay this product or service
                                    </td>
                                </tr>
                                <tr v-else>
                                    <th>
                                        Amount Deducted from your account:
                                    </th>
                                    <td>
                                        {{
                                            currency
                                        }}{{
                                            parseFloat(
                                                paymentTotal
                                            ).toLocaleString()
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Product or Service:</th>
                                    <td>{{ product.product.name }}</td>
                                </tr>
                                <tr>
                                    <th>Quantity:</th>
                                    <td>{{ quantity }}</td>
                                </tr>
                                <tr>
                                    <th>Seller</th>
                                    <td>
                                        {{ product.member.name }}
                                        &mdash;
                                        <span class="text-muted">{{
                                                product.member.phone
                                            }}</span>
                                    </td>
                                </tr>
                                <tr v-if="paymentMethod === 'credit'">
                                    <th>Payment Due</th>
                                    <td>
                                        {{
                                            currency
                                        }}{{
                                            parseFloat(
                                                amount
                                            ).toLocaleString()
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="paymentMethod === 'credit'">
                                    <th>Payment Due Date</th>
                                    <td>
                                        {{ calculateDays(numberofdays) }}
                                    </td>
                                </tr>
                                <tr v-if="paymentMethod === 'credit'">
                                    <th>Amount due on due Date</th>
                                    <td>
                                        {{
                                            currency
                                        }}{{
                                            parseFloat(
                                                paymentTotal
                                            ).toLocaleString()
                                        }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
           .
            <div class="d-flex justify-content-center">
                <div class="tw-w-[600px]">
                    <div class="alert alert-success" role="alert">
                        <div>
                            Please meet <strong>{{ product?.member?.name }}</strong> at <strong>{{ product?.market?.name }}</strong>,  <strong>{{ product?.market?.address }}</strong> to complete the transaction.
                            Contact seller on <strong>{{ product?.member?.phone }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div
                class="modal fade"
                id="topupInline"
                tabindex="-1"
                role="dialog"
                aria-labelledby="topupInline"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="topupInline">Top up</h4>
                            <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="modal-body">
                            <div class="container-fluid">
                                <InlineTopUp
                                    ref="inlineTopUp"
                                    @walletToppedUp="walletToppedUp"
                                    :amountToPay="paymentTotal - balance_clear"
                                ></InlineTopUp>
                            </div>
                        </div>
                        <!--                              <div class="modal-footer">-->
                        <!--                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
                        <!--                                <button type="button" class="btn btn-primary">Save</button>-->
                        <!--                              </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
