<script setup>
import {ref} from "vue";
import ResendOTP from "../../ResendOTP.vue";

const props = defineProps(['members', 'market_id']);

const selectedMember = ref(null);
const otp = ref(null);
const errors = ref({});
const amount = ref(0);
const showOTPScreen = ref(false);
const withdrawing = ref(false);
let user = JSON.parse(document.querySelector('meta[name="user"]').content);
const childRef = ref();


const setChildRef = (el) => {
    childRef.value = el;
};

function selectMember(member) {
  selectedMember.value = member;
}

function close() {
  selectedMember.value = null;
  showOTPScreen.value = false;
}

function showOTP() {
  showOTPScreen.value = true;

  axios.post('/apis/v4/otp', {
    'user_id': user.id,
    'key': 'withdrawFromMarketEarnings'
  }).then(() => {
      childRef.value.startTimer();
  });
}

function withDraw() {
  withdrawing.value = true;

  axios.post('/apis/v4/withdraw', {
    'withdrawFrom': 'marketEarnings',
    'key': 'withdrawFromMarketEarnings',
    'otp': otp.value,
    'amount': amount.value,
    'market_id': props.market_id,
    'user_id': user.id
  }).then(resp => {
    withdrawing.value = false
  }).catch(err => {
    withdrawing.value = false
    errors.value = err.response.data.errors;
  });
}

</script>

<template>
  <!-- Button trigger modal -->
  <!--  <button type="button" class="btn btn-primary btn-lg" data-bs-toggle="modal"-->
  <!--          data-bs-target="#payoutToMemberFromOtherEarnings">-->
  <!--    Launch-->
  <!--  </button>-->

  <!-- Modal -->
  <div class="modal fade" id="payoutToMemberFromOtherEarnings" tabindex="-1" role="dialog"
       aria-labelledby="payoutToMemberFromOtherEarnings" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="payoutToMemberFromOtherEarnings">Payout to Member</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <div class="" v-if="!selectedMember">
            <div class="table-responsive">
              <table class="rounded table table-bordered table-sm table-striped">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Member</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(member,index) in members">
                  <td scope="row">
                    {{ index + 1 }}
                  </td>
                  <td>
                    <div>
                      {{ member.name }}

                    </div>
                    <span class="badge bg-dark">
                  {{ member.phone }}
                  </span>
                  </td>
                  <td>
                    <button class="btn btn-primary btn-sm"
                            @click.prevent="selectMember(member)"
                    >
                      Payout
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <template v-if="!showOTPScreen">
              <div class="row">
                <table class="table table-striped table-condensed">
                  <tbody>
                  <tr>
                    <th>Payout To</th>
                    <td>{{ selectedMember.name }}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{{ selectedMember.phone }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <form action="">
                <div class="row g-3 border border-3 p-3 rounded mt-3">
                  <div class="col-md-6">
                    <!--Label: Amount, Attributes:amount -->
                    <div class="form-floating">
                      <input type="number" class="form-control" id="amount" v-model="amount"
                             aria-describedby="amount-help"
                             :class="[errors.amount ? 'is-invalid': '',!errors.amount && Object.keys(errors).length > 1 ? 'is-valid': '']"
                             required>
                      <label for="amount">Amount</label>

                      <div class="invalid-feedback" v-if="errors.amount">
                        {{ errors.amount.toString() }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <!--Label: Not, Attributes:note -->
                    <div class="form-floating">
                      <input type="text" class="form-control" id="note" v-model="note"
                             aria-describedby="note-help"
                             :class="[errors.note ? 'is-invalid': '',!errors.note && Object.keys(errors).length > 1 ? 'is-valid': '']"
                             required>
                      <label for="note">Note</label>

                      <div class="invalid-feedback" v-if="errors.note">
                        {{ errors.note.toString() }}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>
            <template v-else>
              <!--Label: OTP, Attributes:otp -->
              <div class="form-floating">
                <input type="text" class="form-control" id="otp" v-model="otp"
                       aria-describedby="otp-help"
                       :class="[errors.otp ? 'is-invalid': '',!errors.otp && Object.keys(errors).length > 1 ? 'is-valid': '']"
                       required>
                <label for="otp">OTP</label>
                <div class="invalid-feedback" v-if="errors.otp">
                  {{ errors.otp.toString() }}
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="modal-footer">
            <ResendOTP
                :send-otp="showOTP"
                id="otp-resend"
                v-if="showOTPScreen"
                :ref="setChildRef" />
          <button type="button" class="btn btn-link"

                  data-bs-dismiss="modal"
                  @click="close"
          >Close
          </button>
          <button type="button" class="btn btn-outline-dark"
                  v-if="!showOTPScreen"
                  @click="showOTP"
          >
            Next
          </button>
          <button type="button" class="btn btn-outline-dark"
                  v-else
                  :disabled="withdrawing"
                  @click="withDraw"
          >
            Payout
            <i class="fa fa-spin fa-spinner" v-if="withdrawing"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
