<script setup>
    import Swal from "sweetalert2";

    const props = defineProps(['deleteUrl', 'deleteType']);
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-secondary"
        },
    });

    function deleteChargeFee() {
        swalWithBootstrapButtons.fire({
            text: `Are you sure you want to delete this ${props.deleteType}?`,
            position: 'center',
            width: '20em',
            showConfirmButton: true,
            showCloseButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes, Delete",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
            icon: 'warning',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(props.deleteUrl, { data: { type: props.deleteType } })
                    .finally(() => {
                        location.reload();
                    });
            }
        });
    }
</script>

<template>
    <button
        class="btn btn-danger btn-sm"
        id="deleteChargeButton"
        data-bs-target="#delete-confirmation"
        @click.prevent="deleteChargeFee"
    >
        <i class="fa fa-light fa-trash-clock fa-sm"></i>
        <strong>Delete</strong>
    </button>
</template>

<style scoped>

</style>
