<script setup>
import { ref, watch } from "vue";
import Swal from "sweetalert2";

const props = defineProps(["members"]);

const errors = ref({});
const name = ref("");

const fee_type = ref("");
const amount = ref("");
const members = ref("");
const frequency = ref("");
const description = ref("");
const due_on = ref("");
const custom = ref(false);
const showPhoneNumber = ref(false);
const saving = ref(false);
const phone_number = ref("");
const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;

function changeMember() {
    if (members.value === "phone") {
        showPhoneNumber.value = true;
        return;
    }
    showPhoneNumber.value = false;
    phone_number.value = "";
}

function addFee() {
    saving.value = true;
    axios
        .post("/fee/create", {
            name: name.value,
            phoneNumber: phone_number.value,
            amount: amount.value,
            type: fee_type.value,
            members: members.value,
            frequency: frequency.value,
            description: description.value,
            due_on: due_on.value,
        })
        .then((resp) => {
            amount.value = "";
            fee_type.value = "";
            members.value = "";
            frequency.value = "";
            errors.value = "";
            saving.value = false;

            Swal.fire({
                text: "Fee Added Successfully",
                position: "center",
                showConfirmButton: true,
                showCloseButton: false,
                icon: "success",
            }).then((result) => {
                if (result.isConfirmed) {
                    location.reload();
                } else if (result.isDenied) {
                    Swal.fire("Changes are not saved", "", "info");
                }
            });
        })
        .catch((err) => {
            saving.value = false;
            errors.value = err.response.data.errors;
        })
        .finally(() => {
            saving.value = false;
        });
}
</script>

<template>
    <div>
        <form action="">
            <div class="row g-3">
                <div class="col-md-6">
                    <!--Label: Fee Name, Attributes:name -->
                    <div class="form-floating">
                        <input
                            type="text"
                            class="form-control"
                            id="name"
                            v-model="name"
                            aria-describedby="name-help"
                            :class="[
                                errors.name ? 'is-invalid' : '',
                                !errors.name && Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        />
                        <label for="name">Fee Name</label>

                        <div class="invalid-feedback" v-if="errors.name">
                            {{ errors.name.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Fee Type, Attributes:fee_type -->
                    <div class="form-floating">
                        <select
                            type="text"
                            class="form-control"
                            id="fee_type"
                            v-model="fee_type"
                            aria-describedby="fee_type-help"
                            :class="[
                                errors.fee_type ? 'is-invalid' : '',
                                !errors.fee_type &&
                                Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        >
                            <option
                                name="Capturing promotional content (e.g. videos/photos)"
                                id=""
                            >
                                Capturing promotional content (e.g.
                                videos/photos)
                            </option>
                            <option name="Consultation with market management">
                                Consultation with market management
                            </option>
                            <option name="Contributions">Contributions</option>
                            <option name="Floor space fees">
                                Floor space fees
                            </option>
                            <option name="Insurance contributions">
                                Insurance contributions
                            </option>
                            <option name="Loading">Loading</option>
                            <option value="Market Insurance Contribution">
                                Market Insurance Contributions
                            </option>
                            <option name="Membership fees">
                                Membership fees
                            </option>
                            <option name="Non residence membership">
                                Non residence membership
                            </option>
                            <option name="Offloading">Offloading</option>
                            <option name="Parking">Parking</option>
                            <option name="Permit">Permit</option>
                            <option name="Procurement permit">
                                Procurement permit
                            </option>
                            <option name="Promotional event">
                                Promotional event
                            </option>
                            <option name="Promotional materials">
                                Promotional materials
                            </option>
                            <option name="Rental">Rental</option>
                            <option name="Research and interviews">
                                Research and interviews
                            </option>
                            <option name="Service charge">
                                Service charge
                            </option>
                            <option name="Sign post display">
                                Sign post display
                            </option>
                            <option name="Supply permit">Supply permit</option>
                            <option name="Tax" id="">Tax</option>
                            <option name="Other - Entry field to specify">
                                Other - Entry field to specify
                            </option>
                        </select>
                        <label for="fee_type">Fee Type</label>

                        <div class="invalid-feedback" v-if="errors.fee_type">
                            {{ errors.fee_type.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Description, Attributes:description -->
                    <div class="form-floating">
                        <textarea
                            type="text"
                            class="form-control"
                            id="description"
                            v-model="description"
                            aria-describedby="description-help"
                            :class="[
                                errors.description ? 'is-invalid' : '',
                                !errors.description &&
                                Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        >
                        </textarea>
                        <label for="description">Description</label>

                        <div class="invalid-feedback" v-if="errors.description">
                            {{ errors.description.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Amount, Attributes:amount -->
                    <div class="form-floating">
                        <input
                            type="number"
                            class="form-control"
                            id="amount"
                            v-model="amount"
                            aria-describedby="amount-help"
                            :class="[
                                errors.amount ? 'is-invalid' : '',
                                !errors.amount && Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        />
                        <label for="amount">Amount {{ currency }}</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Due On, Attributes:due_on -->
                    <div class="form-floating">
                        <input
                            type="date"
                            class="form-control"
                            id="due_on"
                            v-model="due_on"
                            aria-describedby="due_on-help"
                            :class="[
                                errors.due_on ? 'is-invalid' : '',
                                !errors.due_on && Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        />
                        <label for="due_on">Due On</label>

                        <div class="invalid-feedback" v-if="errors.due_on">
                            {{ errors.due_on.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Frequency, Attributes:frequency -->
                    <div class="form-floating">
                        <select
                            type="text"
                            class="form-select"
                            id="frequency"
                            v-model="frequency"
                            aria-describedby="frequency-help"
                            :class="[
                                errors.frequency ? 'is-invalid' : '',
                                !errors.frequency &&
                                Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        >
                            <option value="One Time Payment">
                                One Time Payment
                            </option>
                            <option value="daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Bi-Weekly">Bi-Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="annually">
                                Annually from first date paid
                            </option>
                            <option value="Annually">Annually</option>
                            <option value="First Day of Month">
                                First Day of Month
                            </option>
                            <option value="First Day of Year">
                                First Day of Year
                            </option>
                            <option value="First Day of Quarter">
                                First Day of Quarter
                            </option>
                        </select>
                        <label for="frequency">Frequency</label>

                        <div class="invalid-feedback" v-if="errors.frequency">
                            {{ errors.frequency.toString() }}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!--Label: Select Members, Attributes:members -->
                    <div class="form-floating">
                        <select
                            type="text"
                            class="form-select"
                            id="members"
                            v-model="members"
                            aria-describedby="members-help"
                            @change="changeMember"
                            :class="[
                                errors.members ? 'is-invalid' : '',
                                !errors.members &&
                                Object.keys(errors).length > 1
                                    ? 'is-valid'
                                    : '',
                            ]"
                        >
                            <option value="" disabled>Select Option</option>
                            <option value="all">All</option>
                            <option value="phone">Insert Phone Number</option>
                        </select>

                        <label for="members">Select Members</label>

                        <div class="invalid-feedback" v-if="errors.members">
                            {{ errors.members.toString() }}
                        </div>
                    </div>
                </div>
                <template v-if="showPhoneNumber">
                    <div class="col-md-6">
                        <!--Label: Phone Number, Attributes:phone_number -->
                        <div class="form-floating">
                            <select
                                type="text"
                                class="form-select"
                                style="padding-top: 0.5rem"
                                id="phone_number"
                                v-model="phone_number"
                                multiple
                                aria-describedby="phone_number-help"
                                :class="[
                                    errors.phoneNumber ? 'is-invalid' : '',
                                    !errors.phoneNumber &&
                                    Object.keys(errors).length > 1
                                        ? 'is-valid'
                                        : '',
                                ]"
                            >
                                <option value="" disabled>Select Member</option>
                                <option
                                    :value="member.phone"
                                    :key="member?.id"
                                    v-for="member in props.members"
                                >
                                    {{ member.name }}
                                </option>
                            </select>

                            <!-- <label for="phone_number">Select Member</label> -->
                            <div
                                class="invalid-feedback"
                                v-if="errors.phoneNumber"
                            >
                                {{ errors.phoneNumber.toString() }}
                            </div>
                        </div>
                    </div>
                </template>
                <div class="col-md-12 d-flex justify-content-end">
                    <button
                        class="btn btn-outline-primary"
                        :disabled="saving"
                        @click.prevent="addFee"
                    >
                        Add Fee
                        <i v-if="saving" class="fa fa-spin fa-spinner"></i>
                        <i v-else class="fa fa-plus-circle"></i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<style scoped></style>
